
import * as turf from '@turf/turf';
import maplibregl from 'maplibre-gl';

export const fitMapToGeoJsonBounds = (map: maplibregl.Map, features: any[]) => {
  if (!map || !features.length) return;

  const bbox = turf.bbox({
    type: 'FeatureCollection',
    features,
  });

  if (bbox.length === 4) {
    const centerLng = (bbox[0] + bbox[2]) / 2;
    const centerLat = (bbox[1] + bbox[3]) / 2;

    map.setCenter([centerLng, centerLat]);
    map.fitBounds(bbox, {
      padding: 50,
      maxZoom: 16,
      duration: 1000, // Smooth animation
    });
  }
};
