import React, { useRef, useEffect, useState } from 'react';
import 'maplibre-gl/dist/maplibre-gl.css';
import LoaderMap from '../../shared/loader/loaderMap';
import maplibregl from 'maplibre-gl';
import { SideMenu } from './sider/side.menu';
import { LocationTools } from '../../shared/map/button/location';
import { ZoomTools } from '../../shared/map/button/zoom';
import { LayerTools } from '../../shared/map/button/layer';
import useReduxCatalog from '../../hoc/hooks/useCatalog';
import { addGeoJsonLayerParquet } from './helper/map/geojson/layer';
import { TabsSeries } from '../../shared/map/tabs/tabs.series';
// import { DrawerGroupLayer } from '../../shared/map/drawer/drawer.group.layer';
// import { DrawerGroupColor } from '../../shared/map/drawer/drawer.group.color';
import { MobileMenu } from './sider/mobile.menu';
import { GeocodingControl } from '@maptiler/geocoding-control/react';
import { renderGeocodeResultToMap, GeoJSONPolygon, GeoJSONFeature } from '../../component/newmap/helper/map/geocode';
import { Feature, Geometry } from 'geojson';
import { handleReverseGeocode } from './helper/map/reversecode';
import { setCataloglocation } from '../../hoc/store/catalog.store';
import { MobileCardCatalog } from '../menu/menu.mobile/menu.mobile.cardlist';
import { CardMyDataMobile } from '../menu/menu.mobile/menu.card.mydata';
// import { DrawerGroupColorMobile } from '../../shared/map/drawer/mobile/drawer.color.group.mobile';
import { TabsSeriesMobile } from '../../shared/map/tabs/mobile/tabs.series.mobile';
import { LocationToolsMobile } from '../../shared/map/button/mobile/location.mobile';
import { ZoomToolsMobile } from '../../shared/map/button/mobile/zoom.mobile';
import { LayerToolsMobile } from '../../shared/map/button/mobile/layer.mobile';
import { DrawersMobile } from '../../shared/map/button/mobile/drawer.mobile';
import Legendinsight from '../../shared/map/legend/legend.insight';
import { initializeMapboxDraw } from './helper/map/draw.polygon';
import { ShowHideTools } from '../../shared/map/button/showhide';
import { DrawerInfo } from '../../shared/map/drawer/drawer.info';
import { addGeoJsonLayerParquetProfilling } from './helper/map/geojson/layer-profilling';
import { addGeoJsonLayerParquetScoring } from './helper/map/geojson/layer-scoring';

export const MapComponent: React.FC<React.PropsWithChildren> = ({ children }) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<maplibregl.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [draw] = useState<MapboxDraw>(initializeMapboxDraw);
  // const [dataLayerGroup, setDataLayerGroup] = useState<dataLayerGroup[]>([]);
  const [dataSeries, setDataSeries] = useState('Total');
  const [locationMobileView, setLocationMobileView] = useState<any>(null);

  const [isSeries, setIsSeries] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    metaData,
    dataLayerGroup,
    colorGroup,
    showCardCatalog,
    detailMydata,
    selectedCategory,
    showCardMyData,
    popUpFilter,
    showRightDrawer,
    metaDataProfiling,
    setDataLayerGroup,
    setCatalogGeometry,
    setGeojsonFitBounds
  } = useReduxCatalog();

  useEffect(() => {
    if (map.current || !mapContainer.current) return;

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      // style: getEsriWorldImageryStyle(),  // Use the imported style function
      style: 'https://api.maptiler.com/maps/streets/style.json?key=IkttUJmMfqWCx0g43vGM',
      center: [114.17487380816897, 0.3746499288735805],
      zoom: 4,
      maxZoom: 17
    });

    map.current.on('load', () => {
      setMapLoaded(true);
    });
  }, []);
  
  useEffect(() => {
    if (!mapLoaded || !map.current) return;

    const fetchData = async () => {
      try {
        if (metaData?.product === 'profiling') {
          if (!map.current) {
            return;
          }
          await addGeoJsonLayerParquetProfilling(
            map.current,
            draw,
            metaData,
            metaDataProfiling,
            setDataLayerGroup,
            setGeojsonFitBounds,
            dataSeries,
            setIsSeries,
            colorGroup,
            popUpFilter,
            undefined,
            0.5,
            setIsLoading
          )
          
        } else if (metaData?.product === 'scoring') {
          if (!map.current) {
            return;
          }
          await addGeoJsonLayerParquetScoring(
            map.current,
            draw,
            metaData?.table_location,
            metaData?.table_raw_location,
            metaData?.metadata_location,
            setDataLayerGroup,
            dataSeries,
            setIsSeries,
            colorGroup,
            popUpFilter,
            undefined,
            0.5,
            setIsLoading
          );
        }
        else {
          if (!map.current) {
            console.warn("Map instance is null, skipping addGeoJsonLayerParquet.");
            return;
          }
          await addGeoJsonLayerParquet(
            map.current,
            draw,
            metaData?.table_location,
            metaData?.metadata_location,
            setDataLayerGroup,
            dataSeries,
            setIsSeries,
            colorGroup,
            popUpFilter,
            undefined,
            0.5,
            setIsLoading
          );
        }
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };
  
    fetchData();
  
    // eslint-disable-next-line
  }, [mapLoaded, metaData, dataSeries, colorGroup,metaDataProfiling]);

  // Tambahkan fungsi untuk mengganti basemap
  const restoreGeoJsonLayers = async() => {
 
    if (metaData?.product === 'profiling') {
      if (!map.current) {
        console.warn("Map instance is null, skipping addGeoJsonLayerParquet.");
        return;
      }
      await addGeoJsonLayerParquetProfilling(
        map.current,
        draw,
        metaData,
        metaDataProfiling,
        setDataLayerGroup,
        setGeojsonFitBounds,
        dataSeries,
        setIsSeries,
        colorGroup,
        popUpFilter,
        undefined,
        0.5,
        setIsLoading
      )
      
    } else if (metaData?.product === 'scoring') {
      if (!map.current) {
        console.warn("Map instance is null, skipping addGeoJsonLayerParquet.");
        return;
      }
      await addGeoJsonLayerParquetScoring(
        map.current,
        draw,
        metaData?.table_location,
        metaData?.table_raw_location,
        metaData?.metadata_location,
        setDataLayerGroup,
        dataSeries,
        setIsSeries,
        colorGroup,
        popUpFilter,
        undefined,
        0.5,
        setIsLoading
      );
    }
    else {
      if (!map.current) {
        console.warn("Map instance is null, skipping addGeoJsonLayerParquet.");
        return;
      }
      await addGeoJsonLayerParquet(
        map.current,
        draw,
        metaData?.table_location,
        metaData?.metadata_location,
        setDataLayerGroup,
        dataSeries,
        setIsSeries,
        colorGroup,
        popUpFilter,
        undefined,
        0.5,
        setIsLoading
      );
    }
  };

  const changeBasemap = (style: maplibregl.StyleSpecification | string) => {
    if (!map.current) return;

    map.current.setStyle(style);
    map.current.once('styledata', () => {
      restoreGeoJsonLayers()
    });

  };

  const handleGeocodingSelect = async (result: Feature<Geometry> | undefined) => {
    if (result?.geometry?.type === 'Polygon' && map) {
      const geojson: GeoJSONFeature = {
        type: 'Feature', // Type must be explicitly "Feature"
        geometry: result.geometry as GeoJSONPolygon, // Ensure geometry matches GeoJSONPolygon
        properties: {}, // Empty properties for now, can be extended
      };
      renderGeocodeResultToMap(map.current, geojson);
      setCatalogGeometry(geojson.geometry);
      try {
        const locationCode = await handleReverseGeocode(geojson.geometry);
        setLocationMobileView(locationCode)
        setCataloglocation(locationCode);
      } catch (error) {
        console.error('Error fetching address:', error);
      }
    }
  };


  return (
    <div>
      <div ref={mapContainer} style={{ width: '100%', height: '100vh' }} />
      {isLoading && <LoaderMap />}
      {children}

      {windowWidth <= 768 ? <div style={{
        position: "absolute",
        top: 30,
        left: '50%',
        zIndex: 100,
        transform: "translate(-50%, -50%)"
      }}>

        <GeocodingControl
          onPick={handleGeocodingSelect}
          apiKey={'IkttUJmMfqWCx0g43vGM'}
        />
      </div> : null}

      {windowWidth <= 768 ? <MobileMenu map={map.current} /> : <SideMenu map={map.current} draw={draw} />}

      {windowWidth <= 768 && showCardCatalog ? (
        <MobileCardCatalog selectedCategory={selectedCategory} location={locationMobileView} />
      ) : null}

      {windowWidth <= 768 && showCardMyData ? (
        <CardMyDataMobile data={detailMydata} />
      ) : null}
      {windowWidth <= 768 ? <LocationToolsMobile map={map.current} /> : <LocationTools map={map.current} />}
      {windowWidth <= 768 ? <ZoomToolsMobile map={map.current} /> : <ZoomTools map={map.current} />}
      {windowWidth <= 768 ? <LayerToolsMobile onChangeBasemap={changeBasemap} /> : <LayerTools onChangeBasemap={changeBasemap} />}

      {windowWidth > 768 && dataLayerGroup?.length > 0 ? (
        <ShowHideTools />
      ) : null}

      {windowWidth <= 768 && (
        <DrawersMobile map={map.current} />
      )}

      {isSeries && dataLayerGroup?.length > 0 && (
        windowWidth > 768 ? (
          <TabsSeries setDataSeries={setDataSeries} />
        ) : (
          <TabsSeriesMobile setDataSeries={setDataSeries} />
        )
      )}


      {dataLayerGroup?.length > 0 && (showRightDrawer && windowWidth > 768) ? (
        windowWidth > 768 ? (
          <DrawerInfo />
        ) : (
          <DrawerInfo />
        )
      ) : null}



      {(showRightDrawer && windowWidth > 768) && 
        (dataLayerGroup?.[0]?.columnName.toLocaleLowerCase().includes("insight")) ? (
          <><Legendinsight /><DrawerInfo /></>
        ) : null}


        {/* {windowWidth > 768 &&
          (showRightDrawer && dataLayerGroup?.length > 0) ? (
            <DrawerGroupLayer dataLayerGroup={dataLayerGroup} map={map.current!} />
          ) : null} */}

    </div>
  );
};
