import React from 'react';
import { Space } from 'antd';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import icAddress from '../../../asesst/Icons/interactivemap/icon/result.svg';
import icPotition from '../../../asesst/Icons/interactivemap/icon/result3.svg';
import icArea from '../../../asesst/Icons/interactivemap/icon/result2.svg';


export const DrawerInfo: React.FC<any> = () => {
    const {
        metaData,
      } = useReduxCatalog();
    return (
        <React.Fragment>
            <Space
                direction="vertical"
                style={{
                    position: 'absolute',
                    right: '10px',
                    top: '80px',
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <div
                    style={{
                        alignItems: 'center',
                        background: '#141617',
                        padding: '5px',
                        borderRadius: '12px',
                        marginBottom: '5px',
                        paddingTop: '10px',
                        minWidth: '230px',
                        maxWidth: '230px',
                        maxHeight:'500px',
                        minHeight:'170px',
                        height:'auto',
                        color:'white',
                        width: 'auto',
                        paddingBottom: '10px',
                        border: '1px solid black', // Set border color to white
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        cursor: 'pointer',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for
                    }}
                >
                   <div style={{ marginBottom: '15px' }}>
                            <img alt="example" src={icAddress} style={{ marginRight: '10px' }} />
                            <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Location Address</span>
                            <p style={{ margin: '5px 0 0', fontSize: '11px' }}>{metaData?.address}</p>
                        </div>

                        <div style={{ marginBottom: '15px' }}>
                            <img alt="example" src={icPotition} style={{ marginRight: '10px' }} />
                            <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Position</span>
                            <p style={{ margin: '5px 0 0', fontSize: '11px' }}>
                            {metaData?.centroid[1]}, {metaData?.centroid[0]}
                            </p>
                        </div>

                        <div>
                            <img alt="example" src={icArea} style={{ marginRight: '10px' }} />
                            <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Size of Area</span>
                            <p style={{ margin: '5px 0 0', fontSize: '11px' }}>{metaData?.area} km²</p>
                        </div>
                </div>

            </Space>
        </React.Fragment>
    );
};
