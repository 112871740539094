import React, { useEffect, useState } from 'react';
import { notification, Select, Tooltip } from 'antd';

import icDownload from '../../../asesst/new/icon/download.svg';
import icDownloadBlue from '../../../asesst/Icons/interactivemap/icon/dowloadn-blue.svg'
import icTotal from '../../../asesst/Icons/interactivemap/icon/ic-sum-total.svg'
import icavg from '../../../asesst/Icons/interactivemap/icon/ic-sum-avg.svg'
import icmin from '../../../asesst/Icons/interactivemap/icon/ic-sum-min.svg'
import icmed from '../../../asesst/Icons/interactivemap/icon/ic-sum-med.svg'
import icmax from '../../../asesst/Icons/interactivemap/icon/ic-sum-max.svg'
import icbar from '../../../asesst/Icons/interactivemap/icon/ic-sum-bar.svg'
import icColor from '../../../asesst/Icons/interactivemap/icon/ic_outline-color-lens.svg'
import useReduxCatalog from '../../../hoc/hooks/useCatalog';


import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ChartTooltip, // Ubah nama Tooltip dari chart.js
  Legend,
} from 'chart.js';
import GridParser from '../../../component/newmap/helper/map/geojson/grid';
import { MenuCardDownloadData } from '../../../component/menu/menu.card.download.data';
import { useNavigate } from 'react-router-dom';
import { setLayerOpacity } from '../../../component/newmap/helper/map/layer.opacity';
import { DrawerGroupColor, gradientColorsParams } from './drawer.group.color';
import { clearPolygonLayer, removeIsochronesAndPopups, removeLayerTop5, removeLayersAndSources } from '../../../component/newmap/helper/map/sourceLayer';
import { getColorFromInterpolation } from '../../../component/newmap/helper/coloring/color.helper';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ChartTooltip, Legend);
interface ResultCatalogProps {
  onBackClick: () => void;
  map: maplibregl.Map | null;
  draw: MapboxDraw;
}

interface TrafficData {
  total: number;
  earlymorning: number;
  morning: number;
  afternoon: number;
  evening: number;
  night: number;
}

type SummaryTraficHistogram = Record<string, TrafficData>;

export const DrawerSeeData: React.FC<ResultCatalogProps> = ({ onBackClick, map, draw }) => {
  const {
    setPopUpFilter,
    setShowCardDownload,
    setShowCardChangeColor,
    setColorGroup,
    showCardDownload,
    showCardChageColor,
    metaData,
    dataLayerGroup,
    popUpFilter,
    colorGroup
  } = useReduxCatalog();
  const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);
  const [optionsSelect, setOptionsSelects] = useState<{ value: string; label: string }[]>([]);
  const [currentColors,] = useState({
    type: 'gradient',
    color: gradientColorsParams, // Default gradient colors
  });  

  const navigate = useNavigate();
  const grid_lib = new GridParser();
  const filteredData = dataLayerGroup.filter(item => popUpFilter.includes(item.columnName));

  const histogramData = filteredData?.[0]?.summary?.histogram;
  const topo5 = filteredData?.[0]?.summary?.top5;
  const ranges = histogramData?.map((item: { range: any; }) => item.range);
  const values = histogramData?.map((item: { value: any; }) => item.value);
  const avg = filteredData?.[0]?.summary?.avg_formated ?? 0; // Default 0 jika undefined
  const max = filteredData?.[0]?.summary?.max_formated ?? 0;
  const min = filteredData?.[0]?.summary?.min_formated ?? 0;
  const median = filteredData?.[0]?.summary?.median_formated ?? 0;
  const summaryAll = filteredData?.[0]?.summaryall ?? 0;
  const summaryAllTotal = summaryAll?.total_formated?.total_formated ?? 0;
  const summaryAllHistogram = summaryAll?.total?.detail ?? 0;
  const summarySelect = filteredData?.[0]?.summary; // Default 0 jika undefined
  const summarySelecttrafic = filteredData?.[0]; // Default 0 jika undefined
  const labelNonTrarfic = filteredData?.[0]?.productName ?? '';
  // trafic
  // const summaryTraficHistogram: SummaryTraficHistogram = summaryAll?.total?.detail ?? {};
  
  const rangeColor = filteredData?.[0]?.bins ?? []; // Default array kosong jika undefined
  const binsRangeColor = Array.from(new Set<number>(rangeColor)).sort((a, b) => a - b)
  if (!binsRangeColor || binsRangeColor.length === 0) {
    // console.error("Bins range is empty or undefined.");
  }

  const handleBackResult = () => {
    if (map) {
      clearPolygonLayer(map, draw); // Remove the polygon layer if it exists
      removeIsochronesAndPopups(map);
      removeLayersAndSources(map);
      removeLayerTop5(map)
    }

    onBackClick()
  };
  const goToGenerateSummaryPage = () => {
    navigate('/generate-report', { state: { dataLayerGroup, colorGroup, metaData } });
  };

 

  // Konversi data ke GeoJSON
  const convertTogeojson = topo5?.map((item: { gid: string; value: number }) => {
    try {
      // Validasi data sebelum interpolasi
      if (typeof item?.value !== "number" || !item?.gid) {
        // console.error(`Invalid data for item:`, item);
        return null; // Abaikan item yang tidak valid
      }
      const colorStops = getColorFromInterpolation(item?.value, binsRangeColor, colorGroup?.color || [])
      return {
        type: "Feature",
        properties: {
          ...item,
          color: colorStops,
        },
        geometry: {
          type: "Polygon",
          coordinates: [grid_lib._gid_to_geometry(item?.gid)], // Gunakan transformasi geometris yang sesuai
        },
      };
    } catch (error) {
      // console.error(`Failed to process item:`, item, error);
      return null; // Abaikan item yang gagal diproses
    }
  })
    .filter(Boolean); // Hapus elemen `null`  

  const handleCardDownloadclick = () => {
    setShowCardDownload(true);
    setShowCardChangeColor(false)
  };
  const handleCardChangeColorClick = () => {
    setShowCardDownload(false);
    setShowCardChangeColor(true)
  };

  const handleFlyTo = (item: any) => {
    if (map) {
      // Ambil koordinat dari GeoJSON (ambil titik pertama dalam polygon)
      const coordinates = item.geometry.coordinates[0][0]; // Koordinat pertama dalam polygon

      if (coordinates) {
        // Tambahkan layer dengan warna pada grid target
        const sourceId = 'highlighted-grid-source';
        const layerId = 'highlighted-grid-layer';

        // Periksa apakah source/layer sudah ada, jika ya, hapus
        if (map.getSource(sourceId)) {
          map.removeLayer(`${layerId}-outline`);
          map.removeLayer(layerId);
          map.removeSource(sourceId);
        }
        // Tambahkan source baru untuk grid
        map.addSource(sourceId, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [item], // Gunakan fitur GeoJSON yang diterima
          },
        });

        // Tambahkan layer baru dengan warna fill
        map.addLayer({
          id: layerId,
          type: 'fill',
          source: sourceId,
          paint: {
            'fill-color': 'transparent',
            'fill-opacity': 1, // Transparansi
            'fill-outline-color': 'black',
          },
        });

        map.addLayer({
          id: `${layerId}-outline`, // ID unik untuk outline
          type: 'line',
          source: sourceId,
          paint: {
            'line-color': 'black', // Warna garis tepi
            'line-width': 2, // Ketebalan garis tepi
          },
        });

        // Pindahkan tampilan peta ke grid yang dituju
        map.flyTo({
          center: coordinates, // Format [longitude, latitude]
          zoom: 20,
          speed: 1.2,
          curve: 1, // Smooth fly effect
        });
      } else {
        notification.warning({
          message: 'Invalid Coordinates',
          description: 'Unable to extract coordinates from the selected feature.',
        });
      }
    } else {
      notification.warning({
        message: 'Map instance is not available',
        description: 'Ensure the map is loaded before using this feature.',
      });
    }
  };
  // console.log(ranges);

  const distributionData = {
    labels: ranges,
    datasets: [
      {
        label: 'Total',
        data: values,
        backgroundColor: colorGroup?.color,
        borderRadius: 4,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 10,
        bottom: 10, // Atur padding agar label sumbu-X tidak terlalu jauh
      },
    },
    plugins: {
      legend: {
        display: false, // Sembunyikan legenda
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#FFFFFF', // Warna label x-axis
          // padding: 2, // Atur jarak label dari sumbu-X
          font: {
            size: 7, // Ubah ukuran font label y-axis

          },
        },
      },
      y: {
        ticks: {
          color: '#FFFFFF', // Warna label y-axis
          font: {
            size: 10, // Ubah ukuran font label y-axis
          },
        },
      },
    },
  };

  // imi ada perbedaan properties jadi pasti bingung buat filter manualnya jadi aku kondisionalin lagi

  const columnFilter = filteredData?.[0]?.columnName || "all"; // Ambil columnName
  
  const labels = [labelNonTrarfic]; // Label untuk X-axis
  const getFilteredTotalData = (summaryAllHistogram: any, columnFilter: string) => {
    return Object.entries(summaryAllHistogram)
      .filter(([key]) => !key.includes("formated")) // 🔥 Buang data yang mengandung "formated"
      .filter(([key]) => columnFilter.includes("all") || key === columnFilter) // ✅ Filter sesuai columnFilter
      .sort(([, valueA], [, valueB]) => (valueA as number) - (valueB as number)) // Urutkan nilai dari kecil ke besar
      .map(([key, value], index) => ({
        label: key.replace(/_/g, " "), // Ubah _ jadi spasi
        data: [value as number], // Masukkan data
        backgroundColor: [
          "#3366cc", // Softer Blue
          "#3399cc", // Blue-Green
          "#33cccc", // Soft Cyan
          "#33cc99", // Teal-Green
          "#33cc66", // Soft Green
          "#99cc33", // Yellow-Green
          "#cccc33", // Softer Yellow
          "#cc9933", // Muted Orange
          "#cc3333", // Muted Red
          "#993333"  // Dark Red
        ][index % 13], // Warna dinamis dengan index looping
        borderRadius: 4, // Styling opsional
      }));
  };

  const getRandomColorFromArray = () => {
    const colors = [
      "#3366cc", // Softer Blue
      "#3399cc", // Blue-Green
      "#33cccc", // Soft Cyan
      "#33cc99", // Teal-Green
      "#33cc66", // Soft Green
      "#99cc33", // Yellow-Green
      "#cccc33", // Softer Yellow
      "#cc9933", // Muted Orange
      "#cc3333", // Muted Red
      "#993333"  // Dark Red
    ];
    return colors[Math.floor(Math.random() * colors.length)]; // Ambil warna random dari array
  };
  
  
  const getFilterDataset = (summarySelect: any, filteredData: any[]) => {
    return [
      {
        label: filteredData?.[0]?.columnName.replace(/_/g, " ") || "Unknown",
        data: [summarySelect?.total],
        backgroundColor: getRandomColorFromArray(), // Warna diacak dari daftar
        borderRadius: 4,
      },
    ];
  };
  const filteredSummaryTotalData = getFilteredTotalData(summaryAllHistogram, columnFilter);
  const filterSummaryDataset = getFilterDataset(summarySelect, filteredData);
  
  // total stacked bar
  const dataTotal = {
    labels: labels, // Label X-axis
    datasets: columnFilter.includes("all") ? filteredSummaryTotalData : filterSummaryDataset, // Dataset yang sudah dipetakan
  };

  const optionsTotal = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const, // Posisi legend
        labels: {
          boxWidth: 10, // Lebar kotak warna
          color: "#fff", // Warna teks legend
        },
        onClick: () => { }, // Nonaktifkan interaksi klik pada legend
      },
      title: {
        display: true,
        color: "#fff", // Warna teks legend
        font: {
          size: 18,
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
      },
    },
    scales: {
      x: {
        stacked: true, // Bar stack di X-axis
        ticks: {
          color: "#fff", // Warna teks legend
        },
      },
      y: {
        stacked: true, // Bar stack di Y-axis
        ticks: {
          font: {
            size: 8, // Ubah ukuran font label y-axis

          },
          color: "#fff", // Warna teks legend
          beginAtZero: true, // Mulai dari nol
        },
        grid: {
          color: "rgba(0, 0, 0, 0.2)", // Warna grid
        },
      },
    },
  };
  // end total stack bar
  // staked bar trafic 

  
  const labelsTrafic = ["Early Morning", "Morning", "Afternoon", "Evening", "Night"];


  const getFilterDatasetTrafic = (summarySelect: any) => {
    const isTotal = columnFilter.toLowerCase().includes("total");
    const summarySelectTotal: SummaryTraficHistogram = summarySelect?.summaryvariable?.total?.detail ?? {};
    const summarySelectVariable: SummaryTraficHistogram = summarySelect?.summary ?? {};
    const summaryLabel = summarySelect?.columnName.split("_").pop()?.toLowerCase();
    
  
    return isTotal
      ? Object.entries(summarySelectTotal)
      .filter(([key]) => !key.includes("formated"))
      .map(([key, values], index) => (
      {
        
          label: key.replace(/_/g, " "),
          data: new Array(index).fill(0).concat(values), // Tambahin 0 biar dia geser ke kanan
          backgroundColor: ["#E57373", "#64B5F6", "#81C784", "#FFD700", "#FF8C00"][
            index % 5
          ], // Warna berdasarkan index
          borderRadius: 4,
        }))
      : 
      [ {
              label: summarySelect?.columnName.replace(/_/g, " ") || "Unknown",
              data: labelsTrafic.map((label) => 
                  label.toLowerCase().replace(/\s+/g, "") === summaryLabel ? summarySelectVariable?.total : 0
                ), // Taruh nilai di posisi yang sesuai
                backgroundColor: ["#E57373", "#64B5F6", "#81C784", "#FFD700", "#FF8C00"],
              borderRadius: 4,
            }
          ]
  };
  

  const filterSummaryDatasetrafic = getFilterDatasetTrafic(summarySelecttrafic);
  
  const chartDataTrafic = {
    labels: labelsTrafic,
    datasets: filterSummaryDatasetrafic,
  };

  const optionsTrafic = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const, // Posisi legend
        labels: {
          boxWidth: 10, // Lebar kotak warna
          font: {
            size: 9, // Ubah ukuran font label y-axis

          },
          color: "#fff", // Warna teks legend
        },
        onClick: () => { }, // Nonaktifkan interaksi klik pada legend
      },
      title: {
        // text: "Population by Time of Day",
        display: true,
        color: "#fff", // Warna teks legend
        font: {
          size: 8,
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
      },
    },
    scales: {
      x: {
        stacked: true, // Bar stack di X-axis
        ticks: {
          font: {
            size: 8, // Ubah ukuran font label y-axis

          },
          color: "#fff", // Warna teks legend
        },
      },
      y: {
        stacked: true, // Bar stack di Y-axis
        ticks: {
          font: {
            size: 8, // Ubah ukuran font label y-axis

          },
          color: "#fff", // Warna teks legend
          beginAtZero: true, // Mulai dari nol
        },
        grid: {
          color: "rgba(0, 0, 0, 0.2)", // Warna grid
        },
      },
    },
  };
  // end stacked bar trafic

  useEffect(() => {
    // console.log('showCardDownload changed:', show);
  }, [showCardDownload]);


  useEffect(() => {
    if (map) {
      // Sort dataLayerGroup berdasarkan subdataName (A-Z)
      const sortedLayers = [...dataLayerGroup].sort((a, b) => 
        (a.subdataName || a.productName ).localeCompare(b.subdataName || b.productName)
      );
  
      sortedLayers.forEach((layer, index) => {
        const isDefault = index === 0; // Jadikan layer pertama default terbuka
        setLayerOpacity(map, "layer", layer.columnName, isDefault ? 0.8 : 0);
      });
    }
  }, [dataLayerGroup, map]);
  

  // Efek untuk memperbarui options dan defaultValue saat data tersedia
  useEffect(() => {
    if (dataLayerGroup && dataLayerGroup.length > 0) {
      const newOptions = dataLayerGroup.map((item) => ({
        value: item.columnName,
        label: `${item.subdataName || item.productName}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)); // Sort berdasarkan label (A-Z)

      setOptionsSelects(newOptions);
      setSelectedValue(newOptions[0]?.value); // Set default hanya jika data ada
      setPopUpFilter([newOptions[0]?.value])
      // setColorGroup(currentColors)
    }
    // eslint-disable-next-line
  }, [dataLayerGroup]);

  const handleChange = (value: string) => {
    if (map && selectedValue) {
      setLayerOpacity(map, 'layer', selectedValue, 0); // Nonaktifkan layer sebelumnya

    }

    if (map) {
      setLayerOpacity(map, 'layer', value, 0.8); // Aktifkan layer baru
    }
    setPopUpFilter([value])
    setSelectedValue(value);
    setColorGroup(colorGroup)
  };
  useEffect(() => {
    setColorGroup(currentColors)
    // eslint-disable-next-line
  }, [currentColors])
  return (
    <React.Fragment>
      {/* Back Arrow */}

      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px',
        cursor: 'pointer'
      }}>
        {/* Kiri: Arrow dan Result Location */}
        <span onClick={handleBackResult} style={{ fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
          ← Result Location
        </span>

        {/* Kanan: Icon Download & Change Color */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Tooltip
            placement="bottom"
            overlayInnerStyle={{ backgroundColor: '#343A3A', color: 'white' }} // Ganti warna bg dan teks
            title={
              <div style={{ textAlign: 'left' }}>
                <strong style={{ display: 'block', marginBottom: '3px' }}>Change Color</strong>
              
              </div>
            }
          >
            <img
              src={icColor}
              alt="Download"
              style={{ width: '25px', height: '25px', cursor: 'pointer' }}
              onClick={handleCardChangeColorClick}
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            overlayInnerStyle={{ backgroundColor: '#343A3A', color: 'white' }} // Ganti warna bg dan teks
            title={
              <div style={{ textAlign: 'left' }}>
                <strong style={{ display: 'block', marginBottom: '3px' }}>Download Data</strong>
              
              </div>
            }
          > 
          <img
          src={icDownload}
          alt="Change Color"
          style={{ width: '25px', height: '25px', cursor: 'pointer' }}
          onClick={handleCardDownloadclick}
        />
          </Tooltip>
         
        </div>
      </div>

      <div style={{
        overflowY: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
      }}>


        <div
          style={{
            alignItems: 'center',
            background: '#141617',
            padding: '5px',
            borderRadius: '12px',
            marginBottom: '5px',
            paddingTop: '10px',
            paddingBottom: '10px',
            border: '1px solid black', // Set border color to white
            paddingLeft: '10px',
            paddingRight: '10px',
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for
          }}
        >
          <div style={{ width: '166px' }}>
            <h4 style={{ marginBottom: '10px', fontSize: '14px' }}>{metaData?.alias}</h4>
          </div>
          <div>
            <p style={{ fontSize: '11px' }}> {metaData?.description}</p>
          </div>
        </div>

        <div
          style={{
            alignItems: 'center',
            background: '#141617',
            padding: '5px',
            borderRadius: '12px',
            marginBottom: '5px',
            paddingTop: '10px',
            paddingBottom: '10px',
            border: '1px solid black', // Set border color to white
            paddingLeft: '10px',
            paddingRight: '10px',
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for
          }}
        >
          <h4 style={{ marginBottom: '10px', fontSize: '14px' }}>Data</h4>
          <Select
            value={selectedValue}
            style={{ width: '100%', height: '40px' }}
            dropdownStyle={{ backgroundColor: 'gray', color: 'white' }} // Ubah warna teks di dropdown
            onChange={handleChange}
            options={optionsSelect}
            placeholder={(<p style={{ color: 'white' }}>Loading....</p>)}
            disabled={optionsSelect.length === 0}
          />
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '10px'

        }}>
          <h3 style={{ margin: 0 }}>Summary</h3>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img alt="download icon" src={icDownloadBlue} style={{
              marginRight: '5px',
              width: '25px', // Sesuaikan ukuran lebar
              height: '25px', // Sesuaikan ukuran tinggi
            }} />
            <p
              style={{
                color: '#4A7FC0',
                textDecoration: 'none',
                fontSize: '16px',
                fontWeight: 'bold',
                cursor: 'pointer'
              }}
              onClick={goToGenerateSummaryPage} // Navigasi dengan state
            >
              Generate Summary
            </p>
          </div>
        </div>
        <div style={{
          padding: '10px',
          background: '#141617',
          borderRadius: '8px'
        }}>
          {filteredData?.[0]?.columnName &&
            !(
              filteredData[0].columnName.includes("poi") ||
              filteredData[0].columnName.includes("reachability") ||
              filteredData[0].columnName.includes("flood_hazard") ||
              filteredData[0].columnName.includes("mortality") ||
              filteredData[0].columnName.includes("migration") ||
              filteredData[0].columnName.includes("earthquake_hazard") ||
              filteredData[0].columnName.includes("insight") ||
              filteredData[0].columnName.includes("land_price_predicted")

            ) && (
              <div
                style={{
                  background: "#141617",
                  padding: "10px",
                  borderRadius: "8px",
                  border: "2px solid #3B669A", // Menambahkan border biru
                  textAlign: "left",
                  marginBottom: "5px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
                >
                  {/* Ikon di sebelah kiri */}
                  <img
                    src={icTotal} // Ganti URL ini dengan path ke ikon Anda
                    alt="icon"
                  // style={{ width: '16px', height: '16px', marginRight: '8px' }}
                  />
                  <h4 style={{ fontSize: "12px", margin: 0, marginLeft: "5px" }}>
                    Total
                  </h4>
                </div>
                {dataLayerGroup.length < 1 ? (
                  <p>loading...</p>
                ) : (
                  <>
                    <h3 style={{ margin: 0 }}>
                   
                      {summaryAllTotal}
                    </h3>
                    <div
                      style={{
                        height: "300px", // Atur tinggi tetap
                        width: "100%", // Sesuaikan lebar
                        marginTop: '-30px'
                      }}
                    >
                      {filteredData?.[0]?.isSeries ? (
                        <Bar data={chartDataTrafic} options={optionsTrafic} />
                      ) : (
                        <Bar data={dataTotal} options={optionsTotal} />
                      )}
                    </div>
                  </>
                )}
              </div>
            )}

          <div
            style={{
              background: '#141617',
              padding: '10px',
              borderRadius: '8px',
              border: '2px solid #3B669A', // Menambahkan border biru
              textAlign: 'left',
              marginBottom: '5px'

            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              {/* Ikon di sebelah kiri */}
              <img
                src={icavg} // Ganti URL ini dengan path ke ikon Anda
                alt="icon"
              // style={{ width: '16px', height: '16px', marginRight: '8px' }}
              />
              <h3 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Average per Square</h3>
            </div>
            {dataLayerGroup.length < 1 ? (
              <p>loading...</p>
            ) : (
              <h3 style={{ margin: 0 }}>
                {avg}
              </h3>
            )}
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr', // 3 kolom
              gap: '5px',
              maxWidth: '320px', // Batasi lebar maksimum container grid
              margin: '0 auto', // Pusatkan grid secara horizontal
            }}
          >
            <div
              style={{
                background: '#141617',
                padding: '10px',
                border: '2px solid #3B669A', // Menambahkan border biru
                borderRadius: '8px',
                maxWidth: '100px',
                textAlign: 'left',
              }}
            >

              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                {/* Ikon di sebelah kiri */}
                <img
                  src={icmin} // Ganti URL ini dengan path ke ikon Anda
                  alt="icon"
                // style={{ width: '16px', height: '16px', marginRight: '8px' }}
                />
                <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Min</h4>
              </div>
              {dataLayerGroup.length < 1 ? (
                <p>loading...</p>
              ) : (
                <h3 style={{ margin: 0 }}>
                 
                  {min}
                </h3>
              )}
            </div>

            <div
              style={{
                background: '#141617',
                padding: '10px',
                maxWidth: '100px',
                border: '2px solid #3B669A', // Menambahkan border biru
                borderRadius: '8px',
                textAlign: 'left',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                {/* Ikon di sebelah kiri */}
                <img
                  src={icmed} // Ganti URL ini dengan path ke ikon Anda
                  alt="icon"
                // style={{ width: '16px', height: '16px', marginRight: '8px' }}
                />
                <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Med</h4>
              </div>
              {dataLayerGroup.length < 1 ? (
                <p>loading...</p>
              ) : (
                <h3 style={{ margin: 0 }}>
                 
                  {median}
                </h3>
              )}
            </div>
            <div
              style={{
                background: '#141617',
                padding: '10px',
                border: '2px solid #3B669A', // Menambahkan border biru
                borderRadius: '8px',
                maxWidth: '100px',
                textAlign: 'left',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                {/* Ikon di sebelah kiri */}
                <img
                  src={icmax} // Ganti URL ini dengan path ke ikon Anda
                  alt="icon"
                // style={{ width: '16px', height: '16px', marginRight: '8px' }}
                />
                <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Max</h4>
              </div>
              {dataLayerGroup.length < 1 ? (
                <p>loading...</p>
              ) : (
                <h3 style={{ margin: 0 }}>
                 
                  {max}

                </h3>
              )}
            </div>


          </div>
          <div
            style={{
              background: '#141617',
              borderRadius: '8px',
              padding: '15px',
              marginTop: '20px',
              border: '2px solid #3B669A', // Menambahkan border biru
              height: '300px', // Tinggi khusus untuk Chart.js
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              {/* Ikon di sebelah kiri */}
              <img
                src={icbar} // Ganti URL ini dengan path ke ikon Anda
                alt="icon"
              // style={{ width: '16px', height: '16px', marginRight: '8px' }}
              />
              <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Distribution</h4>
            </div>
            {dataLayerGroup.length < 1 ? (
              <p>loading...</p>
            ) : (
              <Bar data={distributionData} options={options} />
            )}
          </div>
          <div
            style={{
              background: '#141617',
              borderRadius: '8px',
              padding: '15px',
              border: '2px solid #3B669A', // Menambahkan border biru
              marginTop: '20px',
            }}
          >

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              {/* Ikon di sebelah kiri */}
              <img
                src={icbar} // Ganti URL ini dengan path ke ikon Anda
                alt="icon"
              // style={{ width: '16px', height: '16px', marginRight: '8px' }}
              />
              <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Top 5 squares with highest number</h4>
            </div>
            {convertTogeojson?.map((number: any, index: any) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '20%',
                      backgroundColor: number?.properties?.color,
                    }}
                  >

                  </div>
           
                  {number?.properties?.formated}
                </div>
                <p
                  style={{ color: '#4A7FC0', fontSize: '14px', cursor: 'pointer' }}
                  onClick={() => handleFlyTo(number)}
                >
                  View
                </p>
              </div>
            ))}
          </div>

        </div>
        {showCardDownload && <MenuCardDownloadData />}
        {showCardChageColor && <DrawerGroupColor />}
      </div>

    </React.Fragment>
  );
};
