import React, { useEffect, useState } from 'react';
import { Button, Empty } from 'antd';
import { CardCatalog } from '../../../component/menu/menu.card.catalog';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import mapid from "../../../asesst/Icons/product/mapid.png";
import LoaderSideBar from '../../loader/loader.sidebar';
// import { formatNumberWithDots } from '../../../component/newmap/helper/formatNumber';
import { IoChevronForwardOutline } from 'react-icons/io5';
import icplanninng from '../../../asesst/new/icon/planning.svg';
// import icPotition from '../../../asesst/Icons/interactivemap/icon/result3.svg';
// import icArea from '../../../asesst/Icons/interactivemap/icon/result2.svg';
import { BsExclamationOctagon } from "react-icons/bs";
import { MenuScoring } from '../../../component/menu/menu.scoring';
import { ModalDefault } from '../modal/modal-checkout';
interface ResultCatalogProps {
  // onBackClick: () => void;
}
export const ResultCatalog: React.FC<ResultCatalogProps> = () => {
  const [activeTab, setActiveTab] = useState('Insight');
  // const [showCardCatalog, setShowCardCatalog] = useState(false);
  const {
    setCatalog,
    setShowCardCatalog,
    setShowSideMenuCustomScoring,
    showCardCatalog,
    paramsCatalogGeometry,
    catalog,
    isloading,
    location,
    showSideMenuCustomScoring,
    setModalOpenCheckout,
    setSelectedItem,
    isModalCheckout, 
    selectedItem,
  } = useReduxCatalog();
  const [param, setParam] = useState('insight');
  const [selectedCategory, setSelectedCategory] = useState<any | null>(null);

  const handleTabClick = (params: string) => {
    setActiveTab(params);
    const paramCatalog = params.toLowerCase();
    setParam(paramCatalog);
  };

  const handleCardClick = (category: any) => {
    setSelectedCategory(category);
    setShowCardCatalog(true);
    setShowSideMenuCustomScoring(false);
  };
  const handleCustomScor = (category: any) => {
    setSelectedCategory(category);
    setShowSideMenuCustomScoring(true);
    setShowCardCatalog(false);
  };

  useEffect(() => {
    setCatalog(param, paramsCatalogGeometry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param, paramsCatalogGeometry, location]);


  const showModal = (item: any) => {

     const cardsData = {
      title: item?.product,
      productId: item?.product_id,
      description: item?.description,
      price: item?.price?.minPrice,
      carPrice: item?.price?.minPrice != null
          ? `Rp ${item?.price?.minPrice.toLocaleString('id-ID')}`
          : 'Free',
      size: item?.size === 1000 ? '1000x1000' : (item?.size < 50 ? '5x5' : '50x50'),
      size_grid: item?.size,
      totalpixel: item?.price?.total_pixel,
      status: item?.status,
      areaSize: location?.area,
      output: item?.output_type,
      year: item?.versions?.info,
      address: location?.address,
      id: item?.id,
      readiness: item?.readiness,
      polygonId: item?.polygon_id,
      subData: item?.subdata,
      version: item?.versions,
      typeDataId: item?.type_id,
      radius: item?.radius,
      centeroid: {
          lattitude: location?.centroid?.[0] || 0, // Default to 0 if undefined
          longitude: location?.centroid?.[1] || 0
      }
     }
           
    setSelectedItem(cardsData);
    setModalOpenCheckout(true);
  };
  const renderTabContent = () => {
    if (activeTab === 'Other') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', background: '#2C3F58', padding: '15px', borderRadius: '12px', marginBottom: '10px', gap: '10px', marginTop: '10px' }}>
          <span style={{ fontSize: 16, fontWeight: 'bold' }}>
            <img
              alt="example"
              src={mapid}
              style={{ width: 70, height: 70, objectFit: 'cover', borderRadius: '8px' }} />
          </span>
          <span style={{ fontSize: 12, lineHeight: '1.5' }}><b>MAPID</b> is a location intelligence platform that connects various location data from trusted sources. The term itself comes from a map where representation of areas are shown in physical features and id as country code of Indonesia.</span>
        </div>
      );
    }
    if (activeTab === 'Survey') {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE} // Replace with your custom image if needed
          description={
            <>
              <span style={{ color: 'white', fontWeight: 'bold', fontSize: '14px' }}>
                No data available
              </span>
              <br />
              <span style={{ color: 'white', fontSize: '12px' }}>
                Check back with us later, or feel free to reach out for more details!
              </span>
            </>
          }
        />
      );
    }
    if (isloading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <LoaderSideBar />
        </div>
      );
    }

    const dataTabs = catalog?.data || {};

    const isData500 = !dataTabs.length || catalog?.data?.statusCode === 500;
    if (isData500) {
      return (
        <Empty
          image={<BsExclamationOctagon style={{ fontSize: '50px', color: '#FF4D4F' }} />}
          description={
            <>
              <span style={{ color: 'white', fontWeight: 'bold', fontSize: '14px' }}>
                The area is too large.
              </span>
              <br />
              <span style={{ color: 'white', fontSize: '12px' }}>
                Please switch to using the draw polygon tool or use a point instead, to define the area you want.
              </span>
            </>
          }
        />
      );
    }

    return Object.entries(dataTabs)
      .map(([category, categoryData]) => {
        const categoryDataTyped = categoryData as { category: string; products: any[]; icon: string; category_id?: string, descriptioon: string };
        const subcategory = categoryDataTyped.products;
        // const description = categoryDataTyped.descriptioon;
        const objectCount = subcategory.length;
        const formattedCategory = categoryDataTyped.category;
        // const isAllowed = user?.email?.includes("@geosquare.ai");

        if (activeTab === 'Insight') {
          return (
            <>
            
                <div
                  onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                  onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
                  key={`${category}`}
                  onClick={() => {
                    if (categoryDataTyped?.category_id === "location_profile") {
                      const firstProduct = categoryDataTyped?.products?.[0]; 
                      showModal(firstProduct)

                    } else if (categoryDataTyped?.category_id === "location_scoring") {
                      handleCustomScor(subcategory);
                    } else {
                      handleCardClick(subcategory);
                    }
                  }}
                  
                  // onClick={() => categoryDataTyped?.category_id === "location_scoring" ? handleCustomScor(subcategory) : handleCardClick(subcategory)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '#141617',
                    padding: '5px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    border: '1px solid black',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    borderRadius: '12px',
                    marginBottom: '10px',
                    marginTop: '10px',
                    cursor: 'pointer',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for 
                  }}
                >
                  <img src={
                    categoryDataTyped?.category_id === "location_scoring"  ? icplanninng : dataTabs[category].icon} alt={formattedCategory} style={{ width: '40px', marginRight: '10px' }} />
                  <div style={{ width: "166px" }}>
                    <h4 style={{ marginBottom: '10px' }}>{formattedCategory}</h4>
                    {
                      categoryDataTyped?.category_id === "location_scoring" || categoryDataTyped?.category_id === 'location_profile' ?
                        (
                          <p style={{ marginBottom: '10px', fontSize: '10px' }}>{categoryDataTyped?.products?.[0]?.description}</p>
                        )
                        :
                        (
                          <>

                            <p>{objectCount} Products</p>

                          </>

                        )
                    }

                  </div>
                  <IoChevronForwardOutline size={30} style={{ color: '#fff' }} />
                </div>
            </>
          );
        } else
          return (
            <div
              onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
              onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
              key={`${category}-${Math.random()}`}
              onClick={() => handleCardClick(subcategory)}
              style={{
                display: 'flex',
                alignItems: 'center',
                background: '#141617',
                padding: '5px',
                paddingTop: '10px',
                paddingBottom: '10px',
                border: '1px solid black', // Set border color to white
                paddingLeft: '10px',
                paddingRight: '10px',
                borderRadius: '12px',
                marginBottom: '10px',
                marginTop: '10px',
                cursor: 'pointer',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for 
              }}
            >
              <img src={dataTabs[category].icon} alt={formattedCategory} style={{ width: '40px', marginRight: '10px' }} />
              <div style={{ width: "166px" }}>
                <h4 style={{ marginBottom: '10px' }}>{formattedCategory}</h4>
                <p>{objectCount} Products</p>
              </div>
              <IoChevronForwardOutline size={30} style={{ color: '#fff' }} />
            </div>
          );
      });
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          padding: '8px',
          backgroundColor: '#141617',
          borderRadius: '6px',
          margin: '0 auto',
          width:'-webkit-fill-available'
        }}
      >
        {['Insight', 'Data', 'Survey'].map((tab) => (
          <Button
            type="text"
            key={tab} // ✅ Gunakan tab sebagai key yang unik
            id={tab}
            onClick={() => handleTabClick(tab)}
            style={{
              borderRadius: '6px',
              color: activeTab === tab ? '#ffff' : '#718096',
              fontWeight: activeTab === tab ? 'bold' : 'bold',
              padding: '0 10px',
              border: activeTab === tab ? '2px solid #333939' : '#333939',
            }}
          >
            {tab}
          </Button>
        ))}
      </div>
      <div
        style={{
          overflowY: 'auto',
          scrollbarWidth: 'none', // Firefox
          msOverflowStyle: 'none', // Internet Explorer
        }}
      >
        {renderTabContent()}
      </div>
      {showCardCatalog && <CardCatalog selectedCategory={selectedCategory} location={location} />}
      {showSideMenuCustomScoring && <MenuScoring selectedCategory={selectedCategory} location={location} />}
      <ModalDefault 
        isModal={isModalCheckout}
        data={selectedItem}
      />
    </React.Fragment>

  );
};

