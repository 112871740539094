import React, { useEffect, useRef, useState } from 'react';
import { Button, Select, notification,Tooltip } from 'antd';
// import {formatRangeNumbers, formatRangeNumbersKm2distribution } from '../../../component/newmap/helper/formatNumber';

import icDownload from '../../../asesst/new/icon/download.svg';
import icDownloadBlue from '../../../asesst/Icons/interactivemap/icon/dowloadn-blue.svg'
// import icTotal from '../../../asesst/Icons/interactivemap/icon/ic-sum-total.svg'
import icavg from '../../../asesst/Icons/interactivemap/icon/ic-sum-avg.svg'
import icmin from '../../../asesst/Icons/interactivemap/icon/ic-sum-min.svg'
import icmed from '../../../asesst/Icons/interactivemap/icon/ic-sum-med.svg'
import icmax from '../../../asesst/Icons/interactivemap/icon/ic-sum-max.svg'
import icbar from '../../../asesst/Icons/interactivemap/icon/ic-sum-bar.svg'
import icColor from '../../../asesst/Icons/interactivemap/icon/ic_outline-color-lens.svg'
import useReduxCatalog from '../../../hoc/hooks/useCatalog';

import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ChartTooltip, // Ubah nama Tooltip dari chart.js
  Legend,
} from 'chart.js';
import GridParser from '../../../component/newmap/helper/map/geojson/grid';
import { MenuCardDownloadData } from '../../../component/menu/menu.card.download.data';

// import { useNavigate } from 'react-router-dom';
import { setLayerOpacity } from '../../../component/newmap/helper/map/layer.opacity';
import { DrawerGroupColor, gradientColorsParams } from './drawer.group.color';
import { clearPolygonLayer, removeIsochronesAndPopups, removeLayerTop5, removeLayersAndSources } from '../../../component/newmap/helper/map/sourceLayer';
import { ModalSelectTypeProfileToScore } from '../modal/modal-selection-scoringToProfiling';
import { MenuProfillingToScoring } from '../../../component/menu/menu.profilingToScoring';
import { getColorFromInterpolation } from '../../../component/newmap/helper/coloring/color.helper';
import { fitMapToGeoJsonBounds } from '../../../component/newmap/helper/map/bounds.map';
// import { convertMetaDataProfilling } from '../../../component/newmap/helper/map/geojson/meta-data';
// import { setPopUpFilter } from '../../../hoc/store/catalog.store';
const { Option } = Select;
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ChartTooltip, Legend);
interface ResultCatalogProps {
  onBackClick: () => void;
  map: maplibregl.Map | null;
  draw: MapboxDraw
}

export const DrawerSeeDataProfiling: React.FC<ResultCatalogProps> = ({ onBackClick, map, draw }) => {
  const {
    setPopUpFilter,
    setShowCardDownload,
    setShowModalSelectTypeProfileToScore,
    // setShowCardChangeColor,
    setColorGroup,
    setMetaDataProfiling,
    setSelectedDataProfilling,
    showCardDownload,
    showCardChageColor,
    metaData,
    dataLayerGroup,
    popUpFilter,
    colorGroup,
    selectedDataProfiling,
    showModalSelectTypeProfileToScore,
    showSideMenuCustomProfillingToScoring,
    geojsonFitBounds
  } = useReduxCatalog();
  // new
  const hasLoadedBounds = useRef(false);
  const prevGeojsonId = useRef<string | null>(null); // Simpan ID terakhir
  
  const [subcategories, setSubcategories] = useState<any>([]);
  const [subdataItems, setSubdataItems] = useState<any>([]);
  const [subdataTimes, setSubdataTimes] = useState<any>([]);

  const [selectedCategories, setSelectedCategories] = useState<string | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string | undefined>(undefined); // ✅ Tambahkan ini
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [selectedTimes, setSelectedTimes] = useState<string | null>(null);

  const [currentColors,] = useState({
    type: 'gradient',
    color: gradientColorsParams, // Default gradient colors
  });
  // console.log(selectedDataProfiling);
  // console.log(geojsonFitBounds);
  

  const grid_lib = new GridParser();
  const filteredData = dataLayerGroup?.filter(item => popUpFilter.includes(item.columnName));

  // console.log(listMeta);

  const histogramData = filteredData?.[0]?.summary?.histogram;
  const topo5 = filteredData?.[0]?.summary?.top5;
  const ranges = histogramData?.map((item: { range: any; }) => item.range);
  const values = histogramData?.map((item: { value: any; }) => item.value);
  const avg = filteredData?.[0]?.summary?.avg_formated ?? 0; // Default 0 jika undefined
  const max = filteredData?.[0]?.summary?.max_formated ?? 0;
  const min = filteredData?.[0]?.summary?.min_formated ?? 0;
  const median = filteredData?.[0]?.summary?.median_formated ?? 0;

  const rangeColor = filteredData?.[0]?.bins ?? []; // Default array kosong jika undefined
  const binsRangeColor = Array.from(new Set<number>(rangeColor)).sort((a, b) => a - b)
  if (!binsRangeColor || binsRangeColor.length === 0) {
    // console.error("Bins range is empty or undefined.");
  }

  // Konversi data ke GeoJSON

  const convertTogeojson = topo5?.map((item: { gid: string; value: number }) => {
    try {
      // Validasi data sebelum interpolasi
      if (typeof item?.value !== "number" || !item?.gid) {
        // console.error(`Invalid data for item:`, item);
        return null; // Abaikan item yang tidak valid
      }
      const colorStops = getColorFromInterpolation(item?.value, binsRangeColor, colorGroup?.color || [])
      return {
        type: "Feature",
        properties: {
          ...item,
          color: colorStops,
        },
        geometry: {
          type: "Polygon",
          coordinates: [grid_lib._gid_to_geometry(item?.gid)], // Gunakan transformasi geometris yang sesuai
        },
      };
    } catch (error) {
      // console.error(`Failed to process item:`, item, error);
      return null; // Abaikan item yang gagal diproses
    }
  }).filter(Boolean); // Hapus elemen `null`  

  const distributionData = {
    labels: ranges,
    datasets: [
      {
        label: 'Total',
        data: values,
        backgroundColor: colorGroup?.color,
        borderRadius: 4,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 10,
        bottom: 10, // Atur padding agar label sumbu-X tidak terlalu jauh
      },
    },
    plugins: {
      legend: {
        display: false, // Sembunyikan legenda
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#FFFFFF', // Warna label x-axis
          // padding: 2, // Atur jarak label dari sumbu-X
          font: {
            size: 7, // Ubah ukuran font label y-axis

          },
        },
      },
      y: {
        ticks: {
          color: '#FFFFFF', // Warna label y-axis
          font: {
            size: 10, // Ubah ukuran font label y-axis
          },
        },
      },
    },
  };

  useEffect(() => {
    if ( metaData?.metadata_location?.length > 0) {
      const demographyCategory = metaData.metadata_location.find((cat: { category: string; }) => cat.category === "Demography");
      // Gunakan "Demography" jika ada, atau kategori pertama jika tidak ditemukan
      const defaultCategory = demographyCategory ? demographyCategory.category : metaData.metadata_location[0].category;
      handleSelectCategoryChange(defaultCategory);
      setSelectedCategories(defaultCategory)

      const subcategories = metaData?.metadata_location.find((meta: any) => meta.category === defaultCategory);
      if (subcategories?.subcategory.length > 0) {
        const defaultSubcategory = subcategories?.subcategory.find((meta:any) => meta.name === 'Population'); // Pilih subcategory pertama
        setSelectedSubcategory(defaultSubcategory?.metadataPath); // ✅ Simpan value subcategory yang dipilih
        handleSelectSubCategoryChange(defaultSubcategory?.metadataPath); // Trigger perubahan subcategory
      }
      
    }
  
    // eslint-disable-next-line 
  }, [ metaData?.metadata_location]); // Jalankan saat `meta` berubah

  useEffect(() => {
    if (map && geojsonFitBounds) {
      // Cek apakah ID berbeda
      if (!hasLoadedBounds.current || prevGeojsonId.current !== geojsonFitBounds.id) {
        fitMapToGeoJsonBounds(map, geojsonFitBounds.features);
        hasLoadedBounds.current = true;
        prevGeojsonId.current = geojsonFitBounds.id; // Simpan ID terbaru
      }
    }
  }, [map, geojsonFitBounds]); // Depend hanya pada perubahan map & geojsonFitBounds
  
  
  const handleSelectCategoryChange = (value: any) => {
    setSubcategories([]);
    setSubdataItems([]);
    setSelectedSubcategory(undefined);
    setSelectedCategories(value)
    
    const selectedMeta = metaData?.metadata_location.find((meta: any) => meta.category === value);
  
    if (selectedMeta) {
      setSubcategories(selectedMeta.subcategory);
    }
  };



  const handleSelectSubCategoryChange = async (value: any) => {
    try {
      setSelectedDataProfilling(value);
      // console.log(value);
      
    } catch (error) {
      console.error("Error fetching subdata:", error);
    }
  };
  // };
  const handleSelectItemChange = async (value: string, option: any) => {
    const { subdataName, times } = option.data;
    try {
        if (selectedDataProfiling?.data?.isSeries) {
            const selectedTime = selectedDataProfiling?.data?.subdata.find(
                (sub: any) => sub?.subdataName === subdataName
            );
            setSelectedItem(value); // ✅ Simpan layer baru sebagai selectedItem
            setSubdataTimes(selectedTime);
            setMetaDataProfiling(option?.data);
        } else {
            setSelectedItem(value); // ✅ Simpan layer baru sebagai selectedItem
            setPopUpFilter([value]);

            // Jika `times` ada, lewati pengaturan opacity layer
            if (!times) {
                if (map && selectedItem) {
                    setLayerOpacity(map, "layer", selectedItem, 0); // ✅ Nonaktifkan layer sebelumnya
                }

                if (map && value) {
                    setLayerOpacity(map, "layer", value, 0.8); // ✅ Aktifkan layer baru
                }
            }
        }
    } catch (error) {
        console.error("Error fetching subdata:", error);
    }
};
// console.log(subdataTimes);


  const handleSelectTimesChange = async (value: string,) => {
    // console.log(value);
    // console.log(selectedTimes);
    try {

      if (map && selectedTimes) {
        setLayerOpacity(map, 'layer', selectedTimes, 0); // ✅ Nonaktifkan layer sebelumnya
      }

      if (map && value) {
        setLayerOpacity(map, 'layer', value, 0.8); // ✅ Aktifkan layer baru
      }

      setSelectedTimes(value); // ✅ Simpan layer baru sebagai selectedItem
      setPopUpFilter([value]);


    } catch (error) {
      console.error("Error fetching subdata:", error);
    }
  };

  useEffect(() => {
  
    if (selectedDataProfiling?.statusCode === 200 && selectedDataProfiling.data?.subdata) {
      if (map) {
        dataLayerGroup?.forEach((layer, index) => {
          const isDefault = index === 0; // Jadikan layer pertama default terbuka
          setLayerOpacity(map, 'layer', layer.columnName, isDefault ? 0.8 : 0);
        });
        setSelectedTimes(dataLayerGroup[0]?.columnName);
        setPopUpFilter([dataLayerGroup[0]?.columnName])
        setSubdataItems(selectedDataProfiling.data?.subdata);
      }


      if (!selectedDataProfiling.data?.isSeries) {
        setMetaDataProfiling(selectedDataProfiling?.data);
        if (map) {
          dataLayerGroup?.forEach((layer, index) => {
            const isDefault = index === 0; // Jadikan layer pertama default terbuka
            setLayerOpacity(map, 'layer', layer.columnName, isDefault ? 0.8 : 0);
          });
        }
        setPopUpFilter([dataLayerGroup[0]?.columnName])
        setSelectedItem(dataLayerGroup[0]?.columnName)
      }
    } else {
      setSubdataItems([]);
      setSelectedTimes(null);
      setMetaDataProfiling(null);
    }
  
    // eslint-disable-next-line
  }, [dataLayerGroup, map,selectedDataProfiling]);
  
  useEffect(() => {
    if (!selectedSubcategory) {
      setMetaDataProfiling(null);
      setSubdataItems([]);
      setSubdataTimes([])
    }
    // eslint-disable-next-line 
  }, [selectedSubcategory]);

  useEffect(() => {
    setColorGroup(currentColors)
    // eslint-disable-next-line
  }, [currentColors])



  // const handleCardChangeColorClick = () => {
  //   // setShowCardDownload(false);
  //   setShowCardChangeColor(true)
  // };

  const handleCardDownloadclick = () => {
    setShowCardDownload(true);
    // setShowCardChangeColor(false)
  };

  const handleBackResult = () => {
    if (map) {
      clearPolygonLayer(map, draw); // Remove the polygon layer if it exists
      removeIsochronesAndPopups(map);
      removeLayersAndSources(map);
      removeLayerTop5(map)
      setMetaDataProfiling(null);
      setSubdataItems([]);
      setSubdataTimes([])
    }
    onBackClick()
  };

  const handleshowScoring = () => {
    setShowModalSelectTypeProfileToScore(true)
  };
  
  const handleFlyTo = (item: any) => {
    if (map) {
      // Ambil koordinat dari GeoJSON (ambil titik pertama dalam polygon)
      const coordinates = item.geometry.coordinates[0][0]; // Koordinat pertama dalam polygon

      if (coordinates) {
        // Tambahkan layer dengan warna pada grid target
        const sourceId = 'highlighted-grid-source';
        const layerId = 'highlighted-grid-layer';

        // Periksa apakah source/layer sudah ada, jika ya, hapus
        if (map.getSource(sourceId)) {
          map.removeLayer(`${layerId}-outline`);
          map.removeLayer(layerId);
          map.removeSource(sourceId);
        }
        // Tambahkan source baru untuk grid
        map.addSource(sourceId, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [item], // Gunakan fitur GeoJSON yang diterima
          },
        });

        // Tambahkan layer baru dengan warna fill
        map.addLayer({
          id: layerId,
          type: 'fill',
          source: sourceId,
          paint: {
            'fill-color': 'transparent',
            'fill-opacity': 1, // Transparansi
            'fill-outline-color': 'black',
          },
        });

        map.addLayer({
          id: `${layerId}-outline`, // ID unik untuk outline
          type: 'line',
          source: sourceId,
          paint: {
            'line-color': 'black', // Warna garis tepi
            'line-width': 2, // Ketebalan garis tepi
          },
        });

        // Pindahkan tampilan peta ke grid yang dituju
        map.flyTo({
          center: coordinates, // Format [longitude, latitude]
          zoom: 20,
          speed: 1.2,
          curve: 1, // Smooth fly effect
        });
      } else {
        notification.warning({
          message: 'Invalid Coordinates',
          description: 'Unable to extract coordinates from the selected feature.',
        });
      }
    } else {
      notification.warning({
        message: 'Map instance is not available',
        description: 'Ensure the map is loaded before using this feature.',
      });
    }
  };

  return (
    <React.Fragment>
      {/* Back Arrow */}
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px',
        cursor: 'pointer'
      }}>
        {/* Kiri: Arrow dan Result Location */}
        <span onClick={handleBackResult} style={{ fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
          ← Result Location
        </span>

        {/* Kanan: Icon Download & Change Color */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Tooltip
            placement="bottom"
            overlayInnerStyle={{ backgroundColor: '#343A3A', color: 'white' }} // Ganti warna bg dan teks
            title={
              <div style={{ textAlign: 'left' }}>
                <strong style={{ display: 'block', marginBottom: '3px' }}>Change Color</strong>
              
              </div>
            }
          >
            <img
              src={icColor}
              alt="Download"
              style={{ width: '25px', height: '25px', cursor: 'pointer' }}
              // onClick={handleCardChangeColorClick}
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            overlayInnerStyle={{ backgroundColor: '#343A3A', color: 'white' }} // Ganti warna bg dan teks
            title={
              <div style={{ textAlign: 'left' }}>
                <strong style={{ display: 'block', marginBottom: '3px' }}>Download Data</strong>
              
              </div>
            }
          > 
          <img
          src={icDownload}
          alt="Change Color"
          style={{ width: '25px', height: '25px', cursor: 'pointer' }}
          onClick={handleCardDownloadclick}
        />
          </Tooltip>
         
        </div>
      </div>
      <div style={{
        overflowY: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
      }}>


        <div
          style={{
            alignItems: 'center',
            background: '#141617',
            padding: '5px',
            borderRadius: '12px',
            marginBottom: '5px',
            paddingTop: '10px',
            paddingBottom: '10px',
            border: '1px solid black', // Set border color to white
            paddingLeft: '10px',
            paddingRight: '10px',
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for
          }}
        >
          <div style={{ width: '166px' }}>
            <h4 style={{ marginBottom: '10px', fontSize: '14px' }}>{metaData?.alias}</h4>
          </div>
          <div>
            <p style={{ fontSize: '11px' }}> {metaData?.description}</p>
          </div>
        </div>
        <div
          style={{
            alignItems: 'center',
            background: '#141617',
            padding: '5px',
            borderRadius: '12px',
            marginBottom: '5px',
            paddingTop: '10px',
            paddingBottom: '10px',
            border: '1px solid black', // Set border color to white
            paddingLeft: '10px',
            paddingRight: '10px',
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for
          }}
        >
          <h4 style={{ marginBottom: '10px', fontSize: '14px' }}>Raw Data</h4>
          <div style={{ paddingBottom: '10px', paddingTop: '10px' }}>
            <Select 
              style={{ width: 300, marginBottom: 10 }}
              placeholder={<span style={{ color: "white" }}>select category</span>} // ✅ Warna placeholder
              value={selectedCategories}
              onChange={handleSelectCategoryChange}>
              {metaData?.metadata_location?.map((meta: any) => (
                <Option key={meta.category} value={meta.category}>
                  {meta.category}
                </Option>
              ))}
            </Select>
            {
              subcategories?.length > 0 && (
                <Select
                  style={{ width: 300, marginBottom: 10 }}
                  placeholder={<span style={{ color: "white" }}>select data</span>} // ✅ Warna placeholder
                  value={selectedSubcategory} // ✅ Agar placeholder muncul setelah reset
                  onChange={(value) => {
                    setSelectedSubcategory(value); // ✅ Simpan value subcategory yang dipilih
                    handleSelectSubCategoryChange(value);
                  }}
                >
                  {subcategories?.map((sub: any) => (
                    <Option key={sub.name} value={sub.metadataPath}>
                      {sub.name}
                    </Option>
                  ))}
                </Select>
              )
            }
            {subdataItems?.length > 0 && (
              <Select
                placeholder={<span style={{ color: "white" }}>select item </span>}
                style={{ width: 300, marginBottom: 10 }}
                value={selectedItem} // ✅ Agar placeholder muncul setelah reset
                onChange={handleSelectItemChange}
              >
                {subdataItems?.map((sub: any) => (

                  <Option key={sub.subdataName || sub.columnName} value={sub.columnName || sub.subdataName} data={sub}>
                    {sub.subdataName} 
                  </Option>
                ))}
              </Select>
            )}

            {subdataTimes?.times?.length > 0 && (
              <Select
                placeholder={<span style={{ color: "white" }}>select times</span>}
                style={{ width: 300 }}
                value={selectedTimes} // ✅ Agar placeholder muncul setelah reset
                onChange={handleSelectTimesChange}
              >
                {subdataTimes?.times?.map((sub: any) => (
                  <Option key={sub.seriesName} value={sub.columnName} data={sub}>
                    {sub.subdataName}
                  </Option>
                ))}
              </Select>
            )}
          </div>
        </div>
       
        { dataLayerGroup.length > 0 && (
          <>
            <Button
              type="primary"
              style={{
                height: '40px',
                width: '100%',
                borderRadius: '8px',
                marginTop: '10px',
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between', // Membuat jarak maksimum antara ikon di kanan dan konten kiri
                padding: '0 15px',
                fontSize: '14px',
                fontWeight: 700,
                backgroundColor: '#4A7FC0',
                color: 'white',
                border: '1px solid #4A7FC0',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              }}
            onClick={handleshowScoring}
            >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img alt="download icon" src={icbar} style={{ marginRight: '5px' }} />
              Generate Scoring
          </div>
        </Button>
           <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '10px'
          }}>
            <h3 style={{ margin: 0 }}>Summary</h3>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img alt="download icon" src={icDownloadBlue} style={{
                marginRight: '5px',
                width: '25px',
                height: '25px', // Sesuaikan ukuran tinggi
              }} />
              <p
                style={{
                  color: '#4A7FC0',
                  textDecoration: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  cursor: 'pointer'
                }}
              >
                Generate Summary
              </p>
            </div>
          </div>
          <div style={{
            padding: '10px',
            background: '#141617',
            borderRadius: '8px'
          }}>
              <div
                style={{
                  background: '#141617',
                  padding: '10px',
                  borderRadius: '8px',
                  border: '2px solid #3B669A',
                  textAlign: 'left',
                  marginBottom: '5px'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                  {/* Ikon di sebelah kiri */}
                  <img
                    src={icavg} // Ganti URL ini dengan path ke ikon Anda
                    alt="icon" />
                  <h3 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Average per Square</h3>
                </div>
                {dataLayerGroup.length < 1 ? (
                  <p>loading...</p>
                ) : (
                  <h3 style={{ margin: 0 }}>
                    {avg}
                  </h3>
                )}
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  gap: '5px',
                  maxWidth: '320px',
                  margin: '0 auto', // Pusatkan grid secara horizontal
                }}
              >
                <div
                  style={{
                    background: '#141617',
                    padding: '10px',
                    border: '2px solid #3B669A',
                    borderRadius: '8px',
                    maxWidth: '100px',
                    textAlign: 'left',
                  }}
                >

                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    {/* Ikon di sebelah kiri */}
                    <img
                      src={icmin} // Ganti URL ini dengan path ke ikon Anda
                      alt="icon" />
                    <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Min</h4>
                  </div>
                  {dataLayerGroup.length < 1 ? (
                    <p>loading...</p>
                  ) : (
                    <h3 style={{ margin: 0 }}>
                     
                            {min}
                    </h3>
                  )}
                </div>

                <div
                  style={{
                    background: '#141617',
                    padding: '10px',
                    maxWidth: '100px',
                    border: '2px solid #3B669A',
                    borderRadius: '8px',
                    textAlign: 'left',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    {/* Ikon di sebelah kiri */}
                    <img
                      src={icmed} // Ganti URL ini dengan path ke ikon Anda
                      alt="icon" />
                    <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Med</h4>
                  </div>
                  {dataLayerGroup.length < 1 ? (
                    <p>loading...</p>
                  ) : (
                    <h3 style={{ margin: 0 }}>
                   
                            {median}
                    </h3>
                  )}
                </div>
                <div
                  style={{
                    background: '#141617',
                    padding: '10px',
                    border: '2px solid #3B669A',
                    borderRadius: '8px',
                    maxWidth: '100px',
                    textAlign: 'left',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    {/* Ikon di sebelah kiri */}
                    <img
                      src={icmax} // Ganti URL ini dengan path ke ikon Anda
                      alt="icon" />
                    <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Max</h4>
                  </div>
                  {dataLayerGroup.length < 1 ? (
                    <p>loading...</p>
                  ) : (
                    <h3 style={{ margin: 0 }}>
                    
                            {max}

                    </h3>
                  )}
                </div>


              </div>
              <div
                style={{
                  background: '#141617',
                  borderRadius: '8px',
                  padding: '15px',
                  marginTop: '20px',
                  border: '2px solid #3B669A',
                  height: '300px', // Tinggi khusus untuk Chart.js
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                  {/* Ikon di sebelah kiri */}
                  <img
                    src={icbar} // Ganti URL ini dengan path ke ikon Anda
                    alt="icon" />
                  <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Distribution</h4>
                </div>
                {dataLayerGroup.length < 1 ? (
                  <p>loading...</p>
                ) : (
                  // null
                  <Bar data={distributionData} options={options} />
                )}
              </div>
              <div
                style={{
                  background: '#141617',
                  borderRadius: '8px',
                  padding: '15px',
                  border: '2px solid #3B669A',
                  marginTop: '20px',
                }}
              >

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  {/* Ikon di sebelah kiri */}
                  <img
                    src={icbar} // Ganti URL ini dengan path ke ikon Anda
                    alt="icon" />
                  <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Top 5 squares with highest number</h4>
                </div>
                {convertTogeojson?.map((number: any, index: any) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <div
                        style={{
                          width: '20px',
                          height: '20px',
                          borderRadius: '20%',
                          backgroundColor: number?.properties?.color,
                        }}
                      >

                      </div>
                      {number?.properties?.formated}
                    </div>
                    <p
                      style={{ color: '#4A7FC0', fontSize: '14px', cursor: 'pointer' }}
                      onClick={() => handleFlyTo(number)}
                    >
                      View
                    </p>
                  </div>
                ))}
              </div>

            </div>
            </>
          )}

        {showCardDownload && <MenuCardDownloadData />}
        {showCardChageColor && <DrawerGroupColor />}
        <ModalSelectTypeProfileToScore isSelectTypeProfileToScore={showModalSelectTypeProfileToScore} data={metaData}/>
      
       
      </div>
      {showSideMenuCustomProfillingToScoring && <MenuProfillingToScoring selectedCategory={metaData} />}
    </React.Fragment>
  );
};
