import React, { useEffect, useRef, useState } from "react";
import { XMapPoint } from "../map/content/mapPoint";
import iconHeader from "../../asesst/new/icon/iconpdf.svg"
import iconLoc from "../../asesst/new/icon/mdi_address-marker-outline.png"
import iconPos from "../../asesst/new/icon/flowbite_globe-outline.png"
import iconRes from "../../asesst/new/icon/f7_resize.png"

import iconHub from "../../asesst/new/icon/mingcute_building-6-line.svg"
import iconDemo from "../../asesst/new/icon/demogrphy.svg"
import iconAccess from "../../asesst/new/icon/solar_accessibility-outline.svg"
import iconKey from "../../asesst/new/icon/hugeicons_house-04.svg"

import iconavg from "../../asesst/new/icon/hugeicons_chart-average.svg"
import iconmin from "../../asesst/new/icon/tdesign_chart-minimum.svg"
import iconmed from "../../asesst/new/icon/carbon_chart-median.svg"
import iconmax from "../../asesst/new/icon/icon-park-outline_maximum.svg"
import icontotal from "../../asesst/new/icon/bi_textarea.svg"
import iconArea from "../../asesst/new/icon/totalarea.svg"
import iconDisCart from "../../asesst/new/icon/distirbutioncart.svg"
import iconTop5 from "../../asesst/new/icon/ion_podium-outline.svg"

import { FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { LoadingOutlined } from '@ant-design/icons';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
// import GridParser from "../newmap/helper/map/geojson/grid";
import { getColorFromInterpolation } from "../newmap/helper/coloring/color.helper";
import useReduxCatalog from "../../hoc/hooks/useCatalog";
import jsPDF from "jspdf";
import bgReport from '../../asesst/new/img/bg-report.png'
import bg2 from '../../asesst/new/icon/bg2.png'
import { Spin } from "antd";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
interface TrafficData {
    total: number;
    earlymorning: number;
    morning: number;
    afternoon: number;
    evening: number;
    night: number;
}

interface Overview {
    title: string;
    overview: string;
}

interface DetailData {
    title: string;
    range: string;
    summary: string;
    color: string;
}

interface Detail {
    title: string;
    data: DetailData[];
}

interface Recommendation {
    title: string;
    recomendation: string;
}

interface MapData {
    title: string;
    location: string;
    coordinates: string;
    size_of_area: string;
    overview: Overview[];
    detail: Detail[];
    recomendations: Recommendation[];
}


type SummaryTraficHistogram = Record<string, TrafficData>;

const GenerateSummaryPage: React.FC = () => {
    const location = useLocation();
    const { dataLayerGroup, colorGroup, metaData } = location.state || [];
    const mapRef1 = useRef<HTMLDivElement | null>(null);
    const mapRefs = useRef<(HTMLDivElement | null)[]>([]); // Arr
    const {
        setGenerateDescription
    } = useReduxCatalog();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);
    const [responseData, setResponseData] = useState<MapData[] | undefined>([]);
    const filteredData = dataLayerGroup?.filter((item: { combinedName: any }) => item.combinedName === 'All');
    const summaryAll = dataLayerGroup?.[0]?.summaryall ?? 0;
    const summaryAllTotal = summaryAll?.total?.total_formated ?? 0;
    const avg = dataLayerGroup[0]?.isSeries ? dataLayerGroup[0]?.summaryall?.avg_formated : filteredData?.[0]?.summary?.avg_formated
    const max = dataLayerGroup[0]?.isSeries ? dataLayerGroup[0]?.summaryall?.max_formated : filteredData?.[0]?.summary?.max_formated
    const min = dataLayerGroup[0]?.isSeries ? dataLayerGroup[0]?.summaryall?.min_formated : filteredData?.[0]?.summary?.min_formated
    const median = dataLayerGroup[0]?.isSeries ? dataLayerGroup[0]?.summaryall?.median_formated : filteredData?.[0]?.summary?.median_formated


    const summaryAllHistogram = summaryAll?.total?.detail ?? 0;
    const labelNonTrarfic = filteredData?.[0]?.productName ?? '';
    // const topo5 = filteredData?.[0]?.summary?.top5;
    const topo5 =  dataLayerGroup[0]?.isSeries ? dataLayerGroup[0]?.summaryall?.top5 : filteredData?.[0]?.summary?.top5;
    // const rangeColor = filteredData?.[0]?.bins ?? []; // Default array kosong jika undefined
    const rangeColor = dataLayerGroup[0]?.isSeries ? dataLayerGroup[0]?.bins : filteredData?.[0]?.bins;
    const binsRangeColor = Array.from(new Set<number>(rangeColor)).sort((a, b) => a - b)
    if (!binsRangeColor || binsRangeColor.length === 0) {
        // console.error("Bins range is empty or undefined.");
        
    }
   
    const downloadPDF = async () => {
        setIsLoadingPdf(true); // Aktifkan loader

        try {
            const sections = document.querySelectorAll(".page"); // Pilih semua elemen dengan kelas "page"
            const pdf = new jsPDF({
                orientation: "landscape",
                unit: "mm",
                format: "a4",
            });

            for (let i = 0; i < sections.length; i++) {
                const element = sections[i] as HTMLElement;
                const canvas = await html2canvas(element, {
                    scale: 2,
                    backgroundColor: null, // Preserve transparency if needed
                    logging: true, // Enable logging for debugging
                }); // Ubah div menjadi canvas
                const imageData = canvas.toDataURL("image/png");
                const imgProps = pdf.getImageProperties(imageData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                // Tambahkan setiap elemen sebagai halaman baru di PDF
                if (i > 0) pdf.addPage(); // Tambahkan halaman baru setelah halaman pertama
                pdf.addImage(imageData, "PNG", 0, 0, pdfWidth, pdfHeight);
            }

            pdf.save(`summary_${metaData?.product}.pdf`);
        } catch (error) {
            console.error("Error generating PDF:", error);
        } finally {
            setIsLoadingPdf(false); // Nonaktifkan loader
        }
    };

    const convertTogeojson = topo5?.map((item: { gid: string; value: number }) => {
        try {
            // Validasi data sebelum interpolasi
            if (typeof item?.value !== "number" || !item?.gid) {
                // console.error(`Invalid data for item:`, item);
                return null; // Abaikan item yang tidak valid
            }
            const colorStops = getColorFromInterpolation(item?.value, binsRangeColor, colorGroup?.color || [])
            return {
                properties: {
                    ...item,
                    color: colorStops,
                },
            };
        } catch (error) {
            console.error(`Failed to process item:`, item, error);
            return null; // Abaikan item yang gagal diproses
        }
    })

    const summaryTraficHistogram: SummaryTraficHistogram = dataLayerGroup?.[0]?.summaryall?.total?.detail ?? {};
    const labelsTrafic = ["Early Morning", "Morning", "Afternoon", "Evening", "Night"];
    const datasetsTrafic = Object.entries(summaryTraficHistogram).map(([key, values], index) => ({
        label: key.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase()), // Format label
        data: [
            values?.earlymorning,
            values?.morning,
            values?.afternoon,
            values?.evening,
            values?.night,
        ],
        backgroundColor: ["#E57373", "#64B5F6", "#81C784", "#FFD700", "#FF8C00"][
            index % 5
        ], // Warna berdasarkan index
        borderWidth: 1,
        borderRadius: 12,
    }));

    const chartDataTrafic = {
        labels: labelsTrafic,
        datasets: datasetsTrafic,
    };
    // console.log('report',chartDataTrafic);

    const optionsTrafic = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "bottom" as const, // Posisi legend
                labels: {
                    boxWidth: 10, // Lebar kotak warna
                    color: "black", // Warna teks legend
                },
                onClick: () => { }, // Nonaktifkan interaksi klik pada legend
            },
            title: {
                // text: "Population by Time of Day",
                display: true,
                color: "black", // Warna teks legend
                font: {
                    size: 8,
                },
            },
        },
        layout: {
            padding: {
                top: 10,
                bottom: 10,
                left: 10,
                right: 10,
            },
        },
        scales: {
            x: {
                grid: {
                    drawBorder: false, // Menghapus garis sumbu X
                    display: false // Menghapus gridlines di sumbu X
                },
                
                stacked: true, // Bar stack di X-axis
                ticks: {
                    font: {
                        size: 8, // Ubah ukuran font label y-axis

                    },
                    color: "black", // Warna teks legend
                },
            },
            y: {
                stacked: true, // Bar stack di Y-axis
                ticks: {
                    font: {
                        size: 8, // Ubah ukuran font label y-axis

                    },
                    color: "black", // Warna teks legend
                    beginAtZero: true, // Mulai dari nol
                },
                grid: {
                    drawBorder: false, // Menghapus garis sumbu X
                    display: false // Menghapus gridlines di sumbu X
                },
            },
        },
    };

    const labels = [labelNonTrarfic]; // Label untuk X-axis
    const sortedDatasets = Object.entries(summaryAllHistogram)
    .filter(([key]) => !key.includes("formated")) // 🔥 Filter out "formated"
        .sort(([, valueA], [, valueB]) => (valueA as number) - (valueB as number))
        .map(([key, value], index) => ({
            label: key.replace(/_/g, ' '),
            data: [value as number], // Nilai data (harus array sejajar dengan labels)
            backgroundColor: [
                "#E57373", // Warna awal
                "#64B5F6",
                "#81C784",
                "#FFD700", // Warna tambahan
                "#FF8C00",
                "#FF69B4",
                "#BA55D3",
                "#40E0D0",
                "#7FFFD4",
                "#4682B4",
                "#DC143C",
                "#8B0000",
                "#00FA9A"
            ][index], // Warna
            borderRadius: 12, // Styling opsional
        }));
    // total stacked bar
    const dataTotal = {
        labels: labels, // Label X-axis
        datasets: sortedDatasets, // Dataset yang sudah dipetakan
    };

    const optionsTotal = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "bottom" as const, // Posisi legend
                labels: {
                    boxWidth: 12, // Lebar kotak warna
                    color: "#030202", // Warna teks legend
                },
                // onClick: () => { }, // Nonaktifkan interaksi klik pada legend
            },
            title: {
                display: true,
                color: "#030202", // Warna teks legend
                font: {
                    size: 18,
                },
            },
        },
        layout: {
            padding: {
                top: 10,
                bottom: 10,
                left: 10,
                right: 10,
            },
        },
        scales: {
            x: {
                grid: {
                    drawBorder: false, // Menghapus garis sumbu X
                    display: false // Menghapus gridlines di sumbu X
                },
                stacked: true, // Bar stack di X-axis
                ticks: {
                    color: "#030202", // Warna teks legend
                },
            },
            y: {
                stacked: true, // Bar stack di Y-axis
                ticks: {
                    font: {
                        size: 8, // Ubah ukuran font label y-axis

                    },
                    color: "#030202", // Warna teks legend
                    beginAtZero: true, // Mulai dari nol
                },
                grid: {
                    drawBorder: false, // Menghapus garis sumbu X
                    display: false // Menghapus gridlines di sumbu X
                },
            },
        },
    };

    const chartData = {
        // labels: filteredData?.[0]?.histogram?.map((h: any) => h.range),
        labels: dataLayerGroup[0]?.isSeries ? dataLayerGroup?.[0]?.summaryall?.histogram?.map((h: any) => h.range) : filteredData?.[0]?.histogram?.map((h: any) => h.range),
        datasets: [
            {
                // label: filteredData?.[0]?.productName,
                label: dataLayerGroup[0]?.isSeries ? dataLayerGroup[0]?.seriesName : filteredData?.[0]?.productName,
                // data: filteredData?.[0]?.histogram?.map((h: { value: any; }) => h.value),
                data: dataLayerGroup[0]?.isSeries ? dataLayerGroup?.[0]?.summaryall?.histogram?.map((h: { value: any; }) => h.value) : filteredData?.[0]?.histogram?.map((h: { value: any; }) => h.value),
                backgroundColor: colorGroup?.color,
                borderRadius: 12, // Styling opsional
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 10,
                bottom: 10, // Atur padding agar label sumbu-X tidak terlalu jauh
            },
        },
        plugins: {
            legend: {
                display: false, // Sembunyikan legenda
            },

        },
        scales: {
            x: {
                grid: {
                    drawBorder: false, // Menghapus garis sumbu X
                    display: false // Menghapus gridlines di sumbu X
                },
                ticks: {
                    color: '#030202', // Warna label x-axis
                    // padding: 2, // Atur jarak label dari sumbu-X
                    font: {
                        size: 7, // Ubah ukuran font label y-axis
                    },
                },
            },
            y: {
                stacked: true, // Bar stack di Y-axis
                ticks: {
                    font: {
                        size: 8, // Ubah ukuran font label y-axis

                    },
                    color: "#030202", // Warna teks legend
                    beginAtZero: true, // Mulai dari nol
                },
                grid: {
                    drawBorder: false, // Menghapus garis sumbu X,
                    display: false // Menghapus gridlines di sumbu X
                },
            },
        },
    };
    const statsData = [
        // { label: "Total Number", value: summaryAllTotal, icon: "📊" },
        { label: "Average per Square", value: avg, icon: iconavg },
        { label: "Min", value: min, icon: iconmin },
        { label: "Med", value: median, icon: iconmed },
        { label: "Max", value: max, icon: iconmax },
    ];
    const hasCapturedScreenshot = useRef(false); // Ref untuk memastikan fungsi hanya dipanggil sekali
    useEffect(() => {
        // Tunggu 5 detik setelah map selesai dirender
        setIsLoading(true); // Mulai loading
        const timer = setTimeout(() => {
            if (!hasCapturedScreenshot.current) {
                captureScreenshot();
                hasCapturedScreenshot.current = true; // Set menjadi true agar tidak dipanggil lagi
            }
        }, 10000);

        return () => {
            setIsLoading(false); // Mulai loading
            clearTimeout(timer); // Bersihkan timer jika komponen unmount
        };
        // eslint-disable-next-line
    }, []); // Efek ini hanya dijalankan sekali pada mount

    const captureScreenshot = async () => {
        try {
            setIsLoading(true); // Mulai loading
            const formData = new FormData();
            // Capture screenshot for mapRef1
                if (mapRef1.current) {
                    const canvas1 = await html2canvas(mapRef1.current, {
                        scale: 0.5, // Mengurangi resolusi gambar hingga setengah
                        backgroundColor: null,
                    });
                    const image1 = canvas1.toDataURL("image/png", 0.8);
                    const file1 = dataURLToFile(image1, "screenshot1.png");
                    formData.append("image", file1);
                    // formData.append("column_name", filteredData[0]?.columnName);
                    formData.append(
                        "column_name",
                        dataLayerGroup[0]?.isSeries ? dataLayerGroup[0]?.columnName : filteredData[0]?.columnName
                    );
    
                }


            // Capture screenshot for mapRef2
            for (let i = 0; i < dataLayerGroup.length; i++) {
                const element = mapRefs.current[i];
                if (element) {
                    const canvas = await html2canvas(element as HTMLElement, {
                        scale: 0.5, // Mengurangi resolusi gambar hingga setengah
                        backgroundColor: null,
                    });
                    const image = canvas.toDataURL("image/png", 0.8);
                    const file = dataURLToFile(image, `screenshot${i + 2}.png`);
                    formData.append("image", file);
                    formData.append(
                        "column_name",
                        dataLayerGroup[i]?.columnName || `column_${i}`
                    );
                }
            }

            // Add metadata_path (hanya ditambahkan sekali karena satu metadata_path digunakan untuk semua)
            formData.append(
                "metadata_path",
                metaData?.metadata_location
            );

            // Kirim data ke API
            const response = await setGenerateDescription(formData)

            if (response) {
                setResponseData(response); // Simpan respons ke state
            }
        } catch (error) {
            setIsLoading(false); // Akhiri loading
        } finally {
            setIsLoading(false); // Akhiri loading
        }
    };

    // Utility function to convert Data URL to File
    const dataURLToFile = (dataURL: string, fileName: string): File => {
        const arr = dataURL.split(",");
        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
    };



    const overviewData = responseData?.[0]?.detail?.reduce<any[]>((acc, current) => {
        // Periksa apakah ada elemen dengan title yang sama di accumulator
        const isDuplicate = acc.some(item => item.title === current.title);
        if (!isDuplicate) {
            acc.push(current); // Tambahkan ke accumulator jika belum ada
        }
        return acc; // Kembalikan accumulator
    }, []);
    // console.log('overviewData', overviewData);
    return (
        <>
        <div style={{
             position: "fixed", // Tetap di posisi tetap
             top: "50%", // Jarak dari atas
             zIndex:'1000'
        }}>
            <Spin
             spinning={isLoadingPdf} 
             indicator={<LoadingOutlined spin />} 
             size="large"
              tip="Downloading PDF..."/>
        </div>
            {/* Page 1: Overview Section */}
            <div style={{ width: '800px' }}>
                <div
                    onClick={overviewData !== undefined ? downloadPDF : undefined}
                    style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#fff",
                        padding: "5px 15px",
                        borderRadius: "5px",
                        display: "inline-block",
                        cursor: overviewData !== undefined ? "pointer" : "not-allowed",
                        backgroundColor: overviewData !== undefined ? "#C33A36" : "#ccc",
                        position: "fixed", // Tetap di posisi tetap
                        top: "20px", // Jarak dari atas
                        right: "200px", // Jarak dari kanan
                        zIndex: 1000, // Pastikan di atas elemen lain
                    }}
                >
                    Download PDF
                </div>
            </div>

            <div className="page" style={page1ContainerStyle}>
                {/* Left Section (Info Boxes) */}
                <div style={page1LeftSectionStyle}>
                    <div style={page1LogoStyle}>
                        <img
                            src={iconHeader}
                            alt="Logo"
                        />
                    </div>

                    <div style={page1InfoContainerStyle}>
                        <div style={page1InfoBoxStyle}>
                            <div style={page1InfoTitleStyle}>
                                <img
                                    src={iconLoc}
                                    height={20}
                                    alt="Logo"
                                />Location Address
                            </div>

                            {isLoading ? (
                                <div style={loadingStyle}>loading...</div>
                            ) : (
                                <div style={page1InfoContentStyle}>
                                    {metaData?.address}
                                </div>
                            )}

                        </div>

                        <div style={page1InfoBoxStyle}>
                            <div style={page1InfoTitleStyle}>
                                <img
                                    src={iconPos}
                                    height={20}
                                    alt="Logo"
                                />Position
                            </div>
                            <div style={page1InfoContentStyle}>
                                {isLoading ? (
                                    <div style={loadingStyle}>loading...</div>
                                ) : (
                                    <div style={page1InfoContentStyle}>
                                        {metaData?.centroid?.[1]}, {metaData?.centroid?.[0]}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div style={page1InfoBoxStyle}>
                            <div style={page1InfoTitleStyle}>
                                <img
                                    src={iconRes}
                                    height={20}
                                    alt="Logo"
                                />Size of the area
                            </div>
                            {isLoading ? (
                                <div style={loadingStyle}>loading...</div>
                            ) : (
                                <div style={page1InfoContentStyle}>
                                    {responseData?.[0]?.size_of_area}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "right",
                        justifyContent: "center",
                        textAlign: "right",
                        backgroundImage: `url(${bgReport})`,
                        backgroundPosition: "bottom right",
                        // width: '390px',
                        backgroundSize: "60%",  // Mengubah ukuran gambar menjadi 50%
                        backgroundRepeat: 'no-repeat',

                        // width: "100%",
                        minHeight: "60vh",
                        // padding: "20px",
                    }}
                >
                    <div style={page1ReportTitleStyle}>SUMMARY REPORT</div>
                    <div style={page1SubtitleStyle}>{metaData?.alias}</div>
                </div>

            </div>

            <div className="page" style={page1ContainerStyle}>
                <section>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h2 style={{ fontSize: "20px", color: "#444" }}>
                            Summary All
                        </h2>

                        <span style={{ fontSize: "24px", color: "#444", cursor: "pointer" }}>
                            <img
                                src={iconHeader}
                                alt="Logo"
                            />
                        </span>
                    </div>
                    <h3 style={{ fontSize: "18px", color: "#333" }}>
                        MAPS
                    </h3>
                    <div

                        style={{

                            display: "flex",
                            gap: "20px",
                            marginBottom: "5px",
                        }}
                    >

                        <div
                            ref={mapRef1} // Reference added here
                            style={{
                                flex: 1,
                                minWidth: '400px',
                                borderRadius: "8px",
                                overflow: "hidden",
                            }}
                        >
                            {dataLayerGroup?.[0]?.isSeries ? (
                                <XMapPoint filter={dataLayerGroup?.[0]?.columnName} />
                            ) : (
                                <XMapPoint filter={filteredData?.[0]?.columnName} />
                            )}
                        </div>

                        <div
                            style={{
                                flex: 1,
                                backgroundImage: `url(${bg2})`,
                                backgroundPosition: "bottom right", // Menempatkan gambar di kanan bawah
                                backgroundSize: "60%",  // Mengubah ukuran gambar menjadi 60%
                                maxHeight: '450px',
                                height: '450px',
                                backgroundRepeat: 'no-repeat', // Menghindari pengulangan gambar
                            }}>
                            <h3 style={{ fontSize: "18px", color: "#333" }}>
                                Basemap Overview:
                            </h3>


                            {isLoading ? (
                                <div style={loadingStyle}>
                                    AI Generate Summary...</div>
                            ) : (
                                responseData?.[0]?.overview.map((stat, index) => {
                                    // Tentukan ikon berdasarkan stat.title
                                    let iconRes = '';
                                    if (stat?.title === 'urban hub') {
                                        iconRes = iconHub;
                                    } else if (stat?.title === 'demographics') {
                                        iconRes = iconDemo;
                                    } else if (stat?.title === 'accessibility') {
                                        iconRes = iconAccess;
                                    } else if (stat?.title === 'key landmark') {
                                        iconRes = iconKey;
                                    } else {
                                        iconRes = iconKey;
                                    }

                                    return (
                                        <div key={index} style={infoBoxStyle}>
                                            <div style={iconStyle}>
                                                <img
                                                    src={iconRes}
                                                    alt="Logo"
                                                />
                                            </div>
                                            <div style={textContentStyle}>
                                                <p style={descriptionStyle}>
                                                    <strong>{stat?.title}</strong>: {stat?.overview}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                </section>

            </div>

            <div className="page" style={page1ContainerStyle}>
                {/* <div> */}
                <section style={{ marginTop: "10px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h2 style={{ fontSize: "20px", color: "#444" }}>
                            Summary All
                        </h2>
                        <span style={{ fontSize: "24px", color: "#444", cursor: "pointer" }}>
                            <img
                                src={iconHeader}
                                alt="Logo"
                            />
                        </span>

                    </div>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr", // Kolom kiri lebih kecil
                            gap: "20px",

                        }}
                    >
                        <div
                            style={{
                                height: "250px",
                                border: "1px solid #e0e0e0",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                borderRadius: "8px",
                                padding: "10px",
                                backgroundColor: "#fff",
                            }}
                        >
                            <div style={{
                                display: "flex",          // Menggunakan Flexbox
                                alignItems: "center",     // Vertikal rata tengah
                                gap: "5px",              // Memberikan jarak antara ikon dan judul
                            }}>
                                <img
                                    src={iconArea}
                                    alt="Logo"
                                    style={{
                                        height: "24px",   // Mengatur ukuran ikon
                                    }}
                                />
                                <h4 style={{
                                    color: '#4A7FC0',
                                    fontSize: '16px'
                                }}>
                                    Total Number
                                </h4>
                            </div>

                            {dataLayerGroup?.[0]?.isSeries ? (
                                <div style={{
                                    height: '230px',
                                    marginTop: '-30px'
                                }}>
                                    <Bar data={chartDataTrafic} options={optionsTrafic} />
                                </div>
                            ) : (
                                <div style={{
                                    height: '230px',
                                    marginTop: '-30px'
                                }}>
                                    <Bar data={dataTotal} options={optionsTotal} />
                                </div>
                            )
                            }

                        </div>
                        <div style={gridStyle}>
                            {statsData.map((stat, index) => (
                            
                                <div key={index} style={cardStyle}>
                                    {/* Kontainer untuk ikon dan label */}
                                    <div style={iconAndLabelStyle}>
                                        <div style={iconStyleStat}>
                                            <img
                                                src={stat.icon}
                                                alt="Logo"
                                            />
                                        </div>
                                        <span style={labelStyle}>{stat.label}</span>
                                    </div>
                                    {/* Nilai di bawah */}
                                    <span style={valueStyle}>
                                        {stat.value}
                                    </span>
                                </div>
                            ))}
                        </div>

                    </div>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr",
                            gap: "20px",
                        }}
                    >
                        {/* Distribution Chart */}
                        <div
                            style={{
                                height: "244px",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                border: "1px solid #e0e0e0",
                                borderRadius: "8px",
                                padding: "15px",
                                marginTop: '5px',
                                backgroundColor: "#fff",
                            }}
                        >
                            <div style={{
                                display: "flex",          // Menggunakan Flexbox
                                alignItems: "center",     // Vertikal rata tengah
                                gap: "5px",              // Memberikan jarak antara ikon dan judul
                            }}>
                                <img
                                    src={iconDisCart}
                                    alt="Logo"
                                    style={{
                                        height: "24px",   // Mengatur ukuran ikon
                                    }}
                                />
                                <h4 style={{
                                    color: '#4A7FC0',
                                    fontSize: '16px'
                                }}>
                                    Distribution
                                </h4>
                            </div>

                            <Bar data={chartData} options={options} />

                        </div>

                        {/* Top 5 Squares */}
                        <div
                            style={{
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                border: "1px solid #e0e0e0",
                                borderRadius: "8px",
                                padding: "15px",
                                marginTop: '5px',
                                backgroundColor: "#fff",
                            }}
                        >
                            <div style={{
                                display: "flex",          // Menggunakan Flexbox
                                alignItems: "center",     // Vertikal rata tengah
                                gap: "5px",              // Memberikan jarak antara ikon dan judul
                            }}>
                                <img
                                    src={iconTop5}
                                    alt="Logo"
                                    style={{
                                        height: "24px",   // Mengatur ukuran ikon
                                    }}
                                />
                                <h4 style={{
                                    color: '#4A7FC0',
                                    fontSize: '16px'
                                }}>
                                    Top 5 Squares with Highest Number
                                </h4>
                            </div>

                            <ul
                                style={{
                                    marginTop: "10px",
                                    fontSize: "14px",
                                    color: "#555",
                                }}
                            >
                                {convertTogeojson?.map((square: any, index: any) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: '5px',
                                        }}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <div
                                                style={{
                                                    width: '25px',
                                                    height: '25px',
                                                    borderRadius: '20%',
                                                    backgroundColor: square.properties.color,
                                                }}
                                            >

                                            </div>
                                                {square?.properties?.formated}
                                            {/* {filteredData?.[0]?.columnName?.includes('expenditure')
                                                ? `Rp. ${formatNumberWithDots(square?.properties?.value)}`
                                                : filteredData?.[0]?.columnName === 'reachability_inbound_sqm' ||
                                                    filteredData?.[0]?.columnName === 'reachability_outbound_sqm'
                                                    ? formatNumberReachKm2(square?.properties?.value)
                                                    : filteredData?.[0]?.columnName?.includes('insight')
                                                        ? Number(square?.properties?.value).toFixed(2)
                                                        : formatNumberWithDots(square?.properties?.value)} */}
                                        </div>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>
                {/* </div> */}
            </div>

            <div className="page" style={page1ContainerStyle}>
                {/* Page 1: Overview Section */}
                <section >
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ fontSize: "24px", color: "#444", cursor: "pointer" }}>
                            <img
                                src={iconHeader}
                                alt="Logo"
                            />
                        </span>
                    </div>
                    {isLoading ? (
                        <div style={loadingStyle}>loading...</div>
                    ) : (
                        <div style={page1InfoContentStyle}>
                            <h2 style={{ fontSize: "20px", color: "#444", marginBottom: "10px" }}>
                                {responseData?.[0]?.detail?.[0]?.title}
                            </h2>
                        </div>
                    )}
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            gap: "10px",

                        }}
                    >

                        {isLoading ? (
                            <div style={loadingStyle}>AI Generate Summary...</div>
                        ) : (

                         dataLayerGroup[0]?.isSeries ? 
                         overviewData?.map((filteredDetail, index) => (
                            <div
                            key={index}
                            style={{
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                border: "1px solid #e0e0e0",
                                borderRadius: "8px",
                                padding: "5px",
                                backgroundColor: "#ffff",
                            }}
                        >
                            <h3 style={{ fontSize: "14px", color: "black" }}>
                                {filteredDetail?.data?.[0]?.title}
                            </h3>
                            <p style={{ fontSize: "12px", margin: "10px 0" }}>
                                <strong>(Range {filteredDetail?.data?.[0]?.range})</strong>
                            </p>
                            <ul
                                style={{
                                    fontSize: "12px",
                                    color: "#555",
                                    paddingLeft: "20px",
                                }}
                            >
                                <li>
                                {filteredDetail?.data?.[0]?.summary}
                                </li>

                            </ul>
                        </div>
                         ))
                         : 
                         overviewData
                            ?.filter((detail) => detail.title?.includes("All")) // Filter elemen dengan title yang mengandung kata "All" (case-insensitive)
                            .map((filteredDetail, index) => (
                                filteredDetail.data?.map((dataItem:any, dataIndex:any) => ( // Mapping array di dalam `data`
                                    <div
                                        key={dataIndex}
                                        style={{
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                            border: "1px solid #e0e0e0",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            backgroundColor: "#ffff",
                                        }}
                                    >
                                        <h3 style={{ fontSize: "14px", color: "black" }}>
                                            {dataItem?.title}
                                        </h3>
                                        <p style={{ fontSize: "12px", margin: "10px 0" }}>
                                            <strong>(Range {dataItem?.range})</strong>
                                        </p>
                                        <ul
                                            style={{
                                                fontSize: "12px",
                                                color: "#555",
                                                paddingLeft: "20px",
                                            }}
                                        >
                                            <li>
                                                {dataItem?.summary}
                                            </li>

                                        </ul>
                                    </div>
                                ))
                            ))
                           
                        )}


                    </div>

                    {/* Recommendations Section */}
                    <h2 style={{ fontSize: "20px", color: "#444", marginBottom: "10px", marginTop: "10px" }}>
                        📝 Recommendations
                    </h2>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            gap: "10px",
                        }}
                    >

                        {isLoading ? (
                            <div style={loadingStyle}>AI Generate Summary...</div>
                        ) : (

                            responseData?.[0]?.recomendations
                                .map((filteredDetail, index) => (

                                    // filteredDetail.recomendations?.map((dataItem, dataIndex) => ( // Mapping array di dalam `data`
                                    <div
                                        key={index}
                                        style={{
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                            border: "1px solid #e0e0e0",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            textAlign: "left",
                                        }}
                                    >
                                        <p style={{ fontSize: "12px", margin: "10px 0" }}>
                                            <strong>  {filteredDetail?.title}</strong>
                                        </p>

                                        <ul
                                            style={{
                                                fontSize: "12px",
                                                color: "#555",
                                                paddingLeft: "20px",
                                            }}
                                        >
                                            <li>
                                                {filteredDetail?.recomendation}
                                            </li>

                                        </ul>
                                    </div>
                                    // ))
                                ))
                        )}


                    </div>
                </section>
            </div>


            {/* sectiom data yg selain summary all recomendataion dan overview */}

            {dataLayerGroup?.filter((item: any) => item.combinedName !== 'All')
                .map((item: any, index: any) => {
                    const convertTop5 = item?.summary?.top5?.map((item: { gid: string; value: number }) => {
                        try {
                            // Validasi data sebelum interpolasi
                            if (typeof item?.value !== "number" || !item?.gid) {
                                // console.error(`Invalid data for item:`, item);
                                return null; // Abaikan item yang tidak valid
                            }
                            const colorStops = getColorFromInterpolation(item?.value, binsRangeColor, colorGroup?.color || [])
                            return {
                                properties: {
                                    ...item,
                                    color: colorStops,
                                },
                            };
                        } catch (error) {
                            console.error(`Failed to process item:`, item, error);
                            return null; // Abaikan item yang gagal diproses
                        }
                    })

                    const chartData = {
                        labels: item.histogram.map((h: any) => h.range),
                        datasets: [
                            {
                                label: item.productName,
                                data: item.histogram.map((h: { value: any; }) => h.value),
                                backgroundColor: colorGroup?.color,
                                borderRadius: 12, // Styling opsional
                            },
                        ],
                    };
                    const options = {
                        responsive: true,
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 10,
                                bottom: 10, // Atur padding agar label sumbu-X tidak terlalu jauh
                            },
                        },
                        plugins: {
                            legend: {
                                display: false, // Sembunyikan legenda
                            },

                        },
                        scales: {
                            x: {
                                grid: {
                                    drawBorder: false, // Menghapus garis sumbu X
                                    display: false // Menghapus gridlines di sumbu X
                                },
                                ticks: {
                                    color: '#030202', // Warna label x-axis
                                    // padding: 2, // Atur jarak label dari sumbu-X
                                    font: {
                                        size: 7, // Ubah ukuran font label y-axis

                                    },
                                },
                            },
                            y: {

                                stacked: true, // Bar stack di Y-axis
                                ticks: {
                                    font: {
                                        size: 8, // Ubah ukuran font label y-axis

                                    },
                                    color: "#030202", // Warna teks legend
                                    beginAtZero: true, // Mulai dari nol
                                },
                                grid: {
                                    drawBorder: false, // Menghapus garis sumbu X
                                    display: false // Menghapus gridlines di sumbu X
                                },
                            },
                        },
                    };

                    return (
                        <>
                            <div className="page" style={page1ContainerStyle}>
                                <section>
                                    <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                                        <h2 style={{ fontSize: "20px", color: "#444" }}>
                                            Summary - {item.subdataName}
                                        </h2>
                                        <span style={{ fontSize: "24px", color: "#444", cursor: "pointer" }}>
                                            <img
                                                src={iconHeader}
                                                alt="Logo"
                                            />
                                        </span>

                                    </div>
                                    <h3 style={{ fontSize: "18px", color: "#333", }}>
                                        MAPS
                                    </h3>
                                    <div

                                        style={{
                                            display: "flex",
                                            gap: "20px",
                                            marginBottom: "5px",
                                        }}
                                    >

                                        <div
                                            key={index}
                                            ref={(el) => (mapRefs.current[index] = el)} // Store ref for each map
                                            style={{
                                                flex: 1,
                                                minWidth: '400px',
                                                borderRadius: "8px",
                                                border: "1px solid #e0e0e0",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <XMapPoint filter={item.columnName} />

                                        </div>

                                        <div style={gridStyle}>
                                            {[
                                                { label: "Total Number", value: summaryAllTotal, icon: icontotal },
                                                { label: "Average per Square", value: item.avg_formated, icon: iconavg },
                                                { label: "Min", value: item.min_formated, icon: iconmin },
                                                { label: "Med", value: item.median_formated, icon: iconmed },
                                                { label: "Max", value: item.max_formated, icon: iconmax },
                                            ].map((stat, index) => (
                                       
                                                
                                                <div key={index} style={cardStyle}>
                                                    {/* Kontainer untuk ikon dan label */}
                                                    <div style={iconAndLabelStyle}>
                                                        <div style={iconStyleStat}>
                                                            <img
                                                                src={stat.icon}
                                                                alt="Logo"
                                                            />
                                                        </div>
                                                        <span style={labelStyle}>{stat.label}</span>
                                                    </div>
                                                    {/* Nilai di bawah */}
                                                    <span style={valueStyle}>
                                                        {stat.value}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </section>

                            </div>
                            <div className="page" style={page1ContainerStyle}>
                                <section>
                                    <div
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns: "2fr 1fr",
                                            gap: "20px",
                                        }}
                                    >
                                        {/* Distribution Chart */}
                                        <div
                                            style={{
                                                height: "244px",
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                border: "1px solid #e0e0e0",
                                                borderRadius: "8px",
                                                padding: "15px",
                                                backgroundColor: "#fff",
                                            }}
                                        >
                                            <div style={{
                                                display: "flex",          // Menggunakan Flexbox
                                                alignItems: "center",     // Vertikal rata tengah
                                                gap: "5px",              // Memberikan jarak antara ikon dan judul
                                            }}>
                                                <img
                                                    src={iconDisCart}
                                                    alt="Logo"
                                                    style={{
                                                        height: "24px",   // Mengatur ukuran ikon
                                                    }}
                                                />
                                                <h4 style={{
                                                    color: '#4A7FC0',
                                                    fontSize: '16px'
                                                }}>
                                                    Distribution
                                                </h4>
                                            </div>
                                            <Bar data={chartData} options={options} />
                                        </div>

                                        {/* Top 5 Squares */}
                                        <div
                                            style={{
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                border: "1px solid #e0e0e0",
                                                borderRadius: "8px",
                                                padding: "15px",
                                                backgroundColor: "#fff",
                                            }}
                                        >
                                            <div style={{
                                                display: "flex",          // Menggunakan Flexbox
                                                alignItems: "center",     // Vertikal rata tengah
                                                gap: "5px",              // Memberikan jarak antara ikon dan judul
                                            }}>
                                                <img
                                                    src={iconTop5}
                                                    alt="Logo"
                                                    style={{
                                                        height: "24px",   // Mengatur ukuran ikon
                                                    }}
                                                />
                                                <h4 style={{
                                                    color: '#4A7FC0',
                                                    fontSize: '16px'
                                                }}>
                                                    Top 5 Squares with Highest Number
                                                </h4>
                                            </div>

                                            <ul
                                                style={{
                                                    marginTop: "10px",
                                                    fontSize: "14px",
                                                    color: "#555",
                                                }}
                                            >
                                                {convertTop5?.map((square: any, index: any) => (
                                        
                                                    
                                                    <div
                                                        key={index}
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            marginBottom: '5px',
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                            <div
                                                                style={{
                                                                    width: '25px',
                                                                    height: '25px',
                                                                    borderRadius: '20%',
                                                                    backgroundColor: square.properties.color,
                                                                }}
                                                            ></div>
                                                           {square?.properties?.formated}
                                                        </div>
                                                    </div>
                                                ))}
                                            </ul>


                                        </div>
                                    </div>
                                    <div style={{ marginTop: '20px' }}>
                                        <h2 style={{ fontSize: "20px", color: "#444", marginBottom: "10px" }}>
                                            {item.subdataName}
                                        </h2>

                                        <div
                                            style={{
                                                display: "grid",
                                                gridTemplateColumns: "1fr 1fr 1fr",
                                                gap: "20px",
                                                marginBottom: "30px",
                                            }}
                                        >
                                            {isLoading ? (
                                                <div style={loadingStyle}>AI Generate Summary...</div>
                                            ) : (
                                                overviewData
                                                    ?.filter((detail: { title: string | string[] }) =>
                                                        detail.title?.toString()?.trim() === item.combinedName?.trim()
                                                    )
                                                    .map((filteredDetail: any, index: any) => (

                                                        filteredDetail.data?.map((dataItem: any, dataIndex: any) => (
                                                            <div
                                                                key={dataIndex}
                                                                style={{
                                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                                    border: "1px solid #e0e0e0",
                                                                    borderRadius: "8px",
                                                                    padding: "15px",
                                                                    backgroundColor: "#ffff",
                                                                }}
                                                            >
                                                                <h3 style={{ fontSize: "14px", color: "black" }}>
                                                                    {dataItem?.title}
                                                                </h3>
                                                                <p style={{ fontSize: "12px", margin: "10px 0" }}>
                                                                    <strong>(Range {dataItem?.range})</strong>
                                                                </p>
                                                                <ul
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        color: "#555",
                                                                        paddingLeft: "20px",
                                                                    }}
                                                                >
                                                                    <li>
                                                                        {dataItem?.summary}
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                        ))
                                                    ))
                                            )}
                                        </div>
                                    </div>
                                </section>
                            </div>

                        </>
                    );
                })}

            <div
                className="page"
                style={{
                    width: "842px", // Lebar A4 dalam piksel
                    height: "595px", // Tinggi A4 dalam piksel
                    marginBottom: "5px",
                    marginTop: "5px",
                    margin: "0 auto",
                    fontFamily: "'Arial', sans-serif",
                    color: "#333",
                    lineHeight: "1.5",
                    backgroundColor: "#fff",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "relative",

                }}
            >
                {/* Logo and title centered */}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: '500px',
                        flex: 1,

                    }}
                >
                    <img
                        src={iconHeader} // Ganti dengan URL logo Anda
                        alt="GeoSquare Logo"
                        style={{
                            height: "50px",   // Mengatur ukuran ikon
                            marginBottom: "10px"
                        }}
                    />

                    <p
                        style={{
                            fontSize: "16px",
                            color: '#7D8587',
                            margin: 0,
                        }}
                    >
                        www.geosquare.ai
                    </p>
                    <div
                        style={{
                            display: "flex",
                            paddingTop: '20px',
                            justifyContent: "center",
                            alignItems: "center",
                            color: '#7D8587',
                            gap: "10px",

                        }}
                    >
                        <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <FaInstagram size={20} /> geosquare.ai
                        </span>
                        <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <FaLinkedin size={20} />  geosquare.ai
                        </span>
                        <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <FaTwitter size={20} /> geosquare.ai
                        </span>
                    </div>
                </div>
                <img
                    src={bg2} // Ganti dengan URL logo Anda
                    alt="GeoSquare Logo"
                    style={{
                        position: 'absolute',
                        bottom: '0px',
                        right: '0px',
                        marginBottom: "10px"
                    }}
                />
                {/* Footer with social media icons */}

            </div>
        </>
    );
};

export default GenerateSummaryPage;

const infoBoxStyle = {
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: "#fff",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    marginBottom: '10px',
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
};

const iconStyle = {
    marginRight: "15px",
    fontSize: "24px",
    color: "#4a7fc0",
};

const textContentStyle = {
    flex: 1,
};


const descriptionStyle = {
    fontSize: "12px",
    color: "#555",
    marginTop: "5px",
};


const gridStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "10px",
    padding: "10px",
};


const cardStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    border: "1px solid #e0e0e0",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
};

const iconAndLabelStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    gap: "10px", // Jarak antara ikon dan label
    // marginBottom: "10px", // Memberikan jarak antara label-ikon dan nilai
};

const iconStyleStat = {
    fontSize: "30px",
    color: "#4a7fc0",
};

const labelStyle: React.CSSProperties = {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#4a7fc0",
};

const valueStyle: React.CSSProperties = {
    fontSize: "21px",
    fontWeight: "bold",
    color: "#4A7FC0",
};

const page1ContainerStyle: React.CSSProperties = {
    width: "842px", // Lebar A4 dalam piksel
    height: "595px", // Tinggi A4 dalam piksel
    marginBottom: '5px',
    marginTop: '5px',
    margin: "0 auto",
    fontFamily: "'Arial', sans-serif",
    color: "#333",
    lineHeight: "1.5",
    backgroundColor: "#fff",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
};

const page1LeftSectionStyle: React.CSSProperties = {
    width: "50%",
};


const page1LogoStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "40px",
};

const page1ReportTitleStyle: React.CSSProperties = {
    fontSize: "61px",
    lineHeight: 'normal',
    fontWeight: "bolder",
    color: "#4A7FC0",
};
const loadingStyle: React.CSSProperties = {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#4a7fc0",
};

const page1SubtitleStyle: React.CSSProperties = {
    fontSize: "18px",
    fontWeight: "bold",
    backgroundColor: "#4a7fc0",
    color: "#fff",
    textAlign:'center',
    padding: "5px 15px",
    borderRadius: "5px",
    display: "inline-block",
    marginTop: "10px",
    marginLeft: '270px'
};

const page1InfoContainerStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: "20px",
};

const page1InfoBoxStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "15px",
    backgroundColor: "#f9f9f9",
};

const page1InfoTitleStyle: React.CSSProperties = {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#4a7fc0",
    display: "flex",
    alignItems: "center",
    gap: "8px",
};

const page1InfoContentStyle: React.CSSProperties = {
    fontSize: "16px",
    marginTop: "8px",
    color: "#4a7fc0",
};


// new
