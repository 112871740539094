import { ThunkAction } from 'redux-thunk'
import catalogApi from '../../service/catalog'
import { Action } from 'redux'
import { RootState } from '../store/config.strore';

import {
    setCatalog,
    setCatalogGeometry,
    setCataloglocation,
    setCatalogStart,
    setDrawActive,
    setCloseModalCheckout,
    setOpenModalCheckout,
    setAddToChart,
    setSelectedItem,
    setListCart,
    setCloseModalCart,
    setOpenModalCart,
    setCart,
    setMyData,
    setDetailMyData,
    setMetaData,
    setDataLayerGroup,
    setColorGroup,
    setInvoiceId,
    setOpenModalConfirmPayment,
    setCloseModalConfirmPayment,
    setSelectedCategory,
    setShowCardCatalog,
    setShowCardMyData,
    setShowDrawerBottom,
    setPopUpFilter,
    setModalWalletPayment,
    setIsDrawActive,
    setDrawnPolygon,
    setIsochrones,
    setInvoiceForData,
    setDownloadData,
    setModalWalletCartPayment,
    setShowCardDownload,
    setShowRightDrawer,
    setShowModalShowCustomSCroring,
    setShowSideMenuCustomScoring,
    setDataCustomScoring,
    setDataListProduct,
    setShowCardChangeColor,
    setMetaDataProfiling,
    setPriceScoring,
    setShowModalCheckoutScoring,
    setDoScoring,
    setSelectedDataProfilling,
    setShowModalSelectTypeProfileToScore,
    setShowSideMenuCustomProfillingToScoring,
    setDataCustomProfToScoring,
    setPriceScoringStart,
    setPriceScoringError,
    setGeojsonFitBounds
} from '../store/catalog.store';


interface DataStructure  {
    [category: string]: {
        subcategories: {
            [subcategory: string]: string[];
        };
    };
};

export const getCatalogAction = (params: string, RequestBody: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCatalogStart());
        const data: any = await catalogApi.getCatalogApi(params, RequestBody);
        // console.log(data);
        
        if (data) {
            dispatch(setCatalog(data));
        }

    } catch (error) {
        dispatch(setCatalog(error));
        // console.error('Error fetching pixel data:', error);
    }
};

export const getCatalogGeometryAction = (RequestBody: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCatalogGeometry(RequestBody));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};

export const getCatalogLocationAction = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCataloglocation(data));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};

export const drawerActionOpen = (status: boolean): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setDrawActive(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }

};

export const openModalCheckout = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setOpenModalCheckout(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const closeModalCheckout = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCloseModalCheckout(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const addChart = (token: string, data: any): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        const addchart = await catalogApi.addToChart(token, data);
        dispatch(setAddToChart(addchart));
        return addchart; // Ensure the payment data is returned
    } catch (error) {
        console.error('Error fetching topup data:', error);
        throw error; // Ensure the error is thrown
    }
};
export const setSelectedItemAction = (data: any): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setSelectedItem(data));
        return data; // Ensure the payment data is returned
    } catch (error) {
        console.error('Error set data:', error);
        throw error; // Ensure the error is thrown
    }
};
export const setListCartAction = (): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        const listData = await catalogApi.getCart();
        dispatch(setListCart(listData));
        return listData; // Ensure the payment data is returned
    } catch (error) {
        console.error('Error fetching list Data :', error);
        throw error; // Ensure the error is thrown
    }
};
export const openModalCartAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setOpenModalCart(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const closeModalCartAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCloseModalCart(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const setDataCartAction = (data: any): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCart(data));
        return data; // Ensure the payment data is returned
    } catch (error) {
        console.error('Error set data:', error);
        throw error; // Ensure the error is thrown
    }
};

export const setMyDataAction = (params: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        const data: any = await catalogApi.getMyDatas(params);
        if (data) {
            dispatch(setMyData(data));
        }

    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const setDetailMyDataAction = (params: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        const data: any = await catalogApi.getDetailMyDatas(params);
        if (data) {
            dispatch(setDetailMyData(data));
        }
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const setMetaDataAction = (data: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {

        dispatch(setMetaData(data));

    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const setDataLayerGroupAction = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setDataLayerGroup(data));
    } catch (error) {
        console.error('Error fetching group layer:', error);
    }
};
export const setColorGroupAction = (colorGroup: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setColorGroup(colorGroup));
    } catch (error) {
        console.error('Error fetching group layer:', error);
    }
};
export const setInvoiceIdAction = (params: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        const data: any = await catalogApi.getDetailInvoice(params);
        dispatch(setInvoiceId(data));
    } catch (error) {
        console.error('Error fetching group layer:', error);
    }
};

export const openModalConfirmPaymentAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setOpenModalConfirmPayment(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const closeModalConfirmPaymentAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCloseModalConfirmPayment(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};

export const setSelectedCategoryAction = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setSelectedCategory(data));
    } catch (error) {
        console.error('Error fetching group layer:', error);
    }
};

export const setShowCardCatalogAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setShowCardCatalog(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const setShowCardMyDataAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setShowCardMyData(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};

export const setShowDrawerBottomAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setShowDrawerBottom(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};

export const setPopUpFilterAction = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setPopUpFilter(data));
    } catch (error) {
        console.error('Error fetching group layer:', error);
    }
};

export const setModalWalletPaymentAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setModalWalletPayment(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const setIsDrawActiveAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setIsDrawActive(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const setDrawnPolygonAction = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setDrawnPolygon(data));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};

export const setIsochronesAction = (
    body: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCatalogStart());

        if (!body) {
            // Jika body null, langsung dispatch null
            dispatch(setIsochrones(null));
            return;
        }

        // Jika body tidak null, panggil API dan dispatch hasilnya
        const data: any = await catalogApi.getIsochrones(body);
        dispatch(setIsochrones(data));
        return data
    } catch (error) {
        console.error('Error fetching isochrones data:', error);
    }
};

export const setInvoiceForDataAction = (params: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCatalogStart());
        const data: any = await catalogApi.getDetailInvoiceforLinkData(params);
        if (data) {
            dispatch(setInvoiceForData(data));
        }

    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const setDownloadDataAction = (params: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCatalogStart());
        const data: any = await catalogApi.downloadData(params);
        if (data) {
            return dispatch(setDownloadData(data));
        }

    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};

export const setModalWalletCartPaymentAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setModalWalletCartPayment(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const setShowCardDownloadAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setShowCardDownload(status));
    } catch (error) {
        console.error('Error fetching  data:', error);
    }
};
export const setShowRightDrawerAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setShowRightDrawer(status));
    } catch (error) {
        console.error('Error fetching  data:', error);
    }
};

export const setGenerateDescriptionAction = (body: any):  ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCatalogStart());
        const data: any = await catalogApi.generateDescription(body);
        if (data) {
            return data;
        }

    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};

export const setShowModalShowCustomSCroringAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setShowModalShowCustomSCroring(status));
    } catch (error) {
        console.error('Error fetching  data:', error);
    }
};
export const setShowSideMenuCustomScoringAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setShowSideMenuCustomScoring(status));
    } catch (error) {
        console.error('Error fetching  data:', error);
    }
};
export const setsetDataCustomScoringAction = (data: DataStructure | null): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setDataCustomScoring(data));
    } catch (error) {
        console.error('Error fetching  data:', error);
    }
};

export const getListDataProductAction = (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        // dispatch(setCatalogStart());
        const data: any = await catalogApi.getListDataProductScoring();
        
        if (data) {
            dispatch(setDataListProduct(data?.data));
        }

    } catch (error) {
        dispatch(setCatalog(error));
        // console.error('Error fetching pixel data:', error);
    }
};

export const setShowCardChangeColorAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setShowCardChangeColor(status));
    } catch (error) {
        console.error('Error fetching  data:', error);
    }
};

export const setMetaDataProfilingAction = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setMetaDataProfiling(data));

    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};

export const setPriceScoringAction = (body: any): ThunkAction<void, RootState, unknown, Action<string>> => 
    async (dispatch) => {
        try {
            dispatch(setPriceScoringStart()); // Mulai loading
            
            const dataPrice: any = await catalogApi.generatePriceScoring(body);
            
            if (dataPrice) {
                dispatch(setPriceScoring(dataPrice));
            }
        } catch (error) {
            dispatch(setPriceScoringError()); // Hentikan loading saat error
        }
    };


export const setShowModalCheckoutScoringAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setShowModalCheckoutScoring(status));
    } catch (error) {
        console.error('Error fetching  data:', error);
    }
};

export const setDoScoringAction = ( data: any): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        const scoring = await catalogApi.checkoutDoScoring(data);
        dispatch(setDoScoring(scoring));
        return scoring; // Ensure the payment data is returned
    } catch (error) {
        console.error('Error fetching topup data:', error);
        throw error; // Ensure the error is thrown
    }
};

export const setSelectedDataProfillingAction = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        const listProfilling: any = await catalogApi.getListDataProductProllingByPath(data);
        dispatch(setSelectedDataProfilling(listProfilling));

    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};

export const setShowModalSelectTypeProfileToScoreAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setShowModalSelectTypeProfileToScore(status));
    } catch (error) {
        console.error('Error fetching  data:', error);
    }
};
export const setShowSideMenuCustomProfillingToScoringAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setShowSideMenuCustomProfillingToScoring(status));
    } catch (error) {
        console.error('Error fetching  data:', error);
    }
};
export const setDataCustomProfToScoringAction = (data: DataStructure | null): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setDataCustomProfToScoring(data));
    } catch (error) {
        console.error('Error fetching  data:', error);
    }
};
export const setGeojsonFitBoundsAction = (data: any | null): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setGeojsonFitBounds(data));
    } catch (error) {
        console.error('Error fetching  data:', error);
    }
};