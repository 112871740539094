// drawerSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GeoJSONFeature } from '../../component/newmap/helper/map/geocode';

interface DataStructure  {
    [category: string]: {
        subcategories: {
            [subcategory: string]: string[];
        };
    };
};


interface locationState {
    address: string;
    centroid: number[][];
    area: number
}

interface DataIds {
    product: string;
    subdata?: string[]; // Dynamic subdata, optional
}
interface AddChart {
    polygon_id: string;
    dataIds: DataIds[];
}
// ----- interface data card selected ----- //
interface ItemCardProps {
    id: string;
    polygonId: string;
    readiness: string;
    subData: any;
    version: any;
    title: string;
    productId: string;
    carPrice: string;
    description: string;
    price: string;
    size: any,
    totalpixel: any,
    status: string,
    output: any,
    year: number
    areaSize: any
    address: any,
    centeroid: {
        lattitude: number,
        longitude: number
    }
}

interface dataLayerGroup {
    columnName: string;
    productName: string;
    subdataName: string;
    additinal: any;
    combinedName: string;
    categoryName: string;
    seriesName: string;
    isSeries: boolean;
    bins: number[];
    min: number;
    max: number;
    summaryall:any;
    summary:any;
    summaryvariable: any;
    name: string;
    weight:[
        {
            categoryName: string,
            columnName: string,
            productName: string,
            subdataName: string,
            weight: number,
            isInverse: boolean
        }
    ];
}
// ---- end interface data card selected ---- //
interface CatalogState {
    catalog: any | null;
    isloading: boolean; // Add loading property
    error: boolean;
    paramsCatalogGeometry: any;
    location: locationState | null,
    drawActive: boolean,
    isModalCheckout: boolean,
    isModalCart: boolean,
    addToChart: AddChart[] | null; // Add dataIds to state
    selectedItem: ItemCardProps | null
    listCart: any
    setCartCheckout: any
    myData: any | null;
    detailMydata: any | null;
    metaData: any | null;
    dataLayerGroup: dataLayerGroup[];
    colorGroup: any | null;
    invoice_id: any | null;
    isModalConfirmPayment: boolean;
    selectedCategory: any | null;
    showCardCatalog: boolean;
    showCardMyData: boolean;
    showDrawerBottom: boolean;
    modalWalletPayment: boolean;
    popUpFilter: any[];
    // polygon drawer
    isDrawActive: boolean;
    drawnPolygon: GeoJSONFeature | null;
    isochrones: any | null;
    invoiceForData: any | null;
    downloadData: any | null;
    modalWalletCartPayment: boolean;
    showCardDownload: boolean;
    showRightDrawer: boolean;
    showModalCustomScoring: boolean;
    showSideMenuCustomScoring: boolean;
    dataCustomScoring: DataStructure | null;
    dataListProduct: DataStructure | null;
    showCardChageColor: boolean;
    metaDataProfiling: any | null;
    priceScoring: any | null;
    loadingPriceScoring: boolean, // Tambahkan loading state
    showModalCheckoutScoring: boolean;
    doScoring: any | null;
    selectedDataProfiling: any | null;
    showModalSelectTypeProfileToScore: boolean;
    showSideMenuCustomProfillingToScoring: boolean
    dataCustomProfileToScoring: DataStructure | null;
    geojsonFitBounds: any
}

const initialState: CatalogState = {
    catalog: null,
    paramsCatalogGeometry: null,
    isloading: true, // Initialize loading state
    error: false,
    location: null,
    drawActive: false,
    isModalCheckout: false,
    addToChart: null,
    selectedItem: null,
    listCart: null,
    isModalCart: false,
    setCartCheckout: null,
    myData: null,
    detailMydata: null,
    metaData: null,
    dataLayerGroup: [],
    colorGroup: null,
    invoice_id: null,
    isModalConfirmPayment: false,
    selectedCategory: null,
    showCardCatalog: false,
    showCardMyData: false,
    showDrawerBottom: false,
    modalWalletPayment: false,
    popUpFilter: [],
    // polygon drawer
    isDrawActive: false,
    drawnPolygon: null,
    isochrones: null,
    invoiceForData: null,
    downloadData: null,
    modalWalletCartPayment: false,
    showCardDownload: false,
    showRightDrawer: true,
    showModalCustomScoring: false,
    showSideMenuCustomScoring: false,
    dataCustomScoring: null,
    dataListProduct: null,
    showCardChageColor: false,
    metaDataProfiling: null,
    priceScoring: 0,
    loadingPriceScoring: false,
    showModalCheckoutScoring: false,
    doScoring: null,
    selectedDataProfiling: null,
    showModalSelectTypeProfileToScore: false,
    showSideMenuCustomProfillingToScoring: false,
    dataCustomProfileToScoring: null,
    geojsonFitBounds: null

};

const catalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {

        setCatalogStart: (state) => {
            state.isloading = true; // Set loading to true when fetching starts
            state.error = false;
        },
        setCatalog: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.catalog = action.payload;
        },
        setCatalogGeometry: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.paramsCatalogGeometry = action.payload;
        },
        setCataloglocation: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.location = action.payload;
        },

        setDrawActive: (state, action) => {
            state.drawActive = action.payload;
        },
        setOpenModalCheckout(state) {
            state.isModalCheckout = true;
        },
        setCloseModalCheckout(state) {
            state.isModalCheckout = false;
        },
        setAddToChart: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.addToChart = action.payload;
        },
        setSelectedItem: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.selectedItem = action.payload;
        },
        setListCart: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.listCart = action.payload;
        },
        setOpenModalCart(state) {
            state.isModalCart = true;
        },
        setCloseModalCart(state) {
            state.isModalCart = false;
        },
        setCart: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.setCartCheckout = action.payload;
        },
        setMyData: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.myData = action.payload;
        },
        setDetailMyData: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.detailMydata = action.payload;
        },
        setMetaData: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.metaData = action.payload;
        },
        setDataLayerGroup: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.dataLayerGroup = action.payload;
        },
        setColorGroup: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.colorGroup = action.payload;
        },
        setInvoiceId: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.invoice_id = action.payload;
        },
        setOpenModalConfirmPayment(state) {
            state.isModalConfirmPayment = true;
        },
        setCloseModalConfirmPayment(state) {
            state.isModalConfirmPayment = false;
        },
        setSelectedCategory: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.selectedCategory = action.payload;
        },
        setShowCardCatalog: (state, action: PayloadAction<any>) => {
            state.showCardCatalog = action.payload;
        },
        setShowCardMyData: (state, action: PayloadAction<any>) => {
            state.showCardMyData = action.payload;
        },
        setShowDrawerBottom: (state, action: PayloadAction<any>) => {
            state.showDrawerBottom = action.payload;
        },
        setPopUpFilter: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.popUpFilter = action.payload;
        },
        setModalWalletPayment: (state, action: PayloadAction<any>) => {
            state.modalWalletPayment = action.payload;
        },
        setIsDrawActive: (state, action: PayloadAction<any>) => {
            state.isDrawActive = action.payload;
        },
        setDrawnPolygon: (state, action: PayloadAction<any>) => {
            state.drawnPolygon = action.payload;
        },
        setIsochrones: (state, action: PayloadAction<any>) => {
            state.isochrones = action.payload;
        },
        setInvoiceForData: (state, action: PayloadAction<any>) => {
            state.invoiceForData = action.payload;
        },
        setDownloadData: (state, action: PayloadAction<any>) => {
            state.downloadData = action.payload;
        },
        setModalWalletCartPayment: (state, action: PayloadAction<any>) => {
            state.modalWalletCartPayment = action.payload;
        },
        setShowCardDownload: (state, action: PayloadAction<any>) => {
            state.showCardDownload = action.payload;
        },
        setShowRightDrawer: (state, action: PayloadAction<any>) => {
            state.showRightDrawer = action.payload;
        },
        setShowModalShowCustomSCroring: (state, action: PayloadAction<any>) => {
            state.showModalCustomScoring = action.payload;
        },
        setShowSideMenuCustomScoring: (state, action: PayloadAction<any>) => {
            state.showSideMenuCustomScoring = action.payload;
        },
        setDataCustomScoring : (state, action: PayloadAction<any>) => {
            state.dataCustomScoring = action.payload;
        },
        setDataListProduct : (state, action: PayloadAction<any>) => {
            state.dataListProduct = action.payload;
        },
        setShowCardChangeColor: (state, action: PayloadAction<any>) => {
            state.showCardChageColor = action.payload;
        },
        setMetaDataProfiling: (state, action: PayloadAction<any>) => {
            state.metaDataProfiling = action.payload;
        },
      
        setPriceScoringStart: (state) => {
            state.loadingPriceScoring = true;
        },
        setPriceScoring: (state, action: PayloadAction<any>) => {
            state.priceScoring = action.payload;
            state.loadingPriceScoring = false; // Set loading jadi false setelah data diterima
        },
        setPriceScoringError: (state) => {
            state.loadingPriceScoring = false; // Set loading jadi false saat error
        },
        setShowModalCheckoutScoring: (state, action: PayloadAction<any>) => {
            state.showModalCheckoutScoring = action.payload;
        },
        setDoScoring: (state, action: PayloadAction<any>) => {
            state.doScoring = action.payload;
        },
        setSelectedDataProfilling: (state, action: PayloadAction<any>) => {
            state.selectedDataProfiling = action.payload;
        },
        setShowModalSelectTypeProfileToScore: (state, action: PayloadAction<any>) => {
            state.showModalSelectTypeProfileToScore = action.payload;
        },
        setShowSideMenuCustomProfillingToScoring: (state, action: PayloadAction<any>) => {
            state.showSideMenuCustomProfillingToScoring = action.payload;
        },
        setDataCustomProfToScoring : (state, action: PayloadAction<any>) => {
            state.dataCustomProfileToScoring = action.payload;
        },
        setGeojsonFitBounds : (state, action: PayloadAction<any>) => {
            state.geojsonFitBounds = action.payload;
        },
    },
});

export const {
    setCatalog,
    setCatalogGeometry,
    setCataloglocation,
    setCatalogStart,
    setDrawActive,
    setOpenModalCheckout,
    setCloseModalCheckout,
    setAddToChart,
    setSelectedItem,
    setListCart,
    setOpenModalCart,
    setCloseModalCart,
    setCart,
    setMyData,
    setDetailMyData,
    setMetaData,
    setDataLayerGroup,
    setColorGroup,
    setInvoiceId,
    setOpenModalConfirmPayment,
    setCloseModalConfirmPayment,
    setSelectedCategory,
    setShowCardCatalog,
    setShowCardMyData,
    setShowDrawerBottom,
    setPopUpFilter,
    setModalWalletPayment,
    setIsDrawActive,
    setDrawnPolygon,
    setIsochrones,
    setInvoiceForData,
    setDownloadData,
    setModalWalletCartPayment,
    setShowCardDownload,
    setShowRightDrawer,
    setShowModalShowCustomSCroring,
    setShowSideMenuCustomScoring,
    setDataCustomScoring,
    setDataListProduct,
    setShowCardChangeColor,
    setMetaDataProfiling,
    setPriceScoringStart, setPriceScoring, setPriceScoringError,
    setShowModalCheckoutScoring,
    setDoScoring,
    setSelectedDataProfilling,
    setShowModalSelectTypeProfileToScore,
    setShowSideMenuCustomProfillingToScoring,
    setDataCustomProfToScoring,
    setGeojsonFitBounds
} = catalogSlice.actions;

export default catalogSlice.reducer;
