import { PropertyData } from '../../../../interface/parquete.interface';
import chroma from 'chroma-js'; // You can use a library like chroma-js for color gradients

export const getRandomColor = (): string => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const domainRange = Array.from({ length: 10 }, (_, i) => i);
  const domainRangePrice = Array.from({ length: 50000000 }, (_, i) => i);
  // Create a color scale with your chosen colors
  const colorScalePeople = chroma.scale(['#000004', '#50127b', '#b6377a', '#fb8761', '#fcfdbf']).domain(domainRange);
  const colorScalePrice = chroma.scale(['#80bfab', '#c7e8ad', '#ffffbf', '#fec980', '#f07c4a', '#d7191c']).domain(domainRangePrice);

  export const groupDataByProperty = (
    data: PropertyData[],
    propertyKey: keyof PropertyData,
    getColor: (groupKey: string) => string
  ): Record<string, PropertyData[]> => {
    const colorMap: Record<string, string> = {};
  // console.log(data);
  // console.log(propertyKey);
  
    return data.reduce((acc: Record<string, PropertyData[]>, item: PropertyData) => {
      let groupKey = item[propertyKey] as string;
      // console.log(groupKey);
      
      groupKey = groupKey ?? '0'; // Convert null or undefined values to '0'
  
      // Apply the chroma color scale for 'RANGENILAI'
      if (propertyKey === 'RANGENILAI') {
        // Clean the value by removing non-numeric characters except '-' and ','
        const cleanedValue = groupKey.replace(/[<>]/g, '').trim();
        const [minValue, maxValue] = cleanedValue
          .split(/ - /)
          .map(value => parseFloat(value.replace(/[^\d-]/g, '').replace(/,/g, '')));
  
        // Apply color scale based on parsed values
        if (!isNaN(minValue)) {
          item.color = colorScalePrice(minValue).hex();
        } else {
          item.color = colorScalePrice(0).hex(); // Default to 0 if parsing fails
        }
  
        // Optionally handle the maxValue for additional color logic
        if (!isNaN(maxValue)) {
          // Optionally apply another color or gradient step
        }
      } else if (propertyKey === 'people_density_avg') {
        const rangeValue = parseFloat(groupKey);
        if (!isNaN(rangeValue)) {
          item.color = colorScalePeople(rangeValue).hex(); // Get the color from the scale
        }
      } else {
        // For other properties, use the provided getColor function
        if (!colorMap[groupKey]) {
          colorMap[groupKey] = getColor(groupKey);
        }
        item.color = colorMap[groupKey];
      }
  
      // Group the data
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
  
      acc[groupKey].push(item);
  
      return acc;
    }, {});
  };
  


    export const interpolateColor = (color1: string, color2: string, t: number): string => {
    const c1 = chroma(color1).rgb();
    const c2 = chroma(color2).rgb();
    const interpolated = [
      Math.round(c1[0] + t * (c2[0] - c1[0])),
      Math.round(c1[1] + t * (c2[1] - c1[1])),
      Math.round(c1[2] + t * (c2[2] - c1[2])),
    ];
    return chroma(interpolated).hex();
  };

  export const getColorFromInterpolation = (
    value: number,
    range: number[],
    colorGroup: string[]
  ): string => {
    // Pastikan panjang colorGroup sesuai dengan panjang range
    const adjustedColorGroup = colorGroup.slice(0, range.length);

    if (!Array.isArray(adjustedColorGroup) || adjustedColorGroup.length !== range.length) {
      throw new Error("ColorGroup harus memiliki panjang yang sama dengan range.");
    }

    if (value <= range[0]) return adjustedColorGroup[0]; // Warna pertama jika nilai di bawah rentang
    if (value >= range[range.length - 1]) return adjustedColorGroup[adjustedColorGroup.length - 1]; // Warna terakhir jika nilai di atas rentang

    for (let i = 0; i < range.length - 1; i++) {
      if (value >= range[i] && value < range[i + 1]) {
        // Interpolasi linier
        const t = (value - range[i]) / (range[i + 1] - range[i]);
        return interpolateColor(adjustedColorGroup[i], adjustedColorGroup[i + 1], t);
      }
    }

    return "#000000"; // Fallback jika tidak sesuai
  };
