import React, { useEffect, useState, useRef } from "react";
import maplibregl from 'maplibre-gl';
import useReduxCatalog from "../../../../hoc/hooks/useCatalog";
import { addGeoJsonLayerParquetReport } from "../../../newmap/helper/map/geojson/layer-report";
// import { initializeMapboxDraw } from "../../../newmap/helper/map/draw.polygon";

interface MapPointProps {
  children?: React.ReactNode; // Untuk child elements (opsional)
  filter?: string; // Filter yang digunakan, default-nya bisa diatur di dalam komponen
}


export const MapReport: React.FC<MapPointProps> = ({ children, filter }) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const map = useRef<maplibregl.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [dataSeries] = useState('Total');
  const [,setIsLoading] = useState(false);
  // const [draw] = useState<MapboxDraw>(initializeMapboxDraw);
  // const [filter, setFilter] = useState<string>('expenditure_kretek_filter');
  const {
    metaData,
    colorGroup,
  } = useReduxCatalog();

  useEffect(() => {
    if (map.current || !mapContainerRef.current) return;
    map.current = new maplibregl.Map({
      container: mapContainerRef.current,
      style: 'https://api.maptiler.com/maps/streets/style.json?key=IkttUJmMfqWCx0g43vGM',
      center: [114.17487380816897, 0.3746499288735805],
      zoom: 4,
      maxZoom: 17,
      preserveDrawingBuffer: true
    });

    map.current.on('load', () => {
      setMapLoaded(true);
    });
  }, []);
  useEffect(() => {
    if (!mapLoaded || !map.current) return;
    addGeoJsonLayerParquetReport(
      map.current,
      metaData?.table_location,
      metaData?.metadata_location,
      dataSeries,
      colorGroup,
      setIsLoading,
      filter // Pass the filter
    );
    // eslint-disable-next-line
  }, [mapLoaded, metaData, dataSeries, colorGroup]);

  return (
    <div>
      <div ref={mapContainerRef} style={{ width: '100%', maxHeight: '400px', height:'250px' }}>
      {children}
      </div>
    </div>
  );
};
