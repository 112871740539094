import React, { useEffect, useState } from 'react';
import { Button, Select } from 'antd';
import Text from '../../../style/styledComponent/Text';
// import icChart from '../../../asesst/Icons/interactivemap/icon/cart.svg';
import icAreaSize from '../../../asesst/Icons/interactivemap/icon/scale-card.svg';
import icGridTotal from '../../../asesst/Icons/interactivemap/icon/grid-card.svg';
import { IoMdTime } from "react-icons/io";
import { formatDateOnlyMonth } from '../../../component/newmap/helper/date';
import { formatNumberWithDots } from '../../../component/newmap/helper/formatNumber';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import { ModalCart } from '../modal/modal.checkout.cart';
import { BankOutlined, WalletOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import useReduxUser from '../../../hoc/hooks/useUser';
import {
    auth,
} from '../../../firebase/firebaseConfig';
import { ModalWalletCartMethode } from '../modal/modal-wallet-cart-methode';

const { Option } = Select;
interface CatalogCardProps {
    polygon_id: string;
    area: number;
    product: string;
    additional: { size: number | null };
    centroid: number[][] | null;
    created_at: string; // Changed to string since dates are often strings from JSON
    subdata: string[]; // Array of subdata
    total_grid: number;
    totalPrice: number;
    currency: string;
    id: string;
    alias: string;
    address: string;
}

interface ChartProps {
    data: CatalogCardProps[];
}

export const DrawerMyChart: React.FC<ChartProps> = ({ data }) => {
    const { setModalOpenCart, isModalCart, setDataCart, modalWalletCartPayment, setModalWalletCartPayment } = useReduxCatalog();
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [, setLoading] = useState(false);
    const { getDetailUser, dataUser } = useReduxUser();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('bank');

    const user = auth.currentUser;

    const fetchGetUser = async () => {
        if (!user?.uid) return; // Ensure user exists
        setLoading(true);
        try {
            const userId = user.uid;
            await getDetailUser(userId);  // Fetch user details
        } catch (error) {
            console.error('Error fetching user details:', error);
        } finally {
            setLoading(false);  // Set loading to false after fetching
        }
    };

    useEffect(() => {
        if (user?.uid) {
            fetchGetUser();  // Fetch user details when user is available
        }
        // eslint-disable-next-line 
    }, [user?.uid]);  // Trigger effect when user changes

    const handleCheckout = () => {
        const selectedData = data.filter((item) => selectedItems.includes(item.id));
        setDataCart(selectedData);
        setModalOpenCart(true);
        setSelectedItems([])
    };

    const handleCheckboxChange = (productId: string) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.includes(productId)
                ? prevSelectedItems.filter((id) => id !== productId)
                : [...prevSelectedItems, productId]
        );
    };
    const totalPrices = selectedItems?.reduce((sum, productId) => {
        const product = data.find((item) => item.id === productId);
        return sum + (product ? product.totalPrice : 0);
    }, 0);


    const formattedTotalPrice = totalPrices.toLocaleString('id-ID', {
        style: 'currency',
        currency: 'IDR',
    });

    const handleChangePaymentMethode = (value: any) => {
        setSelectedPaymentMethod(value);
    };

    const handleOpenWallet = () => {
        const selectedData = data.filter((item) => selectedItems.includes(item.id));
        setDataCart(selectedData);
        setModalWalletCartPayment(true)
    };
    return (
        <React.Fragment>
            {/* Header */}
            <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
                <h2 style={{ color: 'white' }}>Cart</h2>
            </div>

            {/* List of Products */}
            <div style={{ overflowY: 'auto', height: 'calc(70vh - 50px)', }}>
                {data?.map((item, index) => (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            marginBottom: '10px',
                        }}
                    >
                        <input
                            type="checkbox"
                            // checked={selectedItems.includes(item.product)}
                            checked={selectedItems.includes(item.id)}

                            onChange={() => handleCheckboxChange(item.id)} // Use `id` to toggle
                            style={{ marginRight: '10px', width: '20px', height: '50px' }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                height: '120px',
                                width: '100%',
                                alignItems: 'center',
                                // border: '1px solid white',
                                backgroundColor: '#141617',
                                padding: '10px',
                                borderRadius: '10px',
                                marginBottom: '5px',
                            }}
                        >
                            {/* Product Information */}
                            <div style={{ flex: 1 }}>
                                <div style={{ fontWeight: 'bold', marginBottom: '10px', display: 'flex', justifyContent: 'space-between', }}>
                                    <p style={{ fontSize: '12px', fontWeight: 'bold' }}>{item.alias}</p>

                                    <div
                                        style={{
                                            background: 'white',
                                            padding: '5px',
                                            // width: '30px',
                                            height: '20px',
                                            borderRadius: '12px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Text style={{ color: '#316DBA', margin: 0, fontSize: '10px' }}>50x50</Text>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', marginTop: '10px', marginBottom: '5px' }}>
                                    <img alt="example" src={icAreaSize} />
                                    <p style={{ color: '#DCE1E7', margin: 0, fontSize: '12px' }}>{item.area} km²</p>
                                    <img alt="example" src={icGridTotal} />
                                    <p style={{ color: '#DCE1E7', margin: 0, fontSize: '12px' }}>{formatNumberWithDots(item.total_grid)}</p>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', marginBottom: '10px' }}>
                                    <IoMdTime />
                                    <p style={{ color: '#DCE1E7', margin: 0, fontSize: '12px' }}>{formatDateOnlyMonth(item.created_at)}</p>
                                </div>
                                <div style={{ fontWeight: 'bold', color: 'white' }}>
                                    {item.totalPrice.toLocaleString('id-ID', {
                                        style: 'currency',
                                        currency: 'IDR',
                                    })}
                                </div>
                            </div>

                        </div>
                    </div>
                ))}
                <div
                    style={{
                        bottom: 30,
                        padding: '5px',
                        position: 'absolute',
                        borderRadius: '10px',
                    }}
                >
                    <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Payment Methode</span>
                    <Select
                        defaultValue="bank"
                        style={{
                            width: '100%',
                            marginTop: '5px',
                            backgroundColor: '#000',    // Black background for the select body
                            color: '#fff',              // White text color for the selected option
                        }}
                        dropdownStyle={{ padding: 8 }}
                        onChange={handleChangePaymentMethode}
                        placeholder="Select Payment Method"
                    >
                        <Option value="bank">
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <BankOutlined style={{ fontSize: '16px' }} />
                                <span>Bank Transfer</span>
                            </div>
                        </Option>
                        <Option
                            disabled={dataUser?.balance < totalPrices || selectedItems === undefined || selectedItems.length === 0}
                            value="wallet">
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <WalletOutlined style={{ fontSize: '12px' }} />
                                        <span>GeoWallet</span>
                                    </div>
                                    <span style={{ fontSize: '14px' }}>Rp {formatNumberWithDots(dataUser?.balance)}</span>
                                </div>

                                {dataUser?.balance < totalPrices && (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                        <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />
                                        <span style={{ color: '#ff4d4f', backgroundColor: '#fff2f0', padding: '4px 8px', borderRadius: '4px', fontSize: '12px' }}>
                                            GeoCredit Not enough
                                        </span>
                                    </div>
                                )}
                            </div>
                        </Option>


                    </Select>
                    {/* <div style={{ marginTop: '5px', marginBottom: '5px', color: 'white' }}>Total</div>
                    <div style={{ marginBottom: '5px', color: 'white' }}>
                        {selectedItems.length} Items for
                    </div> */}
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '3px', marginTop: '5px', marginBottom: '5px' }}>
                        <div style={{ color: 'white' }}>
                            Total
                        </div>
                        <div style={{ marginBottom: '5px', color: 'white' }}>
                            {selectedItems.length}  Items for {formattedTotalPrice}
                        </div>
                    </div>
                    {/* <div style={{ marginBottom: '10px', color: 'white' }}>{formattedTotalPrice}</div> */}
                    <Button
                        // onClick={handleCheckout}
                        onClick={
                            selectedPaymentMethod === 'wallet'
                                ? handleOpenWallet
                                : handleCheckout
                        }
                        disabled={selectedItems.length === 0}
                        type="primary"
                        style={{
                            width: '100%',
                            height: '38px',
                            color: 'white',
                            backgroundColor: selectedItems.length === 0 ? 'gray' : '#4A7FC0',
                            borderColor: selectedItems.length === 0 ? 'gray' : '#4A7FC0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '16px',
                            // fontWeight: 700,
                        }}
                    >
                        Checkout
                    </Button>
                </div>
            </div>

            {/* Total and Checkout Section */}


            <ModalCart
                isModal={isModalCart} />
            <ModalWalletCartMethode
                isModalConfirmPayment={modalWalletCartPayment}
            />
        </React.Fragment>
    );
};
