import React from 'react';
import { Space } from 'antd';
import { CardListdownload } from '../../shared/map/card/card.download.data';

export const MenuCardDownloadData: React.FC<any> = ({ data }) => {    
    return (
        <React.Fragment>
            <Space
                direction="vertical"
                style={{
                    position: 'absolute',
                    left: '360px',
                    top: '0%',
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                 <CardListdownload />
            </Space>
        </React.Fragment>
    );
};
