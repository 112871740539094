// import React, { useEffect, useRef, useState } from "react";

import iconHeader from "../../asesst/new/icon/logo-profiling.svg"
import iconLoc from "../../asesst/new/icon/mdi_address-marker-outline.png"
import iconPos from "../../asesst/new/icon/flowbite_globe-outline.png"
import iconRes from "../../asesst/new/icon/f7_resize.png"

import iconHub from "../../asesst/new/icon/mingcute_building-6-line.svg"
// import iconDemo from "../../asesst/new/icon/demogrphy.svg"
// import iconAccess from "../../asesst/new/icon/solar_accessibility-outline.svg"
// import iconKey from "../../asesst/new/icon/hugeicons_house-04.svg"

// import iconavg from "../../asesst/new/icon/hugeicons_chart-average.svg"
// import iconmin from "../../asesst/new/icon/tdesign_chart-minimum.svg"
// import iconmed from "../../asesst/new/icon/carbon_chart-median.svg"
// import iconmax from "../../asesst/new/icon/icon-park-outline_maximum.svg"
// import icontotal from "../../asesst/new/icon/bi_textarea.svg"
// import iconArea from "../../asesst/new/icon/totalarea.svg"
// import iconDisCart from "../../asesst/new/icon/distirbutioncart.svg"
// import iconTop5 from "../../asesst/new/icon/ion_podium-outline.svg"

import { FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
// import { LoadingOutlined } from '@ant-design/icons';
// import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// import { useLocation } from "react-router-dom";
// import html2canvas from "html2canvas";
// import GridParser from "../newmap/helper/map/geojson/grid";
// import { getColorFromInterpolation } from "../newmap/helper/coloring/color.helper";
// import useReduxCatalog from "../../hoc/hooks/useCatalog";
// import jsPDF from "jspdf";
import bgReport from '../../asesst/new/img/bg-report.png'
import bg2 from '../../asesst/new/icon/bg2.png'
// import { Spin } from "antd";
import { XMapReport } from "../map/content/mapReport";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
// interface TrafficData {
//     total: number;
//     earlymorning: number;
//     morning: number;
//     afternoon: number;
//     evening: number;
//     night: number;
// }

// interface Overview {
//     title: string;
//     overview: string;
// }

// interface DetailData {
//     title: string;
//     range: string;
//     summary: string;
//     color: string;
// }

// interface Detail {
//     title: string;
//     data: DetailData[];
// }

// interface Recommendation {
//     title: string;
//     recomendation: string;
// }

// interface MapData {
//     title: string;
//     location: string;
//     coordinates: string;
//     size_of_area: string;
//     overview: Overview[];
//     detail: Detail[];
//     recomendations: Recommendation[];
// }


// type SummaryTraficHistogram = Record<string, TrafficData>;

const GenerateSummaryPageProfilling: React.FC = () => {



    // console.log('overviewData', overviewData);
    return (
        <>


            <div className="page" style={page1ContainerStyle}>
                <div
                    style={{

                        justifyContent: "center",
                        // textAlign: "right",
                        backgroundImage: `url(${bgReport})`,

                    }}
                >

                    <div style={page1LogoStyle}>
                        <img
                            src={iconHeader}
                            alt="Logo"
                        />
                    </div>
                    <div style={page1ReportTitleStyle}>LOCATION PROFILE</div>

                    <div style={page1SubtitleStyle}>Antasari Place</div>


                </div>
                <div style={page1InfoContainerStyle}>
                    <div style={page1InfoBoxStyle}>
                        <div style={page1InfoTitleStyle}>
                            <img
                                src={iconLoc}
                                height={20}
                                alt="Logo"
                            />Location Address
                        </div>
                        <div style={page1InfoContentStyle}>
                            Cilandak Barat, Cilandak, Kota Jakarta Selatan, DKI Jakarta
                        </div>
                    </div>

                    <div style={page1InfoBoxStyle}>
                        <div style={page1InfoTitleStyle}>
                            <img
                                src={iconPos}
                                height={20}
                                alt="Logo"
                            />Position
                        </div>
                        <div style={page1InfoContentStyle}>
                            -6.290220835632738, 106.80676750749528
                        </div>
                    </div>

                    <div style={page1InfoBoxStyle}>
                        <div style={page1InfoTitleStyle}>
                            <img
                                src={iconRes}
                                height={20}
                                alt="Logo"
                            />Size of the area
                        </div>
                        <div style={page1InfoContentStyle}>

                            6 km²
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        position: 'absolute',
                        justifySelf: 'anchor-center',
                        top: '750px',
                        justifyContent: "center",
                        alignItems: "center",
                        color: '#7D8587',
                        gap: "10px",

                    }}
                >
                    <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <FaInstagram size={20} /> geosquare.ai
                    </span>
                    <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <FaLinkedin size={20} />  geosquare.ai
                    </span>
                    <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <FaTwitter size={20} /> geosquare.ai
                    </span>
                </div>
            </div>

            <div className="page" style={page1ContainerStyle}>
                <section>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h3 style={{ fontSize: "24px", color: "#444", fontWeight: 'bolder' }}>
                            Location Overview
                        </h3>

                        <span style={{ fontSize: "24px", color: "#444", cursor: "pointer" }}>
                            <img
                                src={iconHeader}
                                alt="Logo"
                                style={{
                                    height: "50px",   // Mengatur ukuran ikon

                                }}
                            />
                        </span>
                    </div>

                    <div

                        style={{
                            gap: "20px",
                            marginBottom: "5px",
                        }}
                    >

                        <div
                            // ref={mapRef1} // Reference added here
                            style={{
                                flex: 1,
                                minWidth: '400px',
                                borderRadius: "8px",
                                overflow: "hidden",
                            }}
                        >
                            {/* {dataLayerGroup?.[0]?.isSeries ? ( */}
                            <XMapReport />
                            {/* ) : (
                                <XMapPoint filter={filteredData?.[0]?.columnName} />
                            )} */}
                        </div>

                        <div
                            style={{
                                flex: 1,
                                backgroundImage: `url(${bg2})`,
                                backgroundPosition: "bottom right", // Menempatkan gambar di kanan bawah
                                backgroundSize: "60%",  // Mengubah ukuran gambar menjadi 60%
                                maxHeight: '450px',
                                height: '450px',
                                marginTop: '10px',
                                backgroundRepeat: 'no-repeat', // Menghindari pengulangan gambar
                            }}>



                            {/* {isLoading ? (
                                <div style={loadingStyle}>
                                    AI Generate Summary...</div>
                            ) : ( */}

                            <div key={'s'} style={infoBoxStyle}>
                                <div style={iconStyle}>
                                    <img
                                        src={iconHub}
                                        alt="Logo"
                                    />
                                </div>
                                <div style={textContentStyle}>
                                    <p style={descriptionStyle}>
                                        {/* <strong>{stat?.title}</strong>: {stat?.overview} */}
                                        Urban Hub:
                                        Well-established residential and commercial area, known for its modern housing, business centers, and shopping hubs.
                                        Home to both locals and expatriates, making it a diverse and vibrant neighborhood.
                                        Key developments include premium residential complexes, office spaces, and retail centers like Cilandak Town Square (Citos).
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>

            </div>

            {/* footer */}
            <div
                className="page"
                style={{
                    height: "842px", // Lebar A4 dalam piksel
                    width: "595px", // Tinggi A4 dalam piksel
                    marginBottom: "5px",
                    marginTop: "5px",
                    margin: "0 auto",
                    fontFamily: "'Arial', sans-serif",
                    color: "#333",
                    lineHeight: "1.5",
                    backgroundColor: "#fff",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "relative",

                }}
            >
                {/* Logo and title centered */}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: '500px',
                        flex: 1,

                    }}
                >
                    <img
                        src={iconHeader} // Ganti dengan URL logo Anda
                        alt="GeoSquare Logo"
                        style={{
                            height: "50px",   // Mengatur ukuran ikon
                            marginBottom: "10px"
                        }}
                    />

                    <p
                        style={{
                            fontSize: "16px",
                            color: '#7D8587',
                            margin: 0,
                        }}
                    >
                        www.geosquare.ai
                    </p>
                    <div
                        style={{
                            display: "flex",
                            paddingTop: '20px',
                            justifyContent: "center",
                            alignItems: "center",
                            color: '#7D8587',
                            gap: "10px",

                        }}
                    >
                        <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <FaInstagram size={20} /> geosquare.ai
                        </span>
                        <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <FaLinkedin size={20} />  geosquare.ai
                        </span>
                        <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <FaTwitter size={20} /> geosquare.ai
                        </span>
                    </div>
                </div>
                <img
                    src={bg2} // Ganti dengan URL logo Anda
                    alt="GeoSquare Logo"
                    style={{
                        position: 'absolute',
                        bottom: '0px',
                        right: '0px',
                        marginBottom: "10px"
                    }}
                />
                {/* Footer with social media icons */}

            </div>
        </>
    );
};

export default GenerateSummaryPageProfilling;

const infoBoxStyle = {
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: "#fff",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    marginBottom: '10px',
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
};

const iconStyle = {
    marginRight: "15px",
    fontSize: "24px",
    color: "#4a7fc0",
};

const textContentStyle = {
    flex: 1,
};


const descriptionStyle = {
    fontSize: "12px",
    color: "#555",
    marginTop: "5px",
};


// const gridStyle: React.CSSProperties = {
//     display: "grid",
//     gridTemplateColumns: "repeat(2, 1fr)",
//     gap: "10px",
//     padding: "10px",
// };


// const cardStyle: React.CSSProperties = {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "flex-start",
//     justifyContent: "center",
//     padding: "10px",
//     borderRadius: "10px",
//     backgroundColor: "#fff",
//     border: "1px solid #e0e0e0",
//     boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
// };

// const iconAndLabelStyle: React.CSSProperties = {
//     display: "flex",
//     alignItems: "center",
//     gap: "10px", // Jarak antara ikon dan label
//     // marginBottom: "10px", // Memberikan jarak antara label-ikon dan nilai
// };

// const iconStyleStat = {
//     fontSize: "30px",
//     color: "#4a7fc0",
// };

// const labelStyle: React.CSSProperties = {
//     fontSize: "12px",
//     fontWeight: "bold",
//     color: "#4a7fc0",
// };

// const valueStyle: React.CSSProperties = {
//     fontSize: "21px",
//     fontWeight: "bold",
//     color: "#4A7FC0",
// };

const page1ContainerStyle: React.CSSProperties = {
    width: "595px", // Lebar A4 dalam piksel
    height: "842px", // Tinggi A4 dalam piksel
    marginBottom: '5px',
    marginTop: '5px',
    margin: "0 auto",
    fontFamily: "'Arial', sans-serif",
    color: "#333",
    lineHeight: "1.5",
    backgroundColor: "#fff",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    // display: "flex",
    justifyItems: "center",
    placeContent: 'center'
    // alignItems: "flex-start",
};

// const page1LeftSectionStyle: React.CSSProperties = {
//     width: "50%",
// };


const page1LogoStyle: React.CSSProperties = {
    justifySelf: 'center',
    // height:'69px',
    // width:'169px'
    // display: "flex",
    // alignItems: "center",
    // gap: "10px",
    // marginBottom: "40px",
};

const page1ReportTitleStyle: React.CSSProperties = {
    fontSize: "61px",
    lineHeight: 'normal',
    fontWeight: 800,
    color: "#4A7FC0",
    width: '400px',
    textAlign: "center"
};
// const loadingStyle: React.CSSProperties = {
//     fontSize: "18px",
//     fontWeight: "bold",
//     color: "#4a7fc0",
// };

const page1SubtitleStyle: React.CSSProperties = {
    fontSize: "18px",
    fontWeight: "bold",
    backgroundColor: "#4a7fc0",
    color: "#fff",
    textAlign: 'center',
    padding: "10px 10px",
    borderRadius: "5px",
    marginTop: "10px",
};

const page1InfoContainerStyle: React.CSSProperties = {
    display: "flex",
    gap: "10px",
    marginTop: '100px'
};

const page1InfoBoxStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #ddd",
    borderRadius: "8px",
    minHeight: '100px',
    minWidth: '150px',
    maxWidth: '180px',
    padding: "15px",
    backgroundColor: "transparent",
};

const page1InfoTitleStyle: React.CSSProperties = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#4a7fc0",
    display: "flex",
    alignItems: "center",
    gap: "8px",
};

const page1InfoContentStyle: React.CSSProperties = {
    fontSize: "12px",
    marginTop: "8px",
    color: "#4a7fc0",
};


// new
