import maplibregl, { DataDrivenPropertyValueSpecification } from 'maplibre-gl';
import { ExpressionSpecification } from 'maplibre-gl';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import GridParser from './grid';
import { loadParquet } from '../../queue/parquet';
import { PropertyData } from '../../../../../interface/parquete.interface';
import { clearPolygonLayer, removeGeocodeLayersAndPopups, removeIsochronesAndPopups, removeLayerTop5 } from '../sourceLayer';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

export const addGeoJsonLayerParquetProfilling = async (
  map: maplibregl.Map,
  draw: MapboxDraw,
  metaData: any,
  metaDataProfiling: any,
  setDataLayerGroup: (layerGroup: any) => void, // Update type if needed
  setGeojsonFitBounds: (layerFeatures: any) => void,
  dataSeries: string,
  setIsSeries: (isSeries: boolean) => void,
  colorGroup: any,
  popUpFilter: any,
  onClickFeature?: (feature: any) => void,
  fillOpacity: number = 0.5,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  const storage = getStorage();
  const grid_lib = new GridParser();
  const listRef = ref(storage, metaDataProfiling?.dataPath);

  //   const listRefMetaData = ref(storage, storageMetaData);
  removeGeocodeLayersAndPopups(map);
  removeIsochronesAndPopups(map)
  clearPolygonLayer(map, draw)
  removeLayerTop5(map)
  try {
    if (setIsLoading) setIsLoading(true); // Start loader
    const downloadUrl = await getDownloadURL(listRef);
    const partData: unknown[] = await loadParquet(downloadUrl);
    const propertyDataList = partData as PropertyData[];

    setIsSeries(false)
    // Tentukan grup berdasarkan kondisi isSeries
    let groupedData: any;
    let layerGroup = [];

    if (!metaDataProfiling?.subdata && metaDataProfiling?.times?.length > 0) {
      // Jika tidak ada subdata tetapi memiliki times array, gunakan times
      groupedData = propertyDataList;
      layerGroup = metaDataProfiling.times;
    } else if (!metaDataProfiling?.subdata || metaDataProfiling?.subdata.length === 0) {
      // Jika tidak ada subdata dan tidak ada times, gunakan metaDataProfiling langsung
      groupedData = propertyDataList;
      layerGroup = [metaDataProfiling];
    } else {
      // Jika ada subdata, gunakan subdata
      groupedData = propertyDataList;
      layerGroup = metaDataProfiling.subdata;
    }

    setDataLayerGroup(layerGroup);
    // Hapus layer dan source yang ada sebelumnya
    const layers = map.getStyle().layers || [];
    const sources = map.getStyle().sources || {};

    layers.forEach((layer) => {
      if (layer.id.startsWith('layer-')) {
        if (map.getLayer(layer.id)) {
          map.removeLayer(layer.id);
        }
      }
    });

    Object.keys(sources).forEach((sourceId) => {
      if (sourceId.startsWith('source-')) {
        if (map.getSource(sourceId)) {
          map.removeSource(sourceId);
        }
      }
    });

    // const allFeatures: any[] = [];
    const BATCH_SIZE = 1000; // Ukuran batch yang sesuai dengan performa perangkat
    // return null
    layerGroup.forEach((group: any) => {
      const sourceId = `source-${group.columnName}`; // Unique source per group
      const layerId = `layer-${group.columnName}`;   // Unique layer per group
      const batchCount = Math.ceil(groupedData.length / BATCH_SIZE);

      // Create a fresh list for features for each group
      const allFeatures: any[] = [];
      for (let batchIndex = 0; batchIndex < batchCount; batchIndex++) {
        const start = batchIndex * BATCH_SIZE;
        const end = start + BATCH_SIZE;

        // Process each batch
        const batchFeatures = groupedData.slice(start, end).map((item: { gid: string, color: string }) => ({
          type: 'Feature',
          properties: { ...item },
          geometry: {
            type: 'Polygon',
            coordinates: [grid_lib._gid_to_geometry(item.gid)],  // Use the appropriate geometry transformation
          },
        }));

        allFeatures.push(...batchFeatures);
      }

      // Add unique source for each group
      map.addSource(sourceId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: allFeatures,
        },
      });
      // passing untuk feature di depan
      setGeojsonFitBounds(
        {
          id: metaData?.id,
          features: allFeatures
        })
      // Color assignment logic for each layer
      const { min, max, bins, columnName } = group;
      const isInsightColumn = columnName.split('_').pop()?.toLowerCase() === 'insight';

      const generateRange = (start: number, end: number, length: number): number[] => {
        const step = (end - start) / (length - 1); // Range step
        return Array.from({ length }, (_, i) => start + i * step);
      };

      const rangeInsight = generateRange(0, 1, 10);
      const range = isInsightColumn
        ? Array.from(new Set<number>([min, ...rangeInsight, max])).sort((a, b) => a - b)
        : Array.from(new Set<number>(bins)).sort((a, b) => a - b);

      const colorStops: ExpressionSpecification = [
        'interpolate',
        ['linear'],
        ['get', `${columnName}`],
        ...range.flatMap((value, index) => [
          value,
          colorGroup?.color[index] || '#000' // Fallback if color is missing
        ]),
      ];

      // Add the layer with unique ID
      map.addLayer({
        id: layerId,
        type: 'fill',
        source: sourceId,
        minzoom: 2,
        paint: {
          'fill-color': colorGroup.type === 'gradient'
            ? colorStops
            : colorStops as DataDrivenPropertyValueSpecification<string>,
          'fill-opacity': 0.8, // Default opacity
          'fill-outline-color': 'transparent',
        },
      });

      // Interactivity (hover, click)
      map.on('mousemove', layerId, (e) => {
        if (e.features && e.features.length > 0) {
          map.getCanvas().style.cursor = 'pointer';
        }
      });

      map.on('mouseleave', layerId, () => {
        map.getCanvas().style.cursor = '';
        if (map.getLayer(`${layerId}-highlight`)) {
          map.removeLayer(`${layerId}-highlight`);
        }
      });

      map.on('click', layerId, (e) => {
        if (e.features && e.features.length > 0) {
          const feature = e.features[0];
          const coordinates = e.lngLat;

          const tableHtml = `
            <div style="max-height: 320px; overflow-y: auto;">
            <table style="width: 100%; border-collapse: separate; color: white;">
            <thead>
                <tr>
                <th style="border-bottom: 0.5px solid #ddd; padding: 8px; text-align: left;">Selected Item</th>
                <th style="border-bottom: 0.5px solid #ddd; padding: 8px; text-align: left;"></th>
                </tr>
            </thead>
            <tbody>
                ${Object.entries(feature.properties)
              .filter(([key]) => key !== 'gid' && key !== 'color')
              .map(([key, value]) => `
                <tr>
                    <td style="padding: 8px;"><strong>${key.replace(/_/g, ' ')}</strong></td>
                    <td style="padding: 8px;">${value}</td>
                </tr>
                `).join('')}
                </tbody>
                </table>
            </div>
            `;

          new maplibregl.Popup({
            className: 'content-popup',
          })
            .setLngLat(coordinates)
            .setHTML(tableHtml)
            .addTo(map);
        }
      });
    });



    // Fit map bounds ke fitu
  } catch (e) {
  } finally {
    if (setIsLoading) setIsLoading(false); // Stop loader, ensuring it stops even on error
  }
};

