import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import { FolderOutlined } from "@ant-design/icons";
import { FaChevronRight } from "react-icons/fa";

interface PopupModalProps {
    visible: boolean;
}

type DataStructure = {
    [category: string]: {
        subcategories: {
            [subcategory: string]: string[];
        };
    };
};

const ModalCustomScor: React.FC<PopupModalProps> = ({ visible }) => {
    const {
        setShowModalShowCustomSCroring,
        setsetDataCustomScoring,
        dataCustomScoring,
        getListDataProduct,
        dataListProduct,
    } = useReduxCatalog();

    const [categories, setCategories] = useState<Record<string, boolean>>({});
    const [subcategories, setSubcategories] = useState<Record<string, boolean>>({});
    const [countSelected, setCountSelected] = useState<number>(0);
    const [checkedItems, setCheckedItems] = useState<Record<string, Record<string, Record<string, boolean>>>>({});

    const [searchQuery, setSearchQuery] = useState<string>('');

    const [checkedCategories, setCheckedCategories] = useState<{ [key: string]: boolean }>({});
    const [checkedSubcategories, setCheckedSubcategories] = useState<{ [key: string]: boolean }>({});

    const sortedDummyData: DataStructure = Object.keys(dataListProduct ?? {})
    .sort()
    .reduce((acc, category) => {
        const subcategories = dataListProduct?.[category]?.subcategories ?? {};

        // Urutkan subkategori
        const sortedSubcategories = Object.keys(subcategories)
            .sort()
            .reduce((subAcc, subcategory) => {
                // Urutkan item dalam setiap subkategori
                const sortedItems = [...(subcategories[subcategory] ?? [])].sort();

                subAcc[subcategory] = sortedItems;
                return subAcc;
            }, {} as Record<string, string[]>);

        acc[category] = { subcategories: sortedSubcategories };
        return acc;
    }, {} as DataStructure);

    const dummyData: DataStructure = sortedDummyData;

    const handleCategoryCheck = (category: string, checked: boolean) => {
        setCheckedCategories(prev => ({
            ...prev,
            [category]: checked
        }));
    
        setCheckedSubcategories(prev => {
            const newCheckedSubcategories = { ...prev };
    
            if (checked) {
                // Centang semua subkategori dalam kategori ini
                Object.keys(dummyData[category]?.subcategories || {}).forEach(subcategory => {
                    newCheckedSubcategories[subcategory] = true;
                });
            } else {
                // Uncheck semua subkategori dalam kategori ini
                Object.keys(dummyData[category]?.subcategories || {}).forEach(subcategory => {
                    delete newCheckedSubcategories[subcategory];
                });
            }
    
            return newCheckedSubcategories;
        });
    
        setCheckedItems(prev => {
            const newCheckedItems = { ...prev };
    
            if (checked) {
                // Centang semua item dalam kategori ini
                if (!newCheckedItems[category]) newCheckedItems[category] = {};
    
                Object.entries(dummyData[category]?.subcategories || {}).forEach(([subcategory, items]) => {
                    if (!newCheckedItems[category][subcategory]) newCheckedItems[category][subcategory] = {};
                    
                    items.forEach(item => {
                        newCheckedItems[category][subcategory][item] = true;
                    });
                });
            } else {
                // Hapus semua item dalam kategori ini
                delete newCheckedItems[category];
            }
    
            return newCheckedItems;
        });
    };
    
  
    const handleCategoryButton = (category: string) => {
        // Jika kategori yang diklik sudah aktif, unselect semua
        if (categories[category]) {
            setCategories({});
            setSubcategories({});
            return;
        }
    
        // Reset kategori dan pilih kategori baru
        setCategories({ [category]: true });
    
        // Buat subkategori baru sesuai dengan kategori yang dipilih
        const newSubcategories: { [key: string]: boolean } = {};
        Object.keys(dummyData[category]?.subcategories || {}).forEach(subcategory => {
            newSubcategories[subcategory] = subcategories[subcategory] || false;
        });
    
        setSubcategories(newSubcategories);
    };
    
    const handleSubcategoryCheck = (category: string, subcategory: string, checked: boolean) => {
        setCheckedSubcategories(prev => ({
            ...prev,
            [subcategory]: checked
        }));
    
        // Ambil item dalam subkategori berdasarkan kategori yang benar
        const items = dummyData[category]?.subcategories[subcategory] || [];
    
        setCheckedItems(prev => {
            const newCheckedItems = { ...prev };
    
            if (!newCheckedItems[category]) {
                newCheckedItems[category] = {};
            }
    
            if (!newCheckedItems[category][subcategory]) {
                newCheckedItems[category][subcategory] = {};
            }
    
            // Centang atau hapus centang semua item dalam subkategori ini
            items.forEach(item => {
                newCheckedItems[category][subcategory][item] = checked;
            });
    
            return newCheckedItems;
        });
    };
    
    
    const handleSubcategoryButton = (subcategory: string, category: string) => {
        setSubcategories(prev => {
            const newSubcategories = { ...prev };
    
            // Jika subcategory sudah dipilih, hapus dari state
            if (newSubcategories[subcategory]) {
                delete newSubcategories[subcategory];
            } else {
                // Reset semua subcategories dalam kategori yang sama dan set hanya yang baru
                Object.keys(newSubcategories).forEach(key => {
                    if (dummyData[category].subcategories[key]) {
                        delete newSubcategories[key];
                    }
                });
                newSubcategories[subcategory] = true;
            }
    
            return newSubcategories;
        });
    
        setCheckedItems(prev => {
            const newCheckedItems = { ...prev };
    
            if (!newCheckedItems[category]) {
                newCheckedItems[category] = {};
            }
    
            if (!newCheckedItems[category][subcategory]) {
                newCheckedItems[category][subcategory] = {};
            }
    
            // Cek apakah subcategory punya items atau perlu menambahkan sendiri
            const subcategoryItems = dummyData[category]?.subcategories[subcategory];
    
            if (Array.isArray(subcategoryItems) && subcategoryItems.length === 0) {
                newCheckedItems[category][subcategory][subcategory] = true; // Set subcategory sebagai checked jika kosong
            }
    
            return newCheckedItems;
        });
    };
    

    const handleItemCheck = (category: string, subcategory: string, item: string, checked: boolean) => {
        setCheckedItems(prev => {
            const newChecked = { ...prev };
    
            if (!newChecked[category]) {
                newChecked[category] = {};
            }
            if (!newChecked[category][subcategory]) {
                newChecked[category][subcategory] = {};
            }
    
            newChecked[category][subcategory][item] = checked;
    
            return { ...newChecked };
        });
    };

    const handleCloseModal = () => {
        setShowModalShowCustomSCroring(false);
        // setCheckedCategories({});
        // setCheckedSubcategories({});
        setCheckedItems({});
        // setCategories({});
        // setSubcategories({});
    }
    const handleChoose = () => {
        const selected: DataStructure = {};
    
        Object.entries(dummyData).forEach(([category, { subcategories }]) => {
            let selectedSubcategories: Record<string, string[]> = {};
    
            Object.entries(subcategories).forEach(([subcategory, items]) => {
                const checkedItemsInSubcategory = items.filter(item => checkedItems[category]?.[subcategory]?.[item]);
    
                if (checkedSubcategories[subcategory] || checkedItemsInSubcategory.length > 0) {
                    selectedSubcategories[subcategory] = checkedItemsInSubcategory.length > 0 ? checkedItemsInSubcategory : [subcategory];
                }
            });
    
            if (Object.keys(selectedSubcategories).length > 0) {
                selected[category] = { subcategories: selectedSubcategories };
            }
        });
    
        setsetDataCustomScoring(selected);
        handleCloseModal()
    };
    
    const filteredData = Object.entries(dummyData).reduce<DataStructure>((acc, [category, { subcategories }]) => {
        if (
            category.toLowerCase().includes(searchQuery) ||
            Object.keys(subcategories).some(subcategory =>
                subcategory.toLowerCase().includes(searchQuery) ||
                subcategories[subcategory].some(item => item.toLowerCase().includes(searchQuery))
            )
        ) {
            acc[category] = {
                subcategories: Object.entries(subcategories).reduce((subAcc, [subcategory, items]) => {
                    const filteredItems = items.filter(item => item.toLowerCase().includes(searchQuery));
                    if (subcategory.toLowerCase().includes(searchQuery) || filteredItems.length > 0) {
                        subAcc[subcategory] = filteredItems.length > 0 ? filteredItems : items;
                    }
                    return subAcc;
                }, {} as DataStructure[string]['subcategories']),
            };
        }
        return acc;
    }, {});

    React.useEffect(() => {
        if (visible) {
            getListDataProduct();
        } // eslint-disable-next-line
    }, [visible]);

    const isChooseDisabled = () => {
        const hasCheckedItems = Object.values(checkedItems).some(Boolean);
        
        const hasCheckedEmptySubcategories = Object.keys(checkedSubcategories).some(sub => 
            checkedSubcategories[sub] && (!dummyData[sub]?.subcategories || Object.keys(dummyData[sub].subcategories).length === 0)
        );
    
        return !(hasCheckedItems || hasCheckedEmptySubcategories);
    };
    
    
    // Fungsi untuk mencari data berdasarkan kategori, subkategori, dan item
    const handleSearch = (query: string) => {
        setSearchQuery(query.toLowerCase());
    };

    const isSubcategoryEmpty = (items: string[]): boolean => {
        return items.length === 0;
    };
    
    useEffect(() => {
        let itemsCount = 0; // Counter untuk jumlah total items
    
        Object.entries(dummyData).forEach(([category, { subcategories }]) => {
            Object.entries(subcategories).forEach(([subcategory, items]) => {
                const checkedItemsInSubcategory = items.filter(
                    item => checkedItems[category]?.[subcategory]?.[item]
                );
    
                if (checkedSubcategories[subcategory] || checkedItemsInSubcategory.length > 0) {
                    // Jika ada item yang dipilih, hitung hanya yang dipilih
                    if (checkedItemsInSubcategory.length > 0) {
                        itemsCount += checkedItemsInSubcategory.length;
                    } else {
                        // Jika subcategory kosong, hitung sebagai 1
                        itemsCount += items.length > 0 ? items.length : 1;
                    }
                }
            });
        });
    
        setCountSelected(itemsCount)
        // eslint-disable-next-line
    }, [ checkedItems, checkedSubcategories]); // Tambahkan dependency array
    
    useEffect(() => {
        if (visible && dataCustomScoring) {
            // Ambil data yang sebelumnya sudah dipilih dari redux atau state global
            const storedData = dataCustomScoring; // Ambil dari redux atau API jika tersedia
            if (storedData) {
                const newCheckedItems: Record<string, Record<string, Record<string, boolean>>> = {};
                const newCheckedCategories: Record<string, boolean> = {};
                const newCheckedSubcategories: Record<string, boolean> = {};

                Object.entries(storedData).forEach(([category, { subcategories }]) => {
                    newCheckedCategories[category] = true;
                    newCheckedItems[category] = {};

                    Object.entries(subcategories).forEach(([subcategory, items]) => {
                        newCheckedSubcategories[subcategory] = true;
                        newCheckedItems[category][subcategory] = {};

                        items.forEach(item => {
                            newCheckedItems[category][subcategory][item] = true;
                        });
                    });
                });
                setCheckedItems(newCheckedItems);
            }
        }
        // eslint-disable-next-line
    }, [visible]); // Jalankan efek ini setiap kali modal dibuka
    return (
        <Modal
            styles={{
                content: { backgroundColor: '#333939',width:'900px'  },
                body: { backgroundColor: '#333939', color: 'white'}
            }}
            closeIcon={<CloseOutlined style={{ color: 'white' }} />}
            title={
                <div style={{
                    backgroundColor: '#333939',
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: '20px',
                    width: "100%"
                }}>
                    <span style={{ flexShrink: 0, color: 'white' }}>Data Source</span>
                    <Input
                        onChange={e => handleSearch(e.target.value)}
                        placeholder="Search..."
                        allowClear style={{ width: "300px", maxWidth: "100%" }} />
                </div>
            }
            open={visible}
            onCancel={() =>  handleCloseModal()}
            footer={[
                <Button
                    key="choose"
                    type="primary"
                    onClick={handleChoose} disabled={isChooseDisabled()}
                    style={{
                        backgroundColor: isChooseDisabled() ? 'gray' : "#4A7FC0",
                    }}
                >
                    Choose {countSelected}
                </Button>
            ]}
            width={800}
        >
            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                height: "400px",
                marginTop: '15px',
                marginBottom: '15px',
                border: "1px solid #e8e8e8",
                borderRadius: '3px'
            }}>
                {/* Categories */}
                <div style={{
                    flex: 1,
                    backgroundColor: '#333939',
                    borderRadius: "4px",
                    overflowY: "auto",
                    padding: "10px",
                    borderRight: "1px solid #e8e8e8"
                }}>
                    {dataListProduct === null ? (
                        <p>Loading...</p>
                    ) : (
                        Object.keys(filteredData).length === 0 ? (
                            <p style={{ color: '#888' }}>No results found</p>
                        ) : (
                            Object.keys(filteredData).map(category => (
                                <div
                                    key={category}
                                    style={{
                                        padding: "10px",
                                        backgroundColor: categories[category] ? "#141617" : "transparent",
                                        display: "flex",
                                        alignItems: "center",
                                        borderRadius: "10px",
                                        marginBottom: "5px",
                                        justifyContent: "flex-start", // Pastikan semuanya mulai dari kiri
                                        gap: "10px", // Beri jarak antara checkbox, teks, dan ikon
                                    }}
                                >
                                    <Checkbox
                                        checked={checkedCategories[category] || false} // Centang penuh kalau checkedCategories true
                                        indeterminate={!checkedCategories[category] && categories[category]} // Strip kalau categories true, tapi checkedCategories false
                                        onChange={(e) => handleCategoryCheck(category, e.target.checked)}
                                    />
                                    <p
                                        onClick={() => handleCategoryButton(category)}
                                        style={{
                                            color: "white",
                                            margin: 0,
                                            flex: 1,
                                            cursor: 'pointer'
                                        }}>{category}
                                    </p>

                                    <button
                                      onClick={() => handleCategoryButton(category)}
                                      style={{
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FaChevronRight style={{ color: "white", fontSize: "14px" }} />
                                    </button>
                                </div>

                            ))
                        )
                    )}
                </div>
                {/* Subcategories */}
                <div style={{
                    flex: 1,
                    backgroundColor: '#333939',
                    borderRadius: "4px",
                    overflowY: "auto",
                    borderRight: "1px solid #e8e8e8",
                    padding: "10px"
                }}>
                  
                {Object.keys(checkedCategories).some(key => checkedCategories[key]) ||
                Object.keys(categories).some(key => categories[key]) ? (
                    Object.keys(dummyData).map(category =>
                        (categories[category]) &&
                        Object.keys(dummyData[category].subcategories).map(subcategory => {
                            const isEmpty = isSubcategoryEmpty(dummyData[category].subcategories[subcategory]);
                            return (
                                <div
                                  key={subcategory}
                                  style={{
                                    padding: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                    borderRadius: "10px",
                                    backgroundColor: subcategories[subcategory] ? "#141617" : "transparent",
                                    justifyContent: "space-between",
                                    gap: "10px",
                                  }}
                                >
                                  <Checkbox
                                    checked={checkedSubcategories[subcategory] || false}
                                    indeterminate={!checkedSubcategories[subcategory] && subcategories[subcategory]} // Strip kalau categories true
                                    onChange={(e) =>
                                      handleSubcategoryCheck(category, subcategory, e.target.checked)
                                    }
                                  />
                                  <p
                                    onClick={() => {
                                      if (isEmpty) {
                                        handleSubcategoryCheck(category, subcategory, !checkedSubcategories[subcategory]);
                                      } else {
                                        handleSubcategoryButton(subcategory, category);
                                      }
                                    }}
                                    style={{
                                      color: "white",
                                      margin: 0,
                                      flex: 1,
                                      cursor: "pointer",
                                    }}
                                  >
                                    {subcategory}
                                  </p>
                              
                                  {/* ✅ Biar ikon tetap bisa diklik secara terpisah */}
                                  {!isEmpty && (
                                    <button
                                      onClick={() => handleSubcategoryButton(subcategory, category)}
                                      style={{
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FaChevronRight style={{ color: "white", fontSize: "14px" }} />
                                    </button>
                                  )}
                                </div>
                              );
                              
                        })
                    )
                ) : (
                    <div style={{
                        padding: "10px",
                        textAlign: "center",
                        color: "#888",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <FolderOutlined style={{ fontSize: "24px", marginBottom: "8px" }} />
                        Select categories first
                    </div>
                )}

                </div>

                {/* Items */}
                {/* {Object.keys(checkedSubcategories).some(key => checkedSubcategories[key]) || */}
                {
                Object.keys(subcategories).some(key => subcategories[key]) ? (
                <div style={{
                    flex: 1,
                    backgroundColor: '#333939',
                    borderRadius: "4px",
                    overflowY: "auto",
                    padding: "10px"
                }}>

                    {
                    Object.keys(checkedCategories).some(key => checkedCategories[key]) ||
                        Object.keys(categories).some(key => categories[key]) ? (

                        Object.keys(checkedSubcategories).some(key => checkedSubcategories[key]) ||
                            Object.keys(subcategories).some(key => subcategories[key]) ? (

                            Object.keys(dummyData).map(category =>
                                Object.keys(dummyData[category].subcategories).map(subcategory =>
                                    ( subcategories[subcategory]) &&
                                    dummyData[category].subcategories[subcategory].map(item => (
                                        <div key={item} style={{
                                            padding: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                            borderRadius: '10px',
                                            marginBottom: '5px',
                                            backgroundColor:  "transparent",
                                            justifyContent: "space-between"
                                        }}>
                                            <Checkbox
                                             checked={checkedItems[category]?.[subcategory]?.[item] || false}
                                             onChange={e => handleItemCheck(category, subcategory, item, e.target.checked)}
                                            >
                                                <p style={{ color: 'white' }}>{item}</p>
                                            </Checkbox>

                                        </div>
                                    ))
                                )
                            )
                        ) : (
                            <div style={{
                                padding: "10px",
                                textAlign: "center",
                                color: "#888",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <FolderOutlined style={{ fontSize: "24px", marginBottom: "8px" }} />
                                Select subcategories first
                            </div>
                        )
                    ) : (
                        <div style={{
                            padding: "10px",
                            textAlign: "center",
                            color: "#888",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <FolderOutlined style={{ fontSize: "24px", marginBottom: "8px" }} />
                            Select categories first
                        </div>
                    )}

                </div>
                ) : null}
            </div>
        </Modal>
    );
};

export default ModalCustomScor;
