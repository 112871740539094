import React from 'react';
import globalStyle from './style/globalCss';
import { Routes, Route, Navigate } from 'react-router-dom';
// import HomePage from './component/page/homePage';
import 'leaflet/dist/leaflet.css';
import { XMapLayout } from './component/map/content';
import DashboardPage from './page/dashboard';
import InsightPage from './page/solution/insightPage';
import DataPage from './page/solution/dataPage';
import SurveyPage from './page/solution/surveyPage';
import OthersPage from './page/solution/othersPage';
import SolutionPage from './page/solution/solutionPage';
import CatalogPage from './page/solution/catalogPage';
import 'normalize.css';
import { Login, RegistrationForm } from './page/user';
import LandingPage from './page/landingPage';
import ApiDashboardPage from './page/apiDashboard';
import { MapApp } from './component/layout/map/main';
import SuccesPayment from './page/payment/succes.payment';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GenerateSummaryPage from './component/menu/menu.generate.report';
import GenerateSummaryPageProfilling from './component/menu/menu.generate.report.profilling';


function App() {
  globalStyle()

 return (
    <>
    <Routes>
      <Route path="/interactive-map" element={<XMapLayout />} />
      <Route path="/map" element={<MapApp />} />
      <Route path="/dashboard" element={  <DashboardPage /> } />
      <Route path="/insight" element={<InsightPage />} />
      <Route path="/all-catalog" element={<CatalogPage />} />
      <Route path="/data" element={<DataPage />} />
      <Route path="/survey" element={<SurveyPage />} />
      <Route path="/others" element={<OthersPage />} />
      <Route path="/solution" element={<SolutionPage />} />
      <Route path="/" element={<LandingPage/>} />
      <Route path="/login" element={<Login/>} />
      <Route path="/landing" element={<LandingPage/>} />
      <Route path="/register" element={<RegistrationForm/>} />
      <Route path="/api-intergration" element={  <ApiDashboardPage /> } />
     <Route path="*" element={<Navigate to="/" />} />
     <Route path="/payment" element={<SuccesPayment/>}/>
     <Route path="/generate-report" element={<GenerateSummaryPage/>}/>
     <Route path="/generate-report-profilling" element={<GenerateSummaryPageProfilling/>}/>
     
    </Routes>
    </>
  );
}

export default App;
