import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import {  Modal, Divider } from 'antd';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import icBySelf from '../../../asesst/Icons/interactivemap/icon/lucide_settings-2.svg'
import icPreset from '../../../asesst/Icons/interactivemap/icon/icon-park-outline_list.svg'
import ModalCustomProfilingToScoring from './formModalCustomProfilingToScoring';

export const ModalSelectTypeProfileToScore: React.FC<any> = ({ isSelectTypeProfileToScore, data }) => {
    const [selectedCard, setSelectedCard] = useState('setData');
    const {
        setShowModalSelectTypeProfileToScore,
        setShowModalShowCustomSCroring,
        showModalCustomScoring
    } = useReduxCatalog();

    const handleCancel = () => {
        setShowModalSelectTypeProfileToScore(false);

    };

    const handleCardClick = (card: any) => {
        setSelectedCard(card);
    };

    const handleChooseType = () => {
        setShowModalShowCustomSCroring(true);
        setShowModalSelectTypeProfileToScore(false);
    };
    // console.log(totalPrice);
    const isDisabled = true; // Ubah ini ke false jika ingin mengaktifkan kembali
    return (
        <>
            <Modal
                onCancel={handleCancel}
                styles={{
                    content: {
                        backgroundColor: '#333939',
                    },
                    body: {
                        backgroundColor: '#333939',
                        color: 'white'
                    },
                }}
                open={isSelectTypeProfileToScore}
                width={400}
                footer={false}
                style={{ top: '3%' }}
                centered={true} // ✅ Menjadikan modal di tengah
                cancelText="Cancel"
                closeIcon={<CloseOutlined style={{ color: 'white' }} />} // Set the close icon color to white
            >
                <div
                    style={{
                        backgroundColor: 'transparent',
                        width: '350px',
                        textAlign: 'left',
                        color: '#fff',
                    }}
                >

                    <h2 style={{ fontSize: '15px' }}>Generate Scoring</h2>


                    <div
                        style={{
                            fontSize: '14px',
                            textAlign: 'left',
                            color: '#ddd',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                        }}
                    >
                        <Divider
                            style={{
                                backgroundColor: "#7D8587",
                                fontWeight: "bold",
                                marginBottom: '5px',
                                color: "#7D8587",
                            }} />
                        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                            {/* Card 1 */}
                            <div
                                onClick={() => handleCardClick("setData")}
                                // onClick={!isDisabled ? () => handleCardClick("setData") : undefined} // Disable klik jika `isDisabled`
                                style={{
                                    width: "300px",
                                    height: "180px",
                                    borderRadius: "10px",
                                    padding: "10px",
                                    textAlign: "center",
                                    border: selectedCard === "setData" ? "2px solid #4A7FC0" : "2px solid #7D8587",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor:  "#333",
                                    color: isDisabled ? "#aaa" : "#fff",
                                    opacity:  1, // Efek transparan jika disable
                                }}
                            >
                                <img
                                    src={icBySelf} // Ganti URL ini dengan path ke ikon Anda
                                    alt="icon" />
                                <p style={{ margin: "5px 0", fontSize: "12px", fontWeight: "bold" }}>Set Data Byself</p>
                                <p style={{ fontSize: "10px" }}>Arrange the data you need yourself</p>
                            </div>

                            {/* Card 2 */}
                            <div
                                // onClick={() => handleCardClick("presetData")}
                                onClick={!isDisabled ? () => handleCardClick("presetData") : undefined} 
                                style={{
                                    width: "300px",
                                    height: "180px",
                                    borderRadius: "10px",
                                    padding: "10px",
                                    textAlign: "center",
                                    cursor: isDisabled ? "not-allowed" : "pointer",
                                    border: selectedCard === "presetData" ? "2px solid #4A7FC0" : "2px solid #7D8587",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: isDisabled ? "#555" : "#333",
                                    color: "#fff",
                                    opacity: isDisabled ? 0.6 : 1, // Efek transparan jika disable
                                }}
                            >
                                <img
                                    src={icPreset} // Ganti URL ini dengan path ke ikon Anda
                                    alt="icon" />
                                <p style={{ margin: "5px 0", fontSize: "12px", fontWeight: "bold" }}>Data Pre-Set</p>
                                <p style={{ fontSize: "10px" }}>No need to be confused, we have prepared the formula</p>
                            </div>
                        </div>


                        <Divider
                            style={{
                                backgroundColor: "#7D8587",
                                fontWeight: "bold",
                                marginTop: '5px',
                                color: "#7D8587",
                            }} />

                    </div>

                    <div
                        style={{
                            display: 'flex',
                            gap: '10px',
                            justifyContent: 'space-between',
                        }}
                    >
                        <button
                            //  disabled={dataUser?.balance < amount} 
                            onClick={handleChooseType}
                            style={{
                                backgroundColor: '#4A7FC0',
                                color: '#fff',
                                width: '100%',
                                fontWeight: 400,
                                padding: '10px 16px',
                                borderRadius: '8px',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            Choose
                        </button>
                    </div>
                </div>
            </Modal>
            <ModalCustomProfilingToScoring visible={showModalCustomScoring} />
        </>
    )
}