import React, { useEffect, useRef, useState } from 'react';
import { notification, Tooltip } from 'antd';
// import { formatNumberReachKm2, formatNumberWithDots } from '../../../component/newmap/helper/formatNumber';

import icDownload from '../../../asesst/new/icon/download.svg';
import icData from '../../../asesst/new/icon/Data.svg';
import icDownloadBlue from '../../../asesst/Icons/interactivemap/icon/dowloadn-blue.svg'
// import icTotal from '../../../asesst/Icons/interactivemap/icon/ic-sum-total.svg'
import icavg from '../../../asesst/Icons/interactivemap/icon/ic-sum-avg.svg'
import icmin from '../../../asesst/Icons/interactivemap/icon/ic-sum-min.svg'
import icmed from '../../../asesst/Icons/interactivemap/icon/ic-sum-med.svg'
import icmax from '../../../asesst/Icons/interactivemap/icon/ic-sum-max.svg'
import icbar from '../../../asesst/Icons/interactivemap/icon/ic-sum-bar.svg'
import icinverse from '../../../asesst/Icons/interactivemap/icon/inverse.svg'
import icColor from '../../../asesst/Icons/interactivemap/icon/ic_outline-color-lens.svg'
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import { FiEyeOff, FiEye } from "react-icons/fi";

import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip as ChartTooltip, // Ubah nama Tooltip dari chart.js
    Legend,
} from 'chart.js';
import GridParser from '../../../component/newmap/helper/map/geojson/grid';
import { MenuCardDownloadData } from '../../../component/menu/menu.card.download.data';
// import { useNavigate } from 'react-router-dom';
import { setLayerOpacity } from '../../../component/newmap/helper/map/layer.opacity';
import { DrawerGroupColor, gradientColorsParams } from './drawer.group.color';
import { clearPolygonLayer, removeIsochronesAndPopups, removeLayerTop5, removeLayersAndSources } from '../../../component/newmap/helper/map/sourceLayer';
import { getColorFromInterpolation } from '../../../component/newmap/helper/coloring/color.helper';

// import { setPopUpFilter } from '../../../hoc/store/catalog.store';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ChartTooltip, Legend);
interface ResultCatalogProps {
    onBackClick: () => void;
    map: maplibregl.Map | null;
    draw: MapboxDraw
}

export const DrawerSeeDataScoring: React.FC<ResultCatalogProps> = ({ onBackClick, map,draw }) => {
    const {
        setPopUpFilter,
        setShowCardDownload,
        setShowCardChangeColor,
        setColorGroup,
        showCardDownload,
        showCardChageColor,
        metaData,
        dataLayerGroup,
        popUpFilter,
        colorGroup,
    } = useReduxCatalog();
    
    const handleBackResult= () => {
        if(map) {
          clearPolygonLayer(map, draw); // Remove the polygon layer if it exists
          removeIsochronesAndPopups(map);
          removeLayersAndSources(map);
          removeLayerTop5(map)
        }
       
        onBackClick()
      };
    const [currentColors,] = useState({
        type: 'gradient',
        color: gradientColorsParams, // Default gradient colors
    });
    // console.log(metaData?.name);
    const [visibility, setVisibility] = useState<Record<string, boolean>>({});
    const [, setVisibleLayers] = useState<string[]>([]);
    const [opacity] = useState<any>(0.8); // Default opacity set to 80%
    // const [legendVisibility, setLegendVisibility] = useState<Record<string, boolean>>({});
    const grid_lib = new GridParser();
    const filteredData = dataLayerGroup?.filter(item => popUpFilter.includes(item.columnName));
    // console.log(listMeta);
    const histogramData = filteredData?.[0]?.summary?.histogram;
    const topo5 = filteredData?.[0]?.summary?.top5;
    const ranges = histogramData?.map((item: { range: any; }) => item.range);
    const values = histogramData?.map((item: { value: any; }) => item.value);
    const avg = filteredData?.[0]?.summary?.avg_formated ?? 0; // Default 0 jika undefined
    const max = filteredData?.[0]?.summary?.max_formated ?? 0;
    const min = filteredData?.[0]?.summary?.min_formated ?? 0;
    const median = filteredData?.[0]?.summary?.median_formated ?? 0;
    // const summaryAll = filteredData?.[0]?.summaryall ?? 0;
    // console.log(dataLayerGroup);
// console.log(filteredData);

    // trafic
    const rangeColor = filteredData?.[0]?.bins ?? []; // Default array kosong jika undefined
    const binsRangeColor = Array.from(new Set<number>(rangeColor)).sort((a, b) => a - b)
    if (!binsRangeColor || binsRangeColor.length === 0) {
        // console.error("Bins range is empty or undefined.");
    }


    // Konversi data ke GeoJSON
    const convertTogeojson = topo5?.map((item: { gid: string; value: number }) => {
        try {
            // Validasi data sebelum interpolasi
            if (typeof item?.value !== "number" || !item?.gid) {
                // console.error(`Invalid data for item:`, item);
                return null; // Abaikan item yang tidak valid
            }
            const colorStops = getColorFromInterpolation(item?.value, binsRangeColor, colorGroup?.color || [])
            return {
                type: "Feature",
                properties: {
                    ...item,
                    color: colorStops,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [grid_lib._gid_to_geometry(item?.gid)], // Gunakan transformasi geometris yang sesuai
                },
            };
        } catch (error) {
            // console.error(`Failed to process item:`, item, error);
            return null; // Abaikan item yang gagal diproses
        }
    }).filter(Boolean); // Hapus elemen `null`  

    const distributionData = {
        labels: ranges,
        datasets: [
            {
                label: 'Total',
                data: values,
                backgroundColor: colorGroup?.color,
                borderRadius: 4,
            },
        ],
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 10,
                bottom: 10, // Atur padding agar label sumbu-X tidak terlalu jauh
            },
        },
        plugins: {
            legend: {
                display: false, // Sembunyikan legenda
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#FFFFFF', // Warna label x-axis
                    // padding: 2, // Atur jarak label dari sumbu-X
                    font: {
                        size: 7, // Ubah ukuran font label y-axis

                    },
                },
            },
            y: {
                ticks: {
                    color: '#FFFFFF', // Warna label y-axis
                    font: {
                        size: 10, // Ubah ukuran font label y-axis
                    },
                },
            },
        },
    };

    const visibleLayersRef = useRef<string[]>([]); // Untuk menyimpan visibleLayers terbaru
    const visibilityRef = useRef<Record<string, boolean>>({}); // Untuk menyimpan visibility state terbaru

    useEffect(() => {
        // Menyimpan visibility terbaru ketika terjadi perubahan
        const initialVisibility: Record<string, boolean> = {};
        const initialLegendVisibility: Record<string, boolean> = {};
        let defaultLayer: string | null = null;

        if (map) {
            dataLayerGroup.forEach((layer) => {
                const isDefault = layer.columnName === 'score';
                initialVisibility[layer.columnName] = visibilityRef.current[layer.columnName] ?? isDefault; // Gunakan nilai dari ref atau default
                initialLegendVisibility[layer.columnName] = false;

                // Set opacity ke 1 untuk layer pertama, 0 untuk lainnya
                setLayerOpacity(map, 'layer', layer.columnName, isDefault ? 0.8 : 0);

                if (isDefault) {
                    defaultLayer = layer.columnName;
                }
            });
        }

        // Set visibility state hanya jika ada perubahan
        if (JSON.stringify(initialVisibility) !== JSON.stringify(visibility)) {
            setVisibility(initialVisibility);
        }

        // Update visibleLayers jika belum ada di ref atau jika berbeda dengan yang lama
        if (visibleLayersRef.current.length === 0 && defaultLayer) {
            visibleLayersRef.current = [defaultLayer];
            setVisibleLayers([defaultLayer]);
        }
        // eslint-disable-next-line 
    }, [dataLayerGroup, map]);

    const toggleVisibility = (item: any) => {
        const newVisibility: Record<string, boolean> = {};

        if (map) {
            dataLayerGroup.forEach((layer) => {
                newVisibility[layer.columnName] = layer.columnName === item.columnName;
                // Atur opacity: layer aktif -> 0.8, lainnya -> 0
                setLayerOpacity(map, 'layer', layer.columnName, layer.columnName === item.columnName ? opacity : 0);
            });
        }

        // Update visibility state
        visibilityRef.current = newVisibility; // Update ref dengan visibility terbaru
        setVisibility(newVisibility);

        // Update visibleLayers
        visibleLayersRef.current = [item.columnName]; // Update ref dengan visible layer terbaru
        setVisibleLayers([item.columnName]);
    };

    useEffect(() => {
        setPopUpFilter(visibleLayersRef.current);
        setColorGroup(currentColors)
        // eslint-disable-next-line 
    }, [visibleLayersRef.current, currentColors]);

    const handleCardChangeColorClick = () => {
        setShowCardDownload(false);
        setShowCardChangeColor(true)
    };

    const handleCardDownloadclick = () => {
        setShowCardDownload(true);
        setShowCardChangeColor(false)
      };


    const handleFlyTo = (item: any) => {
        if (map) {
            // Ambil koordinat dari GeoJSON (ambil titik pertama dalam polygon)
            const coordinates = item.geometry.coordinates[0][0]; // Koordinat pertama dalam polygon

            if (coordinates) {
                // Tambahkan layer dengan warna pada grid target
                const sourceId = 'highlighted-grid-source';
                const layerId = 'highlighted-grid-layer';

                // Periksa apakah source/layer sudah ada, jika ya, hapus
                if (map.getSource(sourceId)) {
                    map.removeLayer(`${layerId}-outline`);
                    map.removeLayer(layerId);
                    map.removeSource(sourceId);
                }
                // Tambahkan source baru untuk grid
                map.addSource(sourceId, {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [item], // Gunakan fitur GeoJSON yang diterima
                    },
                });

                // Tambahkan layer baru dengan warna fill
                map.addLayer({
                    id: layerId,
                    type: 'fill',
                    source: sourceId,
                    paint: {
                        'fill-color': 'transparent',
                        'fill-opacity': 1, // Transparansi
                        'fill-outline-color': 'black',
                    },
                });

                map.addLayer({
                    id: `${layerId}-outline`, // ID unik untuk outline
                    type: 'line',
                    source: sourceId,
                    paint: {
                        'line-color': 'black', // Warna garis tepi
                        'line-width': 2, // Ketebalan garis tepi
                    },
                });

                // Pindahkan tampilan peta ke grid yang dituju
                map.flyTo({
                    center: coordinates, // Format [longitude, latitude]
                    zoom: 20,
                    speed: 1.2,
                    curve: 1, // Smooth fly effect
                });
            } else {
                notification.warning({
                    message: 'Invalid Coordinates',
                    description: 'Unable to extract coordinates from the selected feature.',
                });
            }
        } else {
            notification.warning({
                message: 'Map instance is not available',
                description: 'Ensure the map is loaded before using this feature.',
            });
        }
    };
    return (
        <React.Fragment>
            {/* Back Arrow */}
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '20px',
                cursor: 'pointer'
            }}>
                {/* Kiri: Arrow dan Result Location */}
                <span onClick={handleBackResult} style={{ fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                ← Result Location
                </span>

                {/* Kanan: Icon Download & Change Color */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Tooltip
                    placement="bottom"
                    overlayInnerStyle={{ backgroundColor: '#343A3A', color: 'white' }} // Ganti warna bg dan teks
                    title={
                    <div style={{ textAlign: 'left' }}>
                        <strong style={{ display: 'block', marginBottom: '3px' }}>Change Color</strong>
                    
                    </div>
                    }
                >
                    <img
                    src={icColor}
                    alt="Download"
                    style={{ width: '25px', height: '25px', cursor: 'pointer' }}
                    onClick={handleCardChangeColorClick}
                    />
                </Tooltip>
                <Tooltip
                    placement="bottom"
                    overlayInnerStyle={{ backgroundColor: '#343A3A', color: 'white' }} // Ganti warna bg dan teks
                    title={
                    <div style={{ textAlign: 'left' }}>
                        <strong style={{ display: 'block', marginBottom: '3px' }}>Download Data</strong>
                    
                    </div>
                    }
                > 
                <img
                src={icDownload}
                alt="Change Color"
                style={{ width: '25px', height: '25px', cursor: 'pointer' }}
                onClick={handleCardDownloadclick}
                />
                </Tooltip>
                
                </div>
            </div>
            <div style={{
                overflowY: 'auto',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
            }}>
                <div
                    style={{
                        alignItems: 'center',
                        background: '#141617',
                        padding: '5px',
                        borderRadius: '12px',
                        marginBottom: '5px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        border: '1px solid black', // Set border color to white
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        cursor: 'pointer',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for
                    }}
                >
                    <div style={{ width: '166px' }}>
                        <h4 style={{ marginBottom: '10px', fontSize: '14px' }}>{metaData?.alias}</h4>
                    </div>
                    <div>
                        <p style={{ fontSize: '12px' }}> {metaData?.description}</p>
                    </div>
                </div>
                <div
                    style={{
                        alignItems: 'center',
                        background: '#141617',
                        padding: '5px',
                        borderRadius: '12px',
                        marginBottom: '5px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        border: '1px solid black', // Set border color to white
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        cursor: 'pointer',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <img alt="db icon" src={icavg} style={{ marginRight: '5px' }} />

                                    <h3 style={{ fontSize: '12px', margin: 0 }}> Personalized Score</h3>
                                </div>
                    {dataLayerGroup
                        ?.filter(layer => layer.categoryName === 'Scoring')?.map((layer, index) => (
                            <div key={index} style={{
                                borderRadius: '8px'
                            }}>
                                
                                <div

                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: '8px',
                                    }}
                                >
                                    {/* <div> */}
                                        <div>
                                            <h4 style={{
                                                color: 'white', fontSize: '14px'
                                            }}>{metaData?.name}</h4>
                                            <span style={{ color: 'white', fontSize: '12px' }}>{layer?.categoryName}</span>
                                        </div>
                                    {/* </div> */}
                                    <div
                                        onClick={() => toggleVisibility(layer)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {visibility[layer.columnName] ? <FiEye size={17} /> : <FiEyeOff size={17} />}
                                    </div>
                                </div>

                            </div>
                        ))}
                </div>
                <div
                    style={{
                        alignItems: 'center',
                        background: '#141617',
                        padding: '5px',
                        borderRadius: '12px',
                        marginBottom: '5px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        border: '1px solid black', // Set border color to white
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        cursor: 'pointer',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for
                    }}
                >

                    <div style={{
                        borderRadius: '8px',
                        maxHeight: '250px',
                        overflowY: 'auto',
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img alt="db icon" src={icData} style={{ marginRight: '5px' }} />
                            <h3 style={{ fontSize: '12px', margin: 0 }}> Data Score</h3>
                        </div>
                        {dataLayerGroup
                            ?.filter(layer => layer.categoryName === 'Scoring') // Filter hanya categoryName: Scoring
                            .map(layer => (
                                layer.weight?.map((weightItem, index) => (
                                    <div key={index} style={{ marginBottom: '10px', marginTop: '10px' }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginBottom: '8px',
                                            }}
                                        >
                                            <div>


                                                <div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <h4 style={{ color: 'white', fontSize: '14px', margin: 0 }}>
                                                            {weightItem.subdataName || weightItem.productName}
                                                            </h4>
                                                            {/* Jika isInverse === true, tampilkan ikon */}
                                                            {weightItem?.isInverse && (
                                                                <Tooltip 
                                                                    placement="right"
                                                                    zIndex={9999}
                                                                    overlayInnerStyle={{ backgroundColor: '#343A3A', color: 'white' }} // Ganti warna bg dan teks
                                                                     title={
                                                                         <div style={{ textAlign: 'left' }}>
                                                                         <strong style={{ display: 'block', marginBottom: '3px' }}>Inversion</strong>
                                                                         <span>Lower values men better scores</span>
                                                                         </div>
                                                                     }
                                                                 >
                                                            <img
                                                                src={icinverse} // Ganti dengan path ke ikon
                                                                alt="icon"
                                                                style={{ width: '15px', height: '15px', marginLeft: '5px' }}
                                                            />
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    <span style={{ color: 'white', fontSize: '12px' }}>{weightItem.productName || weightItem.categoryName}</span>
                                                </div>

                                            </div>
                                            <div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        gap: '10px',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <h4 style={{ color: 'white', fontSize: '12px' }}>{weightItem.weight}%</h4>

                                                    <div>
                                                        <div onClick={() => toggleVisibility(weightItem)} style={{ cursor: 'pointer' }}>
                                                            {visibility[weightItem.columnName] ? <FiEye size={17} /> : <FiEyeOff size={17} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ))}
                    </div>
                </div>
            

             


                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '10px'

                }}>
                    <h3 style={{ margin: 0 }}>Summary</h3>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img alt="download icon" src={icDownloadBlue} style={{
                            marginRight: '5px',
                            width: '25px', // Sesuaikan ukuran lebar
                            height: '25px', // Sesuaikan ukuran tinggi
                        }} />
                        <p
                            style={{
                                color: '#4A7FC0',
                                textDecoration: 'none',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }}
                        // onClick={goToGenerateSummaryPage} // Navigasi dengan state
                        >
                            Generate Summary
                        </p>
                    </div>
                </div>
                <div style={{
                    padding: '10px',
                    background: '#141617',
                    borderRadius: '8px'
                }}>


                    <div
                        style={{
                            background: '#141617',
                            padding: '10px',
                            borderRadius: '8px',
                            border: '2px solid #3B669A', // Menambahkan border biru
                            textAlign: 'left',
                            marginBottom: '5px'

                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                            {/* Ikon di sebelah kiri */}
                            <img
                                src={icavg} // Ganti URL ini dengan path ke ikon Anda
                                alt="icon"
                            // style={{ width: '16px', height: '16px', marginRight: '8px' }}
                            />
                            <h3 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Average per Square</h3>
                        </div>
                        {dataLayerGroup.length < 1 ? (
                            <p>loading...</p>
                        ) : (
                            <h3 style={{ margin: 0 }}>
                                {avg}
                            </h3>
                        )}
                    </div>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr', // 3 kolom
                            gap: '5px',
                            maxWidth: '320px', // Batasi lebar maksimum container grid
                            margin: '0 auto', // Pusatkan grid secara horizontal
                        }}
                    >
                        <div
                            style={{
                                background: '#141617',
                                padding: '10px',
                                border: '2px solid #3B669A', // Menambahkan border biru
                                borderRadius: '8px',
                                maxWidth: '100px',
                                textAlign: 'left',
                            }}
                        >

                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                {/* Ikon di sebelah kiri */}
                                <img
                                    src={icmin} // Ganti URL ini dengan path ke ikon Anda
                                    alt="icon"
                                // style={{ width: '16px', height: '16px', marginRight: '8px' }}
                                />
                                <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Min</h4>
                            </div>
                            {dataLayerGroup.length < 1 ? (
                                <p>loading...</p>
                            ) : (
                                <h3 style={{ margin: 0 }}>
                                    {min}
                                </h3>
                            )}
                        </div>

                        <div
                            style={{
                                background: '#141617',
                                padding: '10px',
                                maxWidth: '100px',
                                border: '2px solid #3B669A', // Menambahkan border biru
                                borderRadius: '8px',
                                textAlign: 'left',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                {/* Ikon di sebelah kiri */}
                                <img
                                    src={icmed} // Ganti URL ini dengan path ke ikon Anda
                                    alt="icon"
                                // style={{ width: '16px', height: '16px', marginRight: '8px' }}
                                />
                                <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Med</h4>
                            </div>
                            {dataLayerGroup.length < 1 ? (
                                <p>loading...</p>
                            ) : (
                                <h3 style={{ margin: 0 }}>
                                    {median}
                                </h3>
                            )}
                        </div>
                        <div
                            style={{
                                background: '#141617',
                                padding: '10px',
                                border: '2px solid #3B669A', // Menambahkan border biru
                                borderRadius: '8px',
                                maxWidth: '100px',
                                textAlign: 'left',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                {/* Ikon di sebelah kiri */}
                                <img
                                    src={icmax} // Ganti URL ini dengan path ke ikon Anda
                                    alt="icon"
                                // style={{ width: '16px', height: '16px', marginRight: '8px' }}
                                />
                                <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Max</h4>
                            </div>
                            {dataLayerGroup.length < 1 ? (
                                <p>loading...</p>
                            ) : (
                                <h3 style={{ margin: 0 }}>
                                    {max}
                                </h3>
                            )}
                        </div>


                    </div>
                    <div
                        style={{
                            background: '#141617',
                            borderRadius: '8px',
                            padding: '15px',
                            marginTop: '20px',
                            border: '2px solid #3B669A', // Menambahkan border biru
                            height: '300px', // Tinggi khusus untuk Chart.js
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                            {/* Ikon di sebelah kiri */}
                            <img
                                src={icbar} // Ganti URL ini dengan path ke ikon Anda
                                alt="icon"
                            // style={{ width: '16px', height: '16px', marginRight: '8px' }}
                            />
                            <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Distribution</h4>
                        </div>
                        {dataLayerGroup.length < 1 ? (
                            <p>loading...</p>
                        ) : (
                            <Bar data={distributionData} options={options} />
                        )}
                    </div>
                    <div
                        style={{
                            background: '#141617',
                            borderRadius: '8px',
                            padding: '15px',
                            border: '2px solid #3B669A', // Menambahkan border biru
                            marginTop: '20px',
                        }}
                    >

                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            {/* Ikon di sebelah kiri */}
                            <img
                                src={icbar} // Ganti URL ini dengan path ke ikon Anda
                                alt="icon"
                            // style={{ width: '16px', height: '16px', marginRight: '8px' }}
                            />
                            <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Top 5 squares with highest number</h4>
                        </div>
                        {convertTogeojson?.map((number: any, index: any) => (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '10px',
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <div
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '20%',
                                            backgroundColor: number?.properties?.color,
                                        }}
                                    >

                                    </div>
                         
                                        {number?.properties?.formated}
                                </div>
                                <p
                                    style={{ color: '#4A7FC0', fontSize: '14px', cursor: 'pointer' }}
                                    onClick={() => handleFlyTo(number)}
                                >
                                    View
                                </p>
                            </div>
                        ))}
                    </div>

                </div>
                {showCardDownload && <MenuCardDownloadData />}
                {showCardChageColor && <DrawerGroupColor />}
            </div>

        </React.Fragment>
    );
};
