import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { simplify } from '@turf/turf';
import iconPolygon from '../../../asesst/Icons/interactivemap/icon/polygon.svg';
import iconPoint from '../../../asesst/Icons/interactivemap/icon/point.svg';
import { renderGeocodeResultToMap, GeoJSONPolygon, GeoJSONFeature, GeoJSONPoint } from '../../../component/newmap/helper/map/geocode';
import { GeocodingControl } from '@maptiler/geocoding-control/react';
// import { Feature, Geometry } from 'geojson';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { handleDrawClick } from '../../../component/newmap/helper/map/draw.polygon';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import { handleReverseGeocode } from '../../../component/newmap/helper/map/reversecode';
import { ResultCatalog } from './drawer.result.catalog';
import { enablePointCreation, removeCurrentMarker } from '../../../component/newmap/helper/map/draw.point';
import { addIsochroneLayer, removePopupIsochrones } from '../../../component/newmap/helper/map/isochrones';
import { clearPolygonLayer, removeGeocodeLayersAndPopups, removeIsochronesAndPopups, removeLayerTop5, removeLayersAndSources } from '../../../component/newmap/helper/map/sourceLayer';

interface DrawerMenuCatalogProps {
    map: maplibregl.Map | null; // Pass the map instance as a prop
    draw: MapboxDraw
}
export const DrawerMenuCatalog: React.FC<DrawerMenuCatalogProps> = ({ map, draw }) => {
    const {
        setCatalogGeometry,
        setCatalogLocation,
        setIsDrawActive,
        setDrawnPolygon,
        setIsochronest,
        setShowCardCatalog,
        setShowSideMenuCustomScoring,
        drawnPolygon,
        isochrones,
        isDrawActive,
        paramsCatalogGeometry,
    } = useReduxCatalog();
    // const [draw] = useState<MapboxDraw>(initializeMapboxDraw);
    const [isPointActive, setIsPointActive] = useState(false);
    // console.log(drawnPolygon);

    const handleGeocodingSelect = async (result: any | undefined) => {
        if (result && map) {
            localStorage.removeItem('selectedLocation'); // Replace 'yourKeyHere' with the actual key
            clearPolygonLayer(map, draw); // Remove the polygon layer if it exists
            // removeIsochronesAndPopups(map);
            removeLayersAndSources(map);
            removeCurrentMarker();
            setShowCardCatalog(false)
            setShowSideMenuCustomScoring(false);
            removeLayerTop5(map)
            setDrawnPolygon('')
            setIsPointActive(false);
            if (result.geometry.type === "Point") {
                if (result.place_type && result.place_type.includes("address")) {
                    // console.log("Processing Point (Address):", result);
                    const geojson: GeoJSONFeature = {
                        type: 'Feature',
                        geometry: result.geometry as GeoJSONPoint,
                        properties: {},
                    };

                    
                    // Render the simplified geometry on the map
                    const latlng = {
                        lat: geojson.geometry.coordinates[1],
                        lng: geojson.geometry.coordinates[0]
                    }
                    // setCatalogGeometry(simplified.geometry);
                    try {
                        const fetchedIsochrones = await setIsochronest(latlng);
                        if (fetchedIsochrones?.features) {
                            // Filter berdasarkan location_type == 'outbound'
                            const outboundFeatures = fetchedIsochrones.features.filter(
                                (feature: any) => feature?.properties?.location_type === 'outbound'
                            );

                            const locationCode = await handleReverseGeocode(outboundFeatures?.[0]?.geometry);
                            setCatalogLocation(locationCode);

                            renderGeocodeResultToMap(map, geojson);
                            await addIsochroneLayer(map, fetchedIsochrones); // Render the isochrone laye
                        }
                        // const locationCode = await handleReverseGeocode(geojson.geometry);
                        // setCatalogLocation(locationCode);
                        // const fetchedIsochrones = await setIsochronest(latlng)
                        // renderGeocodeResultToMap(map, geojson);
                        //  await addIsochroneLayer(map, fetchedIsochrones); // Render the isochrone layer
                    } catch (error) {
                        console.error('Error fetching address:', error);
                    }
                } 
            } else if (result.geometry.type === "Polygon") {
                // console.log("Processing Polygon:", result);
                //    Simplify the polygon geometry using Turf.js before setting it
                const simplifiedGeometry = simplify(result.geometry as GeoJSONPolygon, {
                    tolerance: 0.001, // Nilai lebih kecil untuk perubahan minimal
                    highQuality: false, // Menjaga kualitas hasil simplifikasi
                });

                const geojson: GeoJSONFeature = {
                    type: 'Feature',
                    geometry: result.geometry as GeoJSONPolygon,
                    properties: {},
                };
                const simplified: GeoJSONFeature = {
                    type: 'Feature',
                    geometry: simplifiedGeometry,
                    properties: {},
                };

                // Render the simplified geometry on the map
                renderGeocodeResultToMap(map, geojson);
                setCatalogGeometry(simplified.geometry);
                setIsochronest(null)
                removeLayerTop5(map)
                removeIsochronesAndPopups(map);
                try {
                    const locationCode = await handleReverseGeocode(geojson.geometry);
                    setCatalogLocation(locationCode);
                } catch (error) {
                    console.error('Error fetching address:', error);
                }
            }
        }
    };

    const handleDrawPolygon = () => {
        handleDrawClick(map, draw);
        if (map && draw) {
            map.on('draw.create', handleDrawCreate);
            // removeGeocodeLayersAndPopups(map);
            setIsDrawActive(true);
            localStorage.removeItem('selectedLocation'); // Replace 'yourKeyHere' with the actual key
            // removeIsochronesAndPopups(map);
        }
    };

    const handleDrawCreate = async (event: any) => {
        const polygon = event.features[0].geometry;
        setDrawnPolygon({
            type: 'Feature',
            geometry: polygon,
            properties: {},
        });
        // Remove geocoded search layer if it exists
        setIsDrawActive(false);
        setCatalogGeometry(polygon);
        removePopupIsochrones()
        try {
            const locationCode = await handleReverseGeocode(polygon);
            setCatalogLocation(locationCode);
        } catch (error) {
            console.error('Error fetching address:', error);
        }
    };

    const handlePointButtonClick = () => {
        localStorage.removeItem('selectedLocation'); // Replace 'yourKeyHere' with the actual key
        if (!map) return;
        if (isPointActive) {
            // Deactivate point mode and remove marker
            // removeCurrentMarker();
            setIsPointActive(false);
            removeIsochronesAndPopups(map)
        } else {
            setIsPointActive(true);
            // setIsDrawActive(false);
            removeGeocodeLayersAndPopups(map);
            removeLayerTop5(map)
            // clearPolygonLayer(map,draw);
            setShowCardCatalog(false)
            setShowSideMenuCustomScoring(false);
            removeLayersAndSources(map);
        }
    };

    const handlePointsCallback = async (lngLat: maplibregl.LngLat) => {
        const fetchedIsochrones = await setIsochronest(lngLat)
        if (fetchedIsochrones?.features) {
            const outboundFeatures = fetchedIsochrones.features.filter(
                (feature: any) => feature?.properties?.location_type === 'outbound'
                
            );
            const locationCode = await handleReverseGeocode(outboundFeatures?.[0]?.geometry);
            setCatalogLocation(locationCode);
        }
        // console.log(fetchedIsochrones);
        
    };

    useEffect(() => {
        if (map && isPointActive) {
            setDrawnPolygon('')
            enablePointCreation(map, isPointActive, 'point-1', handlePointsCallback);  //
            addIsochroneLayer(map, isochrones)
        }
        if (map && !isPointActive) {
            setIsochronest(null)
            enablePointCreation(map, isPointActive, 'point-1', handlePointsCallback);  //
        }

        if (isochrones) {
            setCatalogGeometry(isochrones?.features?.[0]?.geometry);
        }
        if (map && !!drawnPolygon) {
            // removePopupIsochrones()
            removeGeocodeLayersAndPopups(map);
            removeLayerTop5(map)
            // removeCurrentMarker();
            // removeIsochronesAndPopups(map);
            removeLayersAndSources(map);
            // setIsPointActive(false);
            setShowCardCatalog(false);
            setShowSideMenuCustomScoring(false);
        }
        

        // eslint-disable-next-line
    }, [map, isPointActive, isochrones, drawnPolygon]);


    useEffect(() => {
        if (map && draw) {
            // Add event listeners for create and update events
            map.on('draw.create', handleDrawCreate);
            map.on('draw.update', handleDrawCreate);
            // Cleanup listeners on unmount
            return () => {
                map.off('draw.create', handleDrawCreate);
                map.off('draw.update', handleDrawCreate);
            };
        }
        // eslint-disable-next-line
    }, [map, draw]);


    return (
        <React.Fragment>
            <h2 style={{ color: 'white' }}>Find Insight</h2>
            <div style={{ marginBottom: '10px' }}>
                <h4 style={{ color: 'white', marginTop: '20px' }}>Select Location</h4>
                <p style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.65)', marginTop: '10px', marginBottom: '10px' }}>
                    Choose an area by searching, dropping a pin, or drawing a custom polygon.
                </p>
                <GeocodingControl
                    onPick={handleGeocodingSelect}
                    apiKey={'IkttUJmMfqWCx0g43vGM'}
                />
            </div>

            <div style={{ marginBottom: '20px' }}>
                {/* Conditionally render either Draw or Remove button */}
                <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '10px' }}>
                    <Button
                        type="primary"
                        icon={<img src={iconPoint} alt="custom polygon icon" />}
                        style={{
                            width: '150px',
                            height: '35px',
                            borderRadius: '8px',
                            backgroundColor: isPointActive ? 'red' : '#4A7FC0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '14px',
                            fontWeight: 700,
                        }}
                        onClick={handlePointButtonClick}
                    >
                        Point
                    </Button>
                    <Button
                        type="primary"
                        icon={<img src={iconPolygon} alt="custom polygon icon" />}
                        style={{
                            width: '150px',
                            height: '35px',
                            borderRadius: '8px',
                            backgroundColor: isDrawActive ? 'red' : '#4A7FC0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '14px',
                            fontWeight: 700,
                        }}
                        onClick={handleDrawPolygon}
                    >
                        Polygon
                    </Button>
                </div>
            </div>

            {/* <div style={{ marginBottom: '20px'}}> */}
            {paramsCatalogGeometry !== null ? (
                <ResultCatalog />
            ) : null}
            {/* </div> */}
        </React.Fragment>
    );
};
