import React, { useEffect, useState } from 'react';
import { Button,Empty } from 'antd';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import LoaderSideBar from '../../loader/loader.sidebar';
import { IoChevronForwardOutline } from "react-icons/io5";
import { CardMyData } from '../../../component/menu/menu.card.mydata';
import { auth } from '../../../firebase/firebaseConfig'; // Importing Firebase auth
import useReduxUser from '../../../hoc/hooks/useUser';
import ModalLogin from '../modal/modal-login';
import iconHeader from '../../../asesst/Icons/interactivemap/icon.svg';
import icHiresDemo from '../../../asesst/Icons/interactivemap/icon/categories2.svg';
import icPeopleTrafic from '../../../asesst/Icons/interactivemap/icon/categories3.svg';
import icReachability from '../../../asesst/Icons/interactivemap/icon/categories1.svg';

export const MyData: React.FC<any> = () => {
  const [activeTab, setActiveTab] = useState('Insight');
  const [param, setParam] = useState('insight');
  const [showCardMyData, setShowCardMyData] = useState(false);
  const user = auth.currentUser; // Get the current user state
  const { isLoginModalVisible, modalLoginOpen } = useReduxUser();
  const {
    setMyData,
    setDetailMyData,
    detailMydata,
    myData,
    isloading,
    setMetaData,
  } = useReduxCatalog();

  const handleTabClick = (params: string) => {
    setActiveTab(params);
    const paramCatalog = params.toLowerCase();
    setParam(paramCatalog);
  };

  const handleCardClick = (item: any) => {
    setDetailMyData(item);
    setShowCardMyData(true);
    setMetaData('');
  };

  const handleLoginModalOpen = () => {
    modalLoginOpen(true);
  };

  useEffect(() => {
    if (user) {
      // Memanggil setMyData saat komponen di-mount
      setMyData(param);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param, user]);

  // Icon mapping based on category
  const iconMapping = {
    hires_demography: icHiresDemo,
    reachability: icReachability,
    people_traffic: icPeopleTrafic,
  };

  const renderTabContent = () => {
    if (isloading && user) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <LoaderSideBar />
        </div>
      );
    }

    // If no user, display the login prompt
    if (!user) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', color: 'white' }}>
          <div style={{ textAlign: 'center', paddingTop: '50px' }}>
            <img src={iconHeader} alt="No message" style={{ width: '100px', height: '200px' }} />
            <p style={{ color: 'white', fontSize: '16px' }}>
              You need to be logged in to access this section.
            </p>
          </div>
          <button
            onClick={handleLoginModalOpen}
            style={{
              marginTop: '30px',
              backgroundColor: '#4A7FC0',
              color: '#fff',
              width: '100%',
              fontWeight: 400,
              padding: '10px 16px',
              borderRadius: '8px',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Go to Login
          </button>
        </div>
      );
    }

    const isDataNotFound = !myData?.data?.length;
    if (isDataNotFound) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <>
              <span style={{ color: 'white', fontWeight: 'bold', fontSize: '14px' }}>
                No data available
              </span>
              <br />
              <span style={{ color: 'white', fontSize: '12px' }}>
                Please check back later or explore other categories.
              </span>
            </>
          }
        />
      );
    }

    return myData?.data.map((item: any, index: any) => {
      // Get the corresponding icon for the category
      const iconSrc = iconMapping[item?.category as keyof typeof iconMapping] || iconHeader;

      return (
        <div
          onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
          onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
          key={index}
          onClick={() => handleCardClick(item?.category)}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: '#141617',
            padding: '5px',
            borderRadius: '12px',
            marginBottom: '10px',
            marginTop: '10px',
            paddingTop: '10px',
            paddingBottom: '10px',
            border: '1px solid black',
            paddingLeft: '10px',
            paddingRight: '10px',
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
        >
          <img
            alt="category-icon"
            src={iconSrc}
            style={{ marginRight: '10px', width: '40px', height: '40px' }} // Adjust the icon size as needed
          />
          <div style={{ width: "166px" }}>
            <h4 style={{ marginBottom: '10px' }}>{item?.category_alias}</h4>
            <p>{item?.count_product} Products</p>
          </div>
          <IoChevronForwardOutline size={30} style={{ color: '#fff' }} />
        </div>
        
      );
    });
  };

  return (
    <React.Fragment>
      {/* Back Arrow */}
      <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
        <h2 style={{ color: 'white' }}>My Data</h2>
      </div>

      {/* List Items */}
      <div style={{  display: 'flex',
       justifyContent: 'space-around',
       padding: '8px',
       backgroundColor: '#141617',
       borderRadius: '6px',
      }}>
        {['Insight', 'Data', 'Survey'].map((tab) => (
          <Button
            type="text"
            key={tab}
            id={tab}
            onClick={() => handleTabClick(tab)}
            style={{
              borderRadius: '6px',
              color: activeTab === tab ? '#ffff' : '#718096',
              fontWeight: activeTab === tab ? 'bold' : 'bold',
              padding: '0 10px',
              border: activeTab === tab ? '2px solid #333939' : '#333939',
            }}
          >
            {tab}
          </Button>
        ))}
      </div>
      <div style={{ overflowY: 'auto',  height: 'calc(100vh - 50px)' }}>
        {renderTabContent()}
      </div>
      {showCardMyData && <CardMyData data={detailMydata} />}
      <ModalLogin visible={isLoginModalVisible} />
    </React.Fragment>
  );
};
