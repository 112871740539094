import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin, notification, Select } from 'antd';
// import { EnvironmentOutlined, AimOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';
import icAreaSize from '../../../asesst/Icons/interactivemap/icon/scale-card.svg';
import icGridTotal from '../../../asesst/Icons/interactivemap/icon/grid-card.svg';
import icChart from '../../../asesst/Icons/interactivemap/icon/cart-add.svg';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
// import Text from '../../../style/styledComponent/Text';
import { LoadingOutlined } from '@ant-design/icons';
import {
    auth,
} from '../../../firebase/firebaseConfig';

import { formatNumberWithDots } from '../../../component/newmap/helper/formatNumber';
import ModalLogin from '../modal/modal-login';
import useReduxUser from '../../../hoc/hooks/useUser';
// import { LiaCoinsSolid } from "react-icons/lia";

import { BankOutlined, WalletOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
// import { ModalConfirmPayment } from './modal-confirm-payment'


export const ModalCheckoutScoring: React.FC<any> = ({ isModal, scoringData }) => {
    const {
        setShowModalCheckoutScoring,
        setShowSideMenuCustomScoring,
        setsetDataCustomScoring,
        setShowSideMenuCustomProfillingToScoring,
        setDoScoring,
        priceScoring,
        doScoring,
        loadingPriceScoring
    } = useReduxCatalog();
    
    // console.log(priceScoring?.price);
    const { isLoginModalVisible, modalLoginOpen, getDetailUser, dataUser } = useReduxUser();
    const [loading, setLoading] = useState(false);
    const [scoringDataSubmit, setScoringDataSubmit] = useState({
        name: scoringData?.name,
        useWallet: false,
        polygon_id: scoringData?.polygon_id,
        path: scoringData?.path,
        data: scoringData?.data
    });

    const user = auth.currentUser;

    const handleSubmit = async () => {
        const user = auth.currentUser;
        if (!user) {
            // Show notification if user is not authenticated
            notification.warning({
                style: {
                    backgroundColor: '#142943',
                    color: 'white',  // Make the text white
                },
                message: <p style={{ color: 'white', fontWeight: 'bold' }}>Authentication is required</p>,
                description: <p style={{ color: 'white', fontWeight: 'bold' }}>Please login first before proceeding to checkout.</p>,
                placement: 'topRight',
                closeIcon: <span style={{ color: 'white' }}>×</span>
            });
            modalLoginOpen(true);
            return; // Stop execution if the user is not logged in
        }

        setLoading(true); // Set loading to true
        try {
            const response =  await setDoScoring(scoringDataSubmit);
            if (!response) throw new Error("No response from server");
          
            if (response.statusCode === 201) {
                    handleCancel(); // Close the modal
                    setsetDataCustomScoring(null)
                    setShowSideMenuCustomScoring(false)
                    setShowSideMenuCustomProfillingToScoring(false)
                    notification.success({
                        style: {
                            backgroundColor: '#142943',
                            color: 'white'  // Menjadikan teks berwarna putih
                        },
                        message: <p style={{ color: 'white', fontWeight: 'bold' }}>Successful</p>,
                        description: <p style={{ color: 'white', fontWeight: 'bold' }}>Successfully created score, Please check your DATA for further actions.</p>,
                        placement: 'topRight',
                    });
                } else if (response.statusCode >= 500) {
                    handleCancel(); // Close the modal
                    notification.error({
                        style: {
                            backgroundColor: '#142943',
                            color: 'white',  // Make the text white
                        },
                        message: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed</p>,
                        description: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed to checkout data. Please try again</p>,
                        placement: 'topRight',
                        closeIcon: <span style={{ color: 'white' }}>×</span>
                    });
                } else if (response.data?.invoiceUrl) {
               
                    window.open(doScoring.data?.invoiceUrl, '_blank'); // Open invoice in a new tab
                    localStorage.setItem('invoiceId', doScoring?.data?.id); // Store invoice ID
                    window.dispatchEvent(new Event('storage')); // Memaksa event storage agar dipicu di halaman lain
                }
      
        } catch (error) {
            handleCancel(); // Close the modal
            console.error("Error during scoring:", error);
            notification.error({
                style: {
                    backgroundColor: '#142943',
                    color: 'white',  // Make the text white
                },
                message: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed</p>,
                description: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed to checkout data. Please try again</p>,
                placement: 'topRight',
                closeIcon: <span style={{ color: 'white' }}>×</span>
            });
        } finally {
            setLoading(false); // Set loading to false after the operation completes
            handleCancel(); // Close the modal
            // setOpenModalConfirmPayment(true); // Open payment confirmation modal
        }
    };


    const handleCancel = () => {
        setShowModalCheckoutScoring(false);
    };
    const fetchGetUser = async () => {
        if (!user) return; // Ensure user exists
        setLoading(true);
        try {
            const userId = user?.uid;
            await getDetailUser(userId);  // Fetch user details
        } catch (error) {
            console.error('Error fetching user details:', error);
        } finally {
            setLoading(false);  // Set loading to false after fetching
        }
    };

    useEffect(() => {
        if (user?.uid) {
            fetchGetUser();  // Fetch user details when user is available
        }
        // eslint-disable-next-line 
    }, [user?.uid]);  // Trigger effect when user changes

    const handleChangePaymentMethode = (value: any) => {
        setScoringDataSubmit({
            name: scoringData?.name,
            useWallet: value !== 'bank',
            polygon_id: scoringData?.polygon_id,
            path: scoringData?.path,
            data: scoringData?.data
        })

    };

    const totalPrices = priceScoring?.price
    const formattedTotalPrice = totalPrices?.toLocaleString('id-ID', {
        style: 'currency',
        currency: 'IDR',
    });


    return (
        <>
            <Modal
                styles={{
                    content: {
                        backgroundColor: '#333939'
                    },
                    body: {
                        backgroundColor: '#333939',
                        color: 'white',
                        height: 'calc(100vh - 50px)',
                    },
                }}
                open={isModal}
                width={480}
                footer={false}
                style={{ top: '0%' }}
                onCancel={handleCancel}
                okText="Buy Now"
                cancelText="Cancel"
                closeIcon={<CloseOutlined style={{ color: 'white' }} />} // Set the close icon color to white
            >

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ flex: 1 }}>
                        <span style={{
                            fontSize: '24px',
                            marginBottom: '5px',
                            textAlign: 'left',
                            display: 'block',
                            width: '100%',
                            fontWeight: 'bold'
                        }}>{scoringData?.title}</span>
                        <span style={{
                            fontSize: '17px',
                            marginBottom: '5px',
                            textAlign: 'left',
                            display: 'block',
                            width: '100%',
                            fontWeight: 'bold'
                        }}>Detail Location</span>

                        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '5px', maxWidth: '432px' }}>
                            <div
                                style={{
                                    // border: '1px solid white',
                                    background: '#141617',
                                    paddingLeft: '10px',
                                    paddingTop: '10px',
                                    fontSize: '12px',
                                    width: '350px',
                                    height: '60px',
                                    borderTopLeftRadius: '8px',
                                    borderBottomLeftRadius: '8px',
                                    marginBottom: '10px',
                                }}
                            >
                                <img
                                    src={icAreaSize}
                                    alt="Button Icon"
                                    style={{ marginRight: '10px' }} />
                                <span style={{ color: '#DCE1E7', fontWeight: 'bold', fontSize: '14px' }}>Size of the area</span>
                                <p style={{ color: '#DCE1E7', fontSize: '14px' }}>
                                    {formatNumberWithDots(priceScoring?.total_area)} km²
                                </p>
                            </div>
                            <div
                                style={{
                                    fontSize: '12px',
                                    paddingLeft: '10px',
                                    paddingTop: '10px',
                                    height: '60px',
                                    alignItems: 'center',
                                    width: '350px',
                                    borderTopRightRadius: '8px',
                                    borderBottomRightRadius: '8px',
                                    marginBottom: '20px',
                                    // border: '1px solid white',
                                    background: '#141617',
                                }}
                            >
                                <img
                                    src={icGridTotal}
                                    alt="Button Icon"
                                    style={{ marginRight: '10px' }} />
                                <span style={{
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    color: '#DCE1E7'
                                }}>Square</span>
                                <p style={{ color: '#DCE1E7', fontSize: '14px' }}>
                                    {formatNumberWithDots(priceScoring?.total_grid)},    {priceScoring?.total_area}m2
                                </p>
                            </div>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#141617',
                                padding: '10px',
                                borderRadius: '10px',
                                minHeight: '250px',
                                maxHeight: '400px',
                                overflowY: 'auto', // Scroll secara vertikal jika konten melebihi maxHeight
                                scrollbarWidth: 'none', // Firefox
                                msOverflowStyle: 'none', // Internet Explorer
                            }}
                        >
                            {scoringData?.data?.map((item: any) => (
                                <div
                                    key={item?.subdata}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: '8px',
                                    }}
                                >
                                    <div>
                                        <h5 style={{ color: 'white', fontSize: '14px' }}>{item?.subdata || item?.subcategory}</h5>
                                        <span style={{ color: 'white', fontSize: '10px' }}>{item?.category}</span>
                                    </div>

                                    <h4 style={{ color: 'white', fontSize: '12px' }}>{item?.weight}%</h4>
                                </div>
                            ))}
                        </div>


                        {/* bottom box */}
                        <div style={{ position: 'absolute', bottom: 30 }}>
                            <div
                                style={{
                                    border: '1px solid #141617',
                                    background: '#141617',
                                    paddingLeft: '15px',
                                    paddingTop: '10px',
                                    marginTop: '10px',
                                    width: '432px',
                                    height: '60px',
                                    borderRadius: '12px',
                                }}
                            >
                                {/* <EnvironmentOutlined style={{ fontSize: '18px', marginRight: '10px' }} /> */}
                                <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Item</span>
                                <p style={{ fontSize: '14px', fontStyle: 'italic' }}>
                                    Raw Data (.parquet, .csv, .sql, .xlsx)
                                </p>
                            </div>
                            <div
                                style={{
                                    border: '1px solid #141617',
                                    background: '#141617',
                                    padding: '10px',
                                    paddingTop: '10px',
                                    marginTop: '5px',
                                    width: '432px',
                                    height: '80px',
                                    borderRadius: '12px',
                                    marginBottom: '10px',
                                }}
                            >
                                <span style={{ fontWeight: 'bold', fontSize: '12px', }}>Payment Methode</span>
                                <Select
                                    defaultValue="bank"
                                    style={{
                                        width: '100%',
                                        backgroundColor: '#000',    // Black background for the select body
                                        color: '#fff',              // White text color for the selected option
                                    }}
                                    dropdownStyle={{ padding: 8 }}
                                    onChange={handleChangePaymentMethode}
                                    placeholder="Select Payment Method"
                                >
                                    <Option value="bank">
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                            <BankOutlined style={{ fontSize: '16px' }} />
                                            <span>Bank Transfer</span>
                                        </div>
                                    </Option>
                                    <Option
                                        disabled={dataUser?.balance < totalPrices || !dataUser?.balance}
                                        value="wallet">
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                {/* <span style={{ fontSize: '14px', color: '#333' }}>GeoWallet</span> */}
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                    <WalletOutlined style={{ fontSize: '16px' }} />
                                                    <span>GeoWallet</span>
                                                </div>
                                                <span style={{ fontSize: '14px' }}>Rp {formatNumberWithDots(dataUser?.balance)}</span>
                                            </div>

                                            {dataUser?.balance < totalPrices && (
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                    <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />
                                                    <span style={{ color: '#ff4d4f', backgroundColor: '#fff2f0', padding: '4px 8px', borderRadius: '4px' }}>
                                                        GeoCredit Not enough Saldo.
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </Option>


                                </Select>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '3px' }}>
                                <div style={{ color: 'white' }}>
                                    SubTotal
                                </div>
                                <div style={{ marginBottom: '5px', color: 'white' }}>
                                    {/* {formattedTotalPrice} */}
                                    {loadingPriceScoring ? (
                                            <p>Loading.....</p> // Bisa diganti dengan spinner
                                        ) : (
                                            formattedTotalPrice
                                        )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '3px' }}>
                                <Button

                                    // onClick={handleAddToChart}
                                    id='end-point'
                                    style={{
                                        width: '50px',
                                        height: '48px',
                                        backgroundColor: 'transparent',
                                        color: '#316DBA',
                                        display: 'flex',
                                        borderColor: 'white',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        transition: 'transform 0.3s ease', // Smooth transition
                                    }}
                                    onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                                    onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
                                >
                                    <img
                                        src={icChart}
                                        alt="Button Icon"
                                        width={30}
                                        height={30} />
                                </Button>

                                {loading ? (
                                    <Button type="primary"
                                        style={{
                                            height: '48px',
                                            width: '100%',
                                            color: 'white',
                                            backgroundColor: '#4A7FC0',
                                        }}>
                                        <Spin indicator={<LoadingOutlined spin />} size="small" style={{ color: 'white' }} />
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={handleSubmit}
                                        disabled={loadingPriceScoring}
                                        style={{
                                            height: '48px',
                                            width: '100%',
                                            color: 'white',
                                            backgroundColor: '#4A7FC0',
                                            borderColor: '#4A7FC0',
                                            transition: 'transform 0.3s ease', // Smooth transition
                                        }}
                                        onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                                        onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
                                    >
                                        Checkout
                                    </Button>

                                )}

                            </div>
                        </div>

                    </div>
                </div>
            </Modal>

            <ModalLogin
                visible={isLoginModalVisible}
            />
        </>
    )
}
