import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Space,Tooltip } from 'antd';
// import { ToogleMenu } from '../../shared/drawtools/toogle.menu';
import icAreaSize from '../../asesst/Icons/interactivemap/icon/scale-card.svg';
import icGridTotal from '../../asesst/Icons/interactivemap/icon/grid-card.svg';
import icButton from '../../asesst/new/icon/Bullet.svg'
import useReduxCatalog from '../../hoc/hooks/useCatalog';
import { Input } from 'antd';
import ModalCustomScor from '../../shared/map/modal/formModalCustomScoring';
import { ModalCheckoutScoring } from '../../shared/map/modal/modal-checkout-scoring';
import { formatNumberReachKm2, formatNumberWithDots } from '../newmap/helper/formatNumber';
import { MdInfo } from "react-icons/md";
import { TiDeleteOutline } from "react-icons/ti";

export const MenuScoring: React.FC<any> = ({ selectedCategory, location }) => {
    const [percentages, setPercentages] = useState<Record<string, number>>({});
    const [errors, setErrors] = useState<Record<string, boolean>>({});
    const [isChecked, setIsChecked] = useState(false); // State untuk checklist
    const [savedData, setSavedData] = useState({});
    const [insightName, setInsightName] = useState(""); // State untuk menyimpan Insight Name
    const [isInverse, setIsInverse] = useState<{ [key: string]: boolean }>({});
    const handleCheckboxChange = (item: string, checked: boolean) => {
        setIsInverse((prev) => ({ ...prev, [item]: checked }));
      };

    const {
        setMetaData,
        setDataLayerGroup,
        setShowModalShowCustomSCroring,
        setShowSideMenuCustomScoring,
        setPriceScoring,
        setsetDataCustomScoring,
        setShowModalCheckoutScoring,
        dataCustomScoring,
        showModalCustomScoring,
        showModalCheckoutScoring
    } = useReduxCatalog();
    // console.log(dataCustomScoring);
    const data = dataCustomScoring
    useEffect(() => {
        setMetaData(''); // Reset the geometry to null
        setDataLayerGroup([])
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (data !== null) {
            // Inisialisasi persentase dengan nilai yang dibagi rata
            const allItems = Object.values(data).flatMap((category) =>
                Object.values(category.subcategories).flat()
            );
            const initialPercentage = Math.floor(100 / allItems.length); // Dibulatkan ke bawah
            const initialPercentages = allItems.reduce((acc, item) => {
                acc[item] = initialPercentage; // Hanya angka bulat
                return acc;
            }, {} as Record<string, number>);
            setPercentages(initialPercentages);
        }
    }, [data]); // Tambahkan `data` sebagai dependency

    const handlePercentageChange = (item: string, newValue: number) => {
        if (isChecked) return; // Jangan ubah jika checklist aktif
        const clampedValue = Math.max(0, Math.min(newValue, 100)); // Batasi nilai antara 0 dan 100

        const totalPercentage =
            Object.values(percentages).reduce((sum, value) => sum + value, 0) -
            percentages[item] +
            clampedValue;

        // Validasi total tidak lebih dari 100
        if (totalPercentage > 100) {
            setErrors((prev) => ({ ...prev, [item]: true }));
            return;
        } else {
            setErrors((prev) => ({ ...prev, [item]: false }));
        }

        setPercentages((prev) => ({
            ...prev,
            [item]: clampedValue,
        }));
    };

    let allItems: {
        category: string; // Ganti dari `any` ke `string` supaya lebih jelas
        subcategory: string;
        items: string[];
    }[] = [];

    if (data !== null) {
        allItems = Object.entries(data).flatMap(([categoryName, category]) =>
            Object.entries(category.subcategories).map(([subcategory, items]) => ({
                category: categoryName, // Ambil nama kategorinya
                subcategory,
                items,
            }))
        );
    }
    const handleCheck = (checked: boolean) => {
        setIsChecked(checked);

        if (checked && data !== null) {
            // Reset persentase menjadi dibagi rata
            const allItems = Object.values(data).flatMap((category) =>
                Object.values(category.subcategories).flat()
            );
            const equalPercentage = Math.floor(100 / allItems.length);
            const resetPercentages = allItems.reduce((acc, item) => {
                acc[item] = equalPercentage;
                return acc;
            }, {} as Record<string, number>);
            setPercentages(resetPercentages);
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInsightName(e.target.value);
    };

    const handleSave = () => {
        const formattedDatas = allItems.flatMap(({ category, subcategory, items }) =>
            items.map((item) => ({
                category,
                subcategory,
                subdata: item === subcategory ? null : item,
                weight: (percentages[item] || 0), // Konversi ke desimal
                isInverse: isInverse[item] || false, // Ambil dari state
            }))
        );
        const saveTostate = {
            name: insightName,
            useWallet: false,
            polygon_id: selectedCategory?.[0]?.polygon_id,// null if from profiling
            path: null,// null if new
            data: formattedDatas,
        }
        setShowModalCheckoutScoring(true)
        setSavedData(saveTostate)
        setPriceScoring(saveTostate)

    };

    const handleClose = () => {
        setShowSideMenuCustomScoring(false)
        setsetDataCustomScoring(null)
    }

    const removeItem = (category: string | number, subcategory: string | number, item: any) => {
        const newData = JSON.parse(JSON.stringify(dataCustomScoring)); // Deep Copy

        if (newData[category]?.subcategories[subcategory]) {
            // Hapus item dari array
            newData[category].subcategories[subcategory] = 
                newData[category].subcategories[subcategory].filter((i: any) => i !== item);
    
            // Jika subkategori kosong, hapus subkategori
            if (newData[category].subcategories[subcategory].length === 0) {
                delete newData[category].subcategories[subcategory];
    
                // Jika kategori tidak memiliki subkategori tersisa, hapus kategori
                if (Object.keys(newData[category].subcategories).length === 0) {
                    delete newData[category];
                }
            }
        }
        setsetDataCustomScoring(newData)

    };

    return (
        <React.Fragment>
            <Space
                direction="vertical"
                style={{
                    position: 'absolute',
                    left: '305px',
                    top: '0%',
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <div
                    style={{
                        background: '#333939',
                        border: '1px #d9d9d9',
                        color: 'white',
                        borderRadius: '12px',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                        width: '300px',
                        padding: '15px',
                        height: 'calc(100vh - 50px)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}
                >

                    <div
                        style={{
                            backgroundColor: '#333939',
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: '20px',
                            width: "100%", // Ensure it doesn't exceed modal width
                        }}
                    >
                        <h2 style={{ color: 'white' }}>Custom Scoring</h2>

                        <Button
                            onClick={handleClose}
                            style={{
                                backgroundColor: 'white', // Ubah warna tombol menjadi putih
                                color: 'black', // Ubah warna teks menjadi hitam
                                height: "35px",
                                width: "35px",
                                display: 'flex',
                                position: 'absolute',
                                right: '-15px',
                                borderRadius: '50%',
                                justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                                alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                            }}
                        >
                            X
                        </Button>
                    </div>
                    {/* enable ketika data sudah di selec */}
                    {dataCustomScoring !== null &&
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px', maxWidth: '432px', marginBottom: '-15px' }}>
                            <div
                                style={{
                                    // border: '1px solid white',
                                    background: '#141617',
                                    paddingLeft: '10px',
                                    paddingTop: '10px',
                                    fontSize: '10px',
                                    width: '350px',
                                    height: '60px',
                                    borderTopLeftRadius: '8px',
                                    borderBottomLeftRadius: '8px',
                                    marginBottom: '10px',
                                }}
                            >
                                <img
                                    src={icAreaSize}
                                    alt="Button Icon"
                                    style={{ marginRight: '10px' }} />
                                <span style={{ color: '#DCE1E7', fontWeight: 'bold', fontSize: '12px' }}>Size of the area</span>
                                <p style={{ color: 'white', fontSize: '12px', marginTop: '5px' }}>
                                    {/* {formatNumberReachKm2(selectedCategory?.[0]?.info?.totalArea?.value)} {selectedCategory?.[0]?.info?.totalArea?.unit} */}
                                    {formatNumberReachKm2(selectedCategory?.[0]?.info?.totalArea?.value)} 
                                </p>
                            </div>
                            <div
                                style={{
                                    fontSize: '10px',
                                    paddingLeft: '10px',
                                    paddingTop: '10px',
                                    height: '60px',
                                    alignItems: 'center',
                                    width: '350px',
                                    borderTopRightRadius: '8px',
                                    borderBottomRightRadius: '8px',
                                    marginBottom: '20px',
                                    // border: '1px solid white',
                                    background: '#141617',
                                }}
                            >
                                <img
                                    src={icGridTotal}
                                    alt="Button Icon"
                                    style={{ marginRight: '10px' }} />
                                <span style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    color: '#DCE1E7',

                                }}>Square</span>
                                <p style={{ color: '#DCE1E7', fontSize: '12px', marginTop: '5px' }}>
                                    {formatNumberWithDots(selectedCategory?.[0]?.info?.totalGrid)}, {selectedCategory?.[0]?.info?.gridSizes}x{selectedCategory?.[0]?.info?.gridSizes} m2
                                </p>
                            </div>
                        </div>
                    }
                    {/* enable ketika data sudah di selec */}
                    <p style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.65)', marginTop: '5px', marginBottom: '5px' }}>
                        Insight Name
                    </p>
                    <Input placeholder="E.g., Scoring CITOS" allowClear onChange={onChange} />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <h4 style={{ color: 'white', marginTop: '10px' }}>Choose Source Data</h4>
                        <h4 style={{ color: 'white', marginTop: '10px' }}>100%</h4>
                    </div>

                    <p style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.65)', marginTop: '5px', marginBottom: '10px' }}>
                        Select which data you need for scoring.
                    </p>
                    {dataCustomScoring !== null &&
                        <>
                 
                            <div
                                style={{
                                    backgroundColor: '#141617',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    minHeight: '250px',
                                    maxHeight: '300px',
                                    overflowY: 'auto', // Scroll secara vertikal jika konten melebihi maxHeight
                                    scrollbarWidth: 'none', // Firefox
                                    msOverflowStyle: 'none', // Internet Explorer
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: '10px',
                                        paddingBottom: '5px',
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        borderBottom: '1px solid #444', // Garis bawah untuk pemisah
                                    }}
                                >
                                    <span style={{ color: 'white', flex: 1 }}>Variable</span>
                                    <span style={{ color: 'white', width: '75px', textAlign: 'center' }}>Weight</span>
                                    <span style={{ color: 'white', width: '60px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        Inverse
                                        <Tooltip 
                                           placement="rightBottom"
                                           overlayInnerStyle={{ backgroundColor: '#343A3A', color: 'white' }} // Ganti warna bg dan teks
                                            title={
                                                <div style={{ textAlign: 'left' }}>
                                                <strong style={{ display: 'block', marginBottom: '3px' }}>Inversion</strong>
                                                <span>Use this if lower values mean better scores</span>
                                                </div>
                                            }
                                        >
                                        <MdInfo size={20} style={{ marginLeft: '0px', color: '#4A7FC0', cursor: 'pointer' }} />
                                        </Tooltip>
                                    </span>
                                </div>

                                {allItems.map(({ category,subcategory, items }) => (
                                    <div key={subcategory} style={{ marginBottom: '10px' }}>
                                        {items.map((item) => (
                                            <div
                                                key={item}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    marginBottom: '8px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        maxWidth: '100px',
                                                        minWidth: '100px'
                                                    }}
                                                >
                                                    <h4 style={{ color: 'white', fontSize: '12px' }}>{item}</h4>
                                                    <span style={{ color: 'white', fontSize: '10px' }}>{subcategory}</span>
                                                </div>
                                                <Input
                                                    type="text" // Hilangkan spinner
                                                    value={`${percentages[item] || 0}%`} // Tambahkan simbol %
                                                    onChange={(e) => handlePercentageChange(
                                                        item,
                                                        parseInt(e.target.value.replace(/[^0-9]/g, ''), 10) || 0
                                                    )}
                                                    disabled={isChecked}
                                                    style={{
                                                        width: '50px',
                                                        placeItems:'self-start',
                                                        height: '25px',
                                                        borderColor: errors[item] ? 'red' : undefined,
                                                        color: isChecked ? 'gray' : 'black', // Teks abu-abu saat disabled
                                                        backgroundColor: isChecked ? '#f5f5f5' : undefined, // Tambahkan latar belakang abu-abu untuk kesan disabled
                                                    }} />
                                                <div style={{
                                                    width: '5px'
                                                }}>
                                                    <Checkbox
                                                     checked={isInverse[item] || false}
                                                     onChange={(e) => handleCheckboxChange(item, e.target.checked)}

                                                    />
                                                </div>
                                                <div
                                             
                                                >
                                                <TiDeleteOutline 
                                                 style={{ color: "red",  cursor:"pointer" }}
                                                 size={25}
                                                 onClick={() => removeItem(category, subcategory, item)} 
                                                />

                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}


                            </div>
                            <div style={{
                                padding: '10px',
                            }}>
                                <Checkbox
                                    checked={isChecked}
                                    onChange={(e: { target: { checked: boolean; }; }) => handleCheck(e.target.checked)}
                                    style={{ marginBottom: '15px', color: 'white' }}
                                >
                                    <p style={{
                                        fontSize: '12px'
                                    }}>Divide all data percentages equally</p>
                                </Checkbox>
                            </div>

                        </>
                    }


                    <Button
                        type="primary"
                        icon={<img src={icButton} alt="chose data" />}
                        style={{
                            height: '35px',
                            borderRadius: '8px',
                            backgroundColor: '#4A7FC0',
                            display: 'flex',
                            // marginTop: '20px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '14px',
                            fontWeight: 700,
                        }}
                        onClick={() => setShowModalShowCustomSCroring(true)} // Tutup drawer
                    >
                        {dataCustomScoring !== null ? 'Add Another Data' : ' Choose Data'}

                    </Button>

                    <div style={
                        {
                            width: '100%',
                            position: 'absolute',
                            marginBottom: '10px',
                            bottom: '0px',
                        }
                    }>
                        <Button
                            disabled={!insightName || dataCustomScoring === null}
                            type="primary"
                            style={{
                                height: '35px',
                                width: '270px',
                                borderRadius: '8px',
                                backgroundColor: !insightName || dataCustomScoring === null ? 'gray' : "#4A7FC0",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '14px',
                                fontWeight: 700,
                            }}
                            onClick={handleSave}
                        >
                            Buy Scoring
                        </Button>
                    </div>
                    <ModalCustomScor visible={showModalCustomScoring} />
                    <ModalCheckoutScoring
                        isModal={showModalCheckoutScoring}
                        scoringData={savedData}
                    />
                    {/* <ToogleMenu onClose={onClose}/> */}
                </div>

            </Space>
        </React.Fragment>
    );
};
